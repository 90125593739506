import { environment } from './../../environments/environment';
import { LoginRes } from './../../modules/dashboard/dto/login/login.res';

interface ReqInit<Body> extends Omit<RequestInit, 'body'> {
  body?: Body;
  query?: Record<string, string>;
}

export function getUrl(path: string | URL, base: string | URL | undefined, query?: ReqInit<any>['query']) {
  const url = new URL(path, environment.endpoint);

  const queryEntries = Object.entries(query || {});

  for (let i = 0; i < queryEntries.length; i++) url.searchParams.set(queryEntries[i][0], queryEntries[i][1]);

  return url;
}

export function getBody(init?: ReqInit<any>) {
  return init?.body && !(init?.body instanceof FormData) ? JSON.stringify(init?.body) : init?.body;
}

export function getUser(): LoginRes | null {
  return JSON.parse(String(localStorage.getItem('user')));
}

export function getAuthHeader(): Record<string, string> {
  const user = getUser();
  return user ? { Authorization: `Bearer ${user.token}` } : {};
}

export async function fget<B = any, R = any>(path: string, init?: ReqInit<B>) {
  const res = await fetch(getUrl(path, environment.endpoint, init?.query), {
    ...init,
    method: 'GET',
    headers: {
      ...getAuthHeader(),
      ...init?.headers,
    },
  } as any);

  if (res.ok) {
    return Promise.all([res.clone().text(), res.json() as Promise<R>]);
  } else {
    return Promise.reject(res);
  }
}

export async function fpost<Body = any, Resp = any>(path: string, init?: ReqInit<Body>) {
  const res = await fetch(getUrl(path, environment.endpoint, init?.query), {
    ...init,
    method: 'POST',
    headers: {
      Accept: 'application/json',
      'Content-Type': 'application/json',
      ...getAuthHeader(),
      ...init?.headers,
    },
    body: getBody(init),
  } as any);
  if (res.ok) {
    return Promise.all([res.clone().text(), res.json() as Promise<Resp>]);
  } else {
    return Promise.reject(res.json());
  }
}

export async function fput<B = any, R = any>(path: string, init?: ReqInit<B>) {
  const res = await fetch(getUrl(path, environment.endpoint, init?.query), {
    ...init,
    method: 'PUT',
    headers: {
      Accept: 'application/json',
      'Content-Type': 'application/json',
      ...getAuthHeader(),
      ...init?.headers,
    },
    body: getBody(init),
  });
  if (res.ok) {
    return Promise.all([res.clone().text(), res.json() as Promise<R>]);
  } else {
    return Promise.reject(res.json());
  }
}

export async function fdelete<B = any, R = any>(path: string, init?: ReqInit<B>) {
  const res = await fetch(getUrl(path, environment.endpoint, init?.query), {
    ...init,
    method: 'DELETE',
    headers: {
      Accept: 'application/json',
      'Content-Type': 'application/json',
      ...getAuthHeader(),
      ...init?.headers,
    },
    body: getBody(init),
  } as any);
  if (res.ok) {
    return Promise.all([res.clone().text(), res.json() as Promise<R>]);
  } else {
    Promise.reject(res.json());
  }
}

export async function fupload<B = any, R = any>(path: string | URL, init?: ReqInit<B>) {
  const res = await fetch(path, {
    ...init,
    method: 'POST',
    headers: {
      Accept: 'application/json',
      ...getAuthHeader(),
      ...init?.headers,
    },
  } as any);

  if (res.ok) {
    return Promise.all([res.clone().text(), res.json() as Promise<R>]);
  } else {
    Promise.reject(res.json());
  }
}
