import { Outlet } from 'react-router-dom';
import { useAuth } from 'src/lib/auth/use-auth';
import { DashboardHeader } from './DashboardHeader.component';
import { DashboardSideBar } from './DashboardSideBar.component';

export function DashboardContainer() {
  const { isAuthenticated, locationState } = useAuth();

  return isAuthenticated ? (
    <>
      <DashboardHeader />
      <DashboardSideBar />
      <Outlet />
    </>
  ) : (
    <>
      <DashboardHeader />
      <Outlet context={locationState} />
    </>
  );
}
