import { forwardRef, useCallback, useEffect, useImperativeHandle, useMemo, useRef, useState } from 'react';
import { useParams } from 'react-router-dom';
import ReactFlow, {
  Background,
  Connection,
  ConnectionMode,
  Controls,
  Edge,
  Handle,
  MarkerType,
  Node,
  Position,
  ReactFlowInstance,
  ReactFlowProvider,
  addEdge,
  getSmoothStepPath,
  getStraightPath,
  useEdgesState,
  useNodesState,
  useReactFlow,
  useStore,
} from 'reactflow';
import 'reactflow/dist/style.css';
import { Button } from 'src/lib/components/Button.component';
import { fget, fput } from 'src/lib/fetch';
import { DeviceEntity } from 'src/modules/dashboard/dto/device/device.entity';
import { ResponseDocument, ResponseListDocuments } from 'src/modules/dashboard/dto/response';

import { getEdgeParams } from './utils';
import { getRandomArbitrary } from 'src/lib/math/math';

export function StepFloatingEdge({ id, source, target, markerEnd, style, fromX, fromY, toX, toY }: any) {
  const sourceNode = useStore(useCallback((store) => store.nodeInternals.get(source), [source]));
  const targetNode = useStore(useCallback((store) => store.nodeInternals.get(target), [target]));

  if (!sourceNode || !targetNode) {
    return null;
  }

  const { sx, sy, tx, ty, sourcePos, targetPos } = getEdgeParams(sourceNode, targetNode);

  const [edgePath] = getSmoothStepPath({
    sourceX: sx,
    sourceY: sy,
    sourcePosition: sourcePos,
    targetPosition: targetPos,
    targetX: tx,
    targetY: ty,
    borderRadius: 0.5,
  });

  return <path id={id} className="react-flow__edge-path" d={edgePath} markerEnd={markerEnd} style={style} />;
}

export const DeviceNode = ({
  data: device,
}: {
  data: ResponseDocument<DeviceEntity> & {
    setDevices: React.Dispatch<React.SetStateAction<ResponseDocument<DeviceEntity>[]>>;
  };
}) => {
  return (
    <div className="group relative flex w-40 flex-col border border-sky-400 text-center">
      <span className="inline-flex items-center justify-center bg-sky-700 p-1 text-white">{device.DEVICE_NAME.S}</span>
      <div className="flex h-full flex-col justify-center bg-slate-100 px-2 py-1 text-xs">
        <span className="text-center">{device.AREA_NAME.S}</span>
        <span className="text-center">{device.RIVER_NAME.S}</span>
      </div>

      <Handle
        className="h-2 w-2 border-none bg-slate-500 opacity-0 group-hover:opacity-75"
        style={
          {
            // clipPath: 'polygon(50% 0%, 100% 50%, 50% 100%, 0% 50%)',
          }
        }
        position={Position.Left}
        type="source"
      />
      <Handle
        className="h-2 w-2 border-none bg-slate-500 opacity-0 group-hover:opacity-75"
        style={
          {
            // clipPath: 'polygon(50% 0%, 100% 50%, 50% 100%, 0% 50%)',
          }
        }
        position={Position.Right}
        type="source"
      />
      <Handle
        className="h-2 w-2 border-none bg-slate-500 opacity-0 group-hover:opacity-75"
        style={
          {
            // clipPath: 'polygon(50% 0%, 100% 50%, 50% 100%, 0% 50%)',
          }
        }
        position={Position.Top}
        type="source"
      />
      <Handle
        className="h-2 w-2 border-none bg-slate-500 opacity-0 group-hover:opacity-75"
        style={
          {
            // clipPath: 'polygon(50% 0%, 100% 50%, 50% 100%, 0% 50%)',
          }
        }
        position={Position.Bottom}
        type="source"
      />

      <div
        className="absolute right-0 hidden bg-white p-1 text-red-600 transition-all ease-in-out hover:bg-red-500 hover:text-red-200 group-hover:block dark:text-white"
        title="Click to remove"
        onClick={() => {
          device.setDevices((prev) => {
            const _device = prev.find((_device) => _device.DEVICE_ID.S === device.DEVICE_ID.S);
            if (_device?.POSITIONS) {
              _device.POSITIONS.S = '';
            } else if (_device) {
              _device.POSITIONS = { S: '' };
            }

            return [...prev];
          });
        }}>
        <svg className="h-1 w-1" aria-hidden="true" xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 14 14">
          <path
            stroke="currentColor"
            strokeLinecap="round"
            strokeLinejoin="round"
            strokeWidth="2"
            d="m1 1 6 6m0 0 6 6M7 7l6-6M7 7l-6 6"
          />
        </svg>
      </div>
    </div>
  );
};

function FloatingConnectionLine({ toX, toY, fromPosition, toPosition, fromNode }: any) {
  if (!fromNode) {
    return null;
  }

  const targetNode = {
    id: 'connection-target',
    width: 1,
    height: 1,
    positionAbsolute: { x: toX, y: toY },
  };

  const { sx, sy } = getEdgeParams(fromNode, targetNode as Node);
  const [edgePath] = getStraightPath({
    sourceX: sx,
    sourceY: sy,
    // sourcePosition: fromPosition,
    // targetPosition: toPosition,
    targetX: toX,
    targetY: toY,
    // borderRadius: 0.5,
  });

  return (
    <g>
      <path fill="none" stroke="#222" strokeWidth={1.5} d={edgePath} />
    </g>
  );
}

const ReactFlowContext = forwardRef<ReactFlowInstance, any>((_, ref) => {
  const reactFlow = useReactFlow();

  useImperativeHandle(ref, () => reactFlow);

  return null;
});

const AreaFlowChartPage = () => {
  const mark = document.getElementsByClassName('react-flow__panel react-flow__attribution bottom right').item(0);
  if (mark) mark.innerHTML = '';

  const reactFlowRef = useRef<ReactFlowInstance>(null);
  const [devices, setDevices] = useState<ResponseDocument<DeviceEntity>[]>([]);

  const { id } = useParams();

  const [nodes, setNodes, onNodesChange] = useNodesState([]);
  const [edges, setEdges, onEdgesChange] = useEdgesState([]);

  const onConnect = useCallback(
    (params: Connection) => {
      setEdges((eds) => addEdge(params, eds));
    },
    [setEdges],
  );

  const nodeTypes = useMemo(() => ({ device: DeviceNode }), []);
  const edgeTypes = useMemo(() => ({ floating: StepFloatingEdge }), []);

  useEffect(() => {
    setNodes((prev) => {
      const newVal = devices.reduce((deviceNodes: Node[], device) => {
        const arrayCoord = device.POSITIONS?.S?.split(',').map((coord) => +coord);
        if (arrayCoord?.length === 2 && device.DEVICE_ID.S) {
          const position = {
            x: arrayCoord[0],
            y: arrayCoord[1],
          };

          deviceNodes.push({
            data: {
              ...device,
              setDevices,
            },
            id: device.DEVICE_ID.S,
            position: prev.find((node) => node.data.DEVICE_ID.S === device.DEVICE_ID.S)?.position || position,
            type: 'device',
            deletable: false,
          });
        }

        return deviceNodes;
      }, []);

      return newVal || prev;
    });
  }, [devices]);

  useEffect(() => {
    fget<any, ResponseListDocuments<DeviceEntity>>(`/api/deviceByArea?AREA_NAME=${id}`).then(([_, data]) => {
      if (data.Items) {
        setEdges(
          data.Items.reduce((edges: Edge[], device, i) => {
            const _edges: Edge[] =
              device.CONNECTED_DEVICE_IDS?.L?.map((deviceId, j) => ({
                id: `react-flow____${i + j}`,
                source: device.DEVICE_ID.S || '',
                target: deviceId.S,
              })) || [];

            edges.push(..._edges);

            return edges;
          }, []),
        );

        setNodes((prev) => {
          const newVal = data.Items?.reduce((deviceNodes: Node[], device) => {
            const arrayCoord = device.POSITIONS?.S?.split(',').map((coord) => +coord);
            if (arrayCoord?.length === 2 && device.DEVICE_ID.S) {
              const position = {
                x: arrayCoord[0],
                y: arrayCoord[1],
              };

              deviceNodes.push({
                data: {
                  ...device,
                  setDevices,
                },
                id: device.DEVICE_ID.S,
                position: position,
                type: 'device',
                deletable: false,
              });
            }

            return deviceNodes;
          }, []);

          setDevices(data.Items || []);

          return newVal || prev;
        });
      }
    });
  }, []);

  return (
    <div className="relative ml-12 mr-2 mt-2 flex h-[calc(100%-1rem)] flex-col gap-1">
      <ReactFlowProvider>
        <ReactFlow
          nodes={nodes}
          edges={edges}
          nodeTypes={nodeTypes}
          edgeTypes={edgeTypes}
          onNodesChange={(changes) => {
            onNodesChange(changes);
            if (changes.findIndex((change) => change.type === 'dimensions') !== -1) {
              reactFlowRef.current?.fitView({ duration: 1000 });
            }
          }}
          onEdgesChange={onEdgesChange}
          onConnect={onConnect}
          connectionMode={ConnectionMode.Loose}
          connectionLineComponent={FloatingConnectionLine}
          defaultEdgeOptions={{
            type: 'floating',
            markerEnd: {
              type: MarkerType.ArrowClosed,
              color: 'black',
              width: 6,
              height: 6,
            },
            style: {
              strokeWidth: 4.5,
            },
          }}
          deleteKeyCode={['Backspace', 'Delete']}
          className="h-[calc(100%-6rem)] border border-sky-600"
          fitView>
          <Background />
          <Controls />
        </ReactFlow>
        <ReactFlowContext ref={reactFlowRef} />
      </ReactFlowProvider>
      <div className="flex h-32 flex-wrap gap-4 overflow-y-auto border border-sky-600 p-1 px-5">
        {devices
          .filter((device) => device.POSITIONS?.S?.split(',').length !== 2)
          .map((device) => (
            <div
              key={device.DEVICE_ID.S}
              className="flex cursor-pointer flex-col overflow-clip border border-sky-400"
              title="Click to add"
              onClick={() => {
                setDevices((prev) => {
                  if (device.POSITIONS) {
                    device.POSITIONS.S = `${getRandomArbitrary(-100, 100)},${getRandomArbitrary(-100, 100)}`;
                  } else
                    device.POSITIONS = {
                      S: `${getRandomArbitrary(-100, 100)},${getRandomArbitrary(-100, 100)}`,
                    };

                  return prev ? [...prev] : prev;
                });
              }}>
              <span className="inline-flex items-center justify-center whitespace-nowrap bg-sky-700 p-0.5 text-white">
                {device.DEVICE_NAME.S}
              </span>
              <div className="flex h-full flex-col justify-center px-2 py-1 text-xs">
                <span className="text-center">{device.AREA_NAME.S}</span>
                <span className="text-center">{device.RIVER_NAME.S}</span>
              </div>
            </div>
          ))}
      </div>

      <div className="absolute right-0 mr-2 mt-2 bg-white">
        <Button
          className="rounded-none p-0"
          onClick={async () => {
            const srcDeviceIdMap = edges.reduce((result: Record<string, string[]>, edge) => {
              if (result[edge.source]) {
                result[edge.source].push(edge.target);
              } else {
                result[edge.source] = [edge.target];
              }

              return result;
            }, {});

            const promise = [];

            for (let i = 0; i < devices.length; i++) {
              const device = devices[i];

              if (device.DEVICE_ID.S) {
                let strPosition = '';
                const position = nodes.find((node) => node.data.DEVICE_ID.S === device.DEVICE_ID.S)?.position;
                if (position) {
                  strPosition = `${position.x},${position.y}`;
                }

                promise.push(
                  fput<{ device: Partial<DeviceEntity> }>(`/api/devices/${device.DEVICE_ID.S}`, {
                    body: {
                      device: {
                        AREA_NAME: device.AREA_NAME.S,
                        DEVICE_COORDINATE: device.DEVICE_COORDINATE.S,
                        CONNECTED_DEVICE_IDS: srcDeviceIdMap[device.DEVICE_ID.S] || [],
                        DEVICE_ID: device.DEVICE_ID.S,
                        DEVICE_NAME: device.DEVICE_NAME.S,
                        DEVICE_NUMBER: device.DEVICE_NUMBER.S,
                        DEVICE_TYPE: device.DEVICE_TYPE.S,
                        POSITIONS: strPosition,
                        RIVER_NAME: device.RIVER_NAME.S,
                        INSTALL_DATE: device.INSTALL_DATE.S,
                        REMOVE_DATE: device.REMOVE_DATE?.S || '',
                        REMOVE_DATE_HISTORIES: device.REMOVE_DATE_HISTORIES?.L || [],

                        WATER_LEVEL_FORMULA: device.WATER_LEVEL_FORMULA?.S,
                        VELOCITY_FORMULA: device.VELOCITY_FORMULA?.S,
                        FLOW_RATE_FORMULA: device.FLOW_RATE_FORMULA?.S,
                      },
                    },
                  }),
                );
              }
            }

            await Promise.allSettled(promise);

            window.location.reload();
          }}>
          Save
        </Button>
      </div>
    </div>
  );
};

export default AreaFlowChartPage;
