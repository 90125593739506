import type { GeoJsonObject } from 'geojson';
import { Icon, Marker as _Marker, TileLayer as TileLayerEntity } from 'leaflet';
import React, { useEffect, useImperativeHandle, useRef, useState } from 'react';
import { Marker, Tooltip } from 'react-leaflet';
import { useDispatch } from 'react-redux';
import { toasting } from 'src/lib/components/Toast.component';
import { fget, fpost } from 'src/lib/fetch';
import { setShowingDialogState } from 'src/redux/slices/showDialog.slice';

const directions = ['bottom', 'left', 'right']; // Danh sách các hướng

const getRandomDirection = () => {
  return directions[Math.floor(Math.random() * directions.length)];
};

export const CheckBoxWaterLevelMarkers = React.forwardRef(
  (
    {
      label = '水位',
      baseTileLayer,
      checked,
    }: {
      label?: string;
      baseTileLayer: React.RefObject<TileLayerEntity>;
      checked?: boolean;
    },
    ref: React.Ref<{ setGeoData?: (data: GeoJsonObject) => void }>,
  ) => {
    // Handle checkbox
    const eLabel = useRef<HTMLLabelElement>(null);
    const eInputLabel = useRef<HTMLDivElement>(null);

    const markersRef = useRef(new Map<string, _Marker>());

    const [position, setPosition] = useState<{
      data?: {
        marker: [number, number];
        pos: string;
        waterLevel: string;
        flowRate: string;
        deviceId: string;
        velocity: string;
        publish: string;
      }[];
    }>({});
    const [dataDam, setDam] = useState<any>([]);

    const getDamData = async () => {
      try {
        const res = await fget('api/dams');
        const items = res?.[1]?.Items || [];
        const dams: any = [];
        items.forEach((item: any) => {
          const coo = item.DAM_COORDINATE.S.split(',');
          dams.push({
            marker: coo,
            DAM_NUMBER: item.DAM_NUMBER?.S ? item.DAM_NUMBER.S : '',
            RIVER_NAME: item.RIVER_NAME?.S ? item.RIVER_NAME.S : '',
            DAM_ID: item.DAM_ID.S,
          });
        });

        setDam(dams);
      } catch (e) {
        console.log(e);
        toasting({ children: 'データの取得に失敗しました。', containerProps: { className: 'border-red-600' } });
      }
    };

    const getDeviceData = async () => {
      try {
        const res = await fget('api/devices');
        const items = res?.[1]?.Items || [];
        const positions: any = [];

        for (const item of items) {
          const query = {
            query: {
              ExpressionAttributeValues: {
                ':id': { S: item.DEVICE_ID.S },
              },
              KeyConditionExpression: 'DEVICE_ID = :id',
              TableName: 'DEVICE_RECORD',
              ScanIndexForward: false,
              Limit: 1,
            },
          };
          const data = await fpost<any>('api/device/record/query', {
            body: query,
          });

          const record = data?.[1]?.Items?.[0];

          const coo = item.DEVICE_COORDINATE.S.split(',');
          positions.push({
            marker: coo,
            pos: item.DEVICE_NAME?.S ? item.DEVICE_NAME.S : '',
            waterLevel: record?.WATER_LEVEL?.S || '0',
            flowRate: record?.FLOW_RATE?.S || '0',
            deviceId: item.DEVICE_ID.S,
            velocity: record?.VELOCITY?.S || '0',
            publish: record?.Publish?.S || '',
          });
        }

        setPosition({ data: positions });
      } catch (e) {
        console.log(e);
        toasting({ children: 'データの取得に失敗しました。', containerProps: { className: 'border-red-600' } });
      }
    };

    useEffect(() => {
      const fetchData = async () => {
        await Promise.all([getDamData(), getDeviceData()]);
      };
      fetchData();
      // Call getDeviceData every 30 seconds
      const intervalId =  setInterval( async () =>  {
        await Promise.all([ getDeviceData()]);
      }, 30000);
      return () => {
        clearInterval(intervalId);
      };
    }, []);

    const [inputChecked, setInputChecked] = useState<boolean>(!!checked);
    const [flowRateChecked, setFlowChecked] = useState<boolean>(false);
    const [velocityChecked, setVelocityChecked] = useState<boolean>(false);

    const dispatch = useDispatch();

    // Handle ref
    useImperativeHandle(ref, () => ({}));

    return (
      <>
        <div className="flex" ref={eInputLabel}>
          <input
            type="checkbox"
            id={'flow_rate'}
            checked={flowRateChecked}
            onChange={(e) => {
              setFlowChecked(e.target.checked);
            }}
          />
          <label className="ml-2" htmlFor={'flow_rate'}>
            流量
          </label>
        </div>

        <div className="flex" ref={eInputLabel}>
          <input
            type="checkbox"
            id={'velocity'}
            checked={velocityChecked}
            onChange={(e) => {
              setVelocityChecked(e.target.checked);
            }}
          />
          <label className="ml-2" htmlFor={'velocity'} ref={eLabel}>
            {'流速'}
          </label>
        </div>

        <div className="flex" ref={eInputLabel}>
          <input
            type="checkbox"
            id={label}
            checked={inputChecked}
            onChange={(e) => {
              setInputChecked(e.target.checked);
            }}
          />
          <label className="ml-2" htmlFor={label} ref={eLabel}>
            {label}
          </label>
        </div>

        {(inputChecked || flowRateChecked || velocityChecked) &&
          dataDam?.map((position: any, index: number) => (
            <Marker
              key={`dam-marker-${index}`}
              position={position.marker}
              icon={
                new Icon({
                  iconUrl: 'assets/dam.png',
                  iconSize: [32, 32],
                  iconAnchor: [16, 32],
                  popupAnchor: [16, -16],
                  tooltipAnchor: [16, -16],
                })
              }
              ref={(e) => {
                if (e) markersRef.current.set(position.deviceId, e);
              }}>
              <Tooltip sticky className="z-10" permanent={true}>
                ダム:{position.DAM_NUMBER}
              </Tooltip>
            </Marker>
          ))}

        {
          position?.data?.map((position, idx) => (
            <Marker
              eventHandlers={{
                click: () => {
                  dispatch(
                    setShowingDialogState({
                      isFlowRate: false,
                      isWaterLevel: false,
                      showDialogWaterLevel: true,
                      position: position,
                    }),
                  );
                },
              }}
              ref={(e) => {
                if (e) markersRef.current.set(position.deviceId, e);
              }}
              icon={
                new Icon({
                  iconUrl: 'assets/marker-icon.png',
                  shadowUrl: 'assets/marker-shadow.png',
                  iconSize: [25, 41],
                  iconAnchor: [13, 41],
                  popupAnchor: [2, -40],
                })
              }
              key={`marker-${idx}`}
              position={position.marker}>
              { (inputChecked || flowRateChecked || velocityChecked) &&
                  <Tooltip
                      direction={getRandomDirection() as any}
                      permanent
                      interactive={true}
                      eventHandlers={{
                        click: () => {
                          dispatch(
                            setShowingDialogState({
                              showDialogWaterLevel: true,
                              position: position,
                              isMap: true,
                              isWaterLevel: inputChecked,
                              isFlowRate: flowRateChecked,
                              isVelocity: velocityChecked,
                            }),
                          );
                        },
                        mouseover(e) {
                          const contentNode = e.target._contentNode as HTMLDivElement;
                          contentNode.style.transition = 'all 0.3s ease, transform 0.3s ease';
                          contentNode.style.zIndex = '1000';
                          contentNode.style.backgroundColor = '#185088';
                          contentNode.style.color = 'white';
                        },
                        mouseout(e) {
                          const contentNode = e.target._contentNode as HTMLDivElement;
                          contentNode.style.transition = 'all 0.3s ease, transform 0.3s ease';
                          contentNode.style.zIndex = '0';
                          contentNode.style.backgroundColor = '#ffffff';
                          contentNode.style.color = 'black';
                        },
                      }}>
                <span>
                  {position.pos}
                  {inputChecked ? <br /> : ''}
                  {inputChecked ? `水位:${parseFloat(position.waterLevel || '0').toFixed(2)} m` : ''}
                  {flowRateChecked ? <br /> : ''}
                  {flowRateChecked ? `流量:${parseFloat(position.flowRate || '0').toFixed(2)} m3/s` : ''}
                  {velocityChecked ? <br /> : ''}
                  {velocityChecked ? `流速:${parseFloat(position.velocity || '0').toFixed(2)} m/s` : ''}
                    <br/>
                  {`更新日付: ${position.publish}`}
                </span>
                  </Tooltip>
              }
            </Marker>
          ))}
      </>
    );
  },
);
