import { Icon, LatLng, Map, Marker as MarkerEntity, TileLayer as TileLayerEntity } from 'leaflet';
import { useEffect, useRef, useState } from 'react';
import { MapContainer, TileLayer } from 'react-leaflet';
import Control from 'react-leaflet-custom-control';
import { Link, useNavigate, useParams } from 'react-router-dom';
import { Button } from 'src/lib/components/Button.component';
import { Checkbox } from 'src/lib/components/Checkbox.component';
import { Select } from 'src/lib/components/Select.component';
import { TextInput } from 'src/lib/components/TextInput.component';
import { toasting } from 'src/lib/components/Toast.component';
import { fdelete, fget, fput } from 'src/lib/fetch';
import { SelectBoxBaseLayer } from 'src/modules/map-layers/components/SelectBoxBaseLayer.component';
import { MarkerMapControl } from 'src/modules/map-markers/components/MarkerMapControl.component';
import { BASE_MAP_URL } from 'src/redux/slices/baseLayer.slice';
import { AreaEntity } from '../../dto/area/area.entity';
import { DamEntity } from '../../dto/dam/dam.entity';
import { DeleteDamReq, UpdateDamReq } from '../../dto/dam/dam.req';
import { ResponseDocument, ResponseGetDocument, ResponseListDocuments } from '../../dto/response';
import { RiverEntity } from '../../dto/river/river.entity';

export function EditDamPage() {
  const eForm = useRef<HTMLFormElement>(null);

  const eTextNumber = useRef<HTMLInputElement>(null);
  const eSelectAreaName = useRef<HTMLSelectElement>(null);
  const eTextCoordinateX = useRef<HTMLInputElement>(null);
  const eTextCoordinateY = useRef<HTMLInputElement>(null);
  const eSelectRiver = useRef<HTMLSelectElement>(null);

  const baseTileLayer = useRef<TileLayerEntity>(new TileLayerEntity(''));
  const map = useRef<Map>(null);
  const eMarker = useRef<MarkerEntity>(null);

  const eButtonModeEdit = useRef<HTMLInputElement>(null);
  const [checkedModeEdit, setCheckedModeEdit] = useState<boolean>(false);

  const eButtonSubmit = useRef<HTMLButtonElement>(null);
  const eButtonDelete = useRef<HTMLButtonElement>(null);

  const { id } = useParams();
  const [data, setData] = useState<{
    dam?: ResponseDocument<DamEntity>;
    areas?: ResponseListDocuments<AreaEntity>;
    rivers?: ResponseListDocuments<RiverEntity>;
  }>();

  const navigate = useNavigate();

  const handleButtonSubmit = async (e: React.MouseEvent<HTMLButtonElement, MouseEvent>) => {
    e.preventDefault();

    const form = eForm.current;
    const buttonSubmit = eButtonSubmit.current;

    if (!form?.checkValidity()) {
      form?.reportValidity();
      return;
    }

    if (!buttonSubmit) return;

    buttonSubmit.disabled = true;
    buttonSubmit.classList.add('bg-gray-400');

    const damData = {
      DAM_ID: data?.dam?.DAM_ID.S,
      DAM_AREA: eSelectAreaName.current?.value || '',
      DAM_COORDINATE: `${eTextCoordinateX.current?.value || ''},${eTextCoordinateY.current?.value || ''}`,
      DAM_NUMBER: eTextNumber.current?.value || '',
      RIVER_NAME: eSelectRiver.current?.value || '',
    };

    try {
      await fput<UpdateDamReq>(`api/dams/${id}`, {
        body: { dam: damData },
      });

      buttonSubmit.classList.remove('bg-gray-400');
      buttonSubmit.disabled = false;

      navigate('..');
    } catch (error) {
      console.error(error);

      buttonSubmit.classList.remove('bg-gray-400');
      buttonSubmit.disabled = false;
    }
  };

  const handleButtonDelete = async (e: React.MouseEvent<HTMLButtonElement, MouseEvent>) => {
    e.preventDefault();
    if (!eForm.current?.checkValidity()) return eForm.current?.reportValidity();

    await fdelete<DeleteDamReq>(`api/dams/${id}`);
    navigate('..');
  };

  useEffect(() => {
    const fetchData = async () => {
      try {
        const [areaResponse, damResponse] = await Promise.all([
          fget<unknown, ResponseListDocuments<AreaEntity>>('api/areas'),
          fget<unknown, ResponseGetDocument<DamEntity>>(`api/dams/${id}`),
        ]);

        const dam = damResponse?.[1]?.Item;
        const damAreaName = dam?.DAM_AREA?.S;

        if (!damAreaName) throw new Error('damAreaName is undefined');

        const riverResponse = await fget<unknown, ResponseListDocuments<RiverEntity>>(
          `api/riverByArea?AREA_NAME=${damAreaName}`,
        );

        setData({
          areas: areaResponse[1],
          rivers: riverResponse[1],
          dam,
        });
      } catch (error) {
        console.error(error);
        toasting({ children: '何か問題が発生しました', containerProps: { className: 'border-red-600' } });
      }
    };

    fetchData();
  }, []);

  return data?.areas && data.dam && data.rivers ? (
    <form className="flex flex-col px-4 pt-12 md:px-12 md:py-8" ref={eForm}>
      <h1 className="mb-2 whitespace-nowrap  text-2xl">ダム情報編集</h1>
      <span className="w-full border-b border-slate-800"></span>

      <div className="flex basis-full justify-end">
        <Checkbox
          className="hidden"
          onChange={(e) => setCheckedModeEdit(e.target.checked)}
          defaultChecked={checkedModeEdit}
          ref={eButtonModeEdit}
          containerProps={{
            className: `mt-4 ml-auto border border-gray-500`,
          }}
          labelProps={{
            children: '編集モード',
            className: `select-none p-2 ${checkedModeEdit ? 'bg-black text-white' : ''}`,
          }}
        />
      </div>

      <div className="mt-4 flex flex-nowrap gap-8">
        <div className="flex w-3/4 flex-row flex-wrap gap-8">
          <TextInput
            labelProps={{
              children: 'ID',
            }}
            type="text"
            containerProps={{
              className: 'w-1/3 basis-full',
              style: {
                minWidth: '24rem',
              },
            }}
            disabled
            readOnly
            required
            defaultValue={data.dam.DAM_ID.S}
          />

          <TextInput
            labelProps={{
              children: '番号',
            }}
            type="text"
            defaultValue={data.dam.DAM_NUMBER.S}
            containerProps={{
              className: 'w-1/3 basis-full',
              style: {
                minWidth: '24rem',
              },
            }}
            required
            ref={eTextNumber}
            disabled={!checkedModeEdit}
          />
          <Select
            defaultValue={data.dam.DAM_AREA.S}
            labelProps={{ value: '地域名' }}
            containerProps={{
              className: 'w-1/3 basis-full',
              style: {
                minWidth: '24rem',
              },
            }}
            required
            onChange={(e) => {
              fget(`api/riverByArea?AREA_NAME=${e.target.value}`, {}).then((river) => {
                setData((prev) => ({ ...prev, rivers: river[1] }));
              });
            }}
            ref={eSelectAreaName}
            disabled={!checkedModeEdit}>
            {data.areas.Items?.map((area, i) => (
              <option key={`Option-` + i} value={area.AREA_NAME.S}>
                {area.AREA_NAME.S}
              </option>
            ))}
          </Select>

          <Select
            defaultValue={data.dam.RIVER_NAME.S}
            labelProps={{ value: '川の名前' }}
            containerProps={{
              className: 'w-1/3 basis-full',
              style: {
                minWidth: '24rem',
              },
            }}
            required
            ref={eSelectRiver}
            disabled={!checkedModeEdit}>
            {data.rivers.Items?.map((river, i) => (
              <option key={`Option-` + i} value={river.RIVER_NAME.S}>
                {river.RIVER_NAME.S}
              </option>
            ))}
          </Select>

          <div className="flex basis-full flex-col">
            <label className="mb-2 text-sm font-normal text-gray-900 dark:text-white">設置座標</label>
            <div className="flex flex-row flex-wrap justify-between gap-4">
              <TextInput
                labelProps={{
                  className: 'mb-0 mr-2',
                  children: 'X',
                }}
                type="text"
                containerProps={{
                  className: 'flex flex-row items-center',
                  style: {
                    minWidth: '12rem',
                  },
                }}
                required
                defaultValue={data.dam.DAM_COORDINATE.S?.split(',')[0]}
                onChange={(e) => {
                  if (eTextCoordinateY.current?.value)
                    eMarker.current?.setLatLng(new LatLng(+e.target.value, +eTextCoordinateY.current.value));
                }}
                ref={eTextCoordinateX}
                disabled={!checkedModeEdit}
              />

              <TextInput
                labelProps={{
                  className: 'mb-0 mr-2',
                  children: 'Y',
                }}
                type="text"
                containerProps={{
                  className: 'flex flex-row items-center',
                  style: {
                    minWidth: '12rem',
                  },
                }}
                required
                defaultValue={data.dam.DAM_COORDINATE.S?.split(',')[1]}
                onChange={(e) => {
                  if (eTextCoordinateX.current?.value)
                    eMarker.current?.setLatLng(new LatLng(+e.target.value, +eTextCoordinateX.current.value));
                }}
                ref={eTextCoordinateY}
                disabled={!checkedModeEdit}
              />
            </div>
          </div>

          {checkedModeEdit && (
            <div className="block basis-full justify-center">
              <Button
                className="mx-auto mt-6 rounded-md bg-black text-xs text-white"
                style={{
                  minWidth: '256px',
                }}
                type="submit"
                onClick={handleButtonSubmit}
                ref={eButtonSubmit}>
                編集
              </Button>
              <Button
                className="mx-auto mt-6 rounded-md border bg-transparent text-xs text-orange-600"
                style={{
                  minWidth: '256px',
                }}
                type="submit"
                onClick={handleButtonDelete}
                ref={eButtonDelete}>
                削除
              </Button>
            </div>
          )}
          <Link
            className="mx-auto rounded-md  border border-gray-600 bg-transparent p-2.5 text-center text-xs"
            to=".."
            style={{
              minWidth: '256px',
            }}>
            {eButtonModeEdit.current?.checked ? 'キャンセル' : '戻る'}
          </Link>
        </div>

        <MapContainer
          attributionControl={false}
          minZoom={5}
          zoom={9}
          center={[33.9195, 133.1811]}
          style={{
            height: '100%',
            minHeight: '60vh',
            width: '20vw',
            border: '0.25rem solid',
            flexBasis: '100%',
          }}
          ref={map}>
          <TileLayer url={decodeURI(BASE_MAP_URL.std.href)} ref={baseTileLayer} maxNativeZoom={18} />
          <Control
            position="topright"
            container={{
              className: 'flex bg-none flex-col',
            }}>
            <SelectBoxBaseLayer baseTileLayer={baseTileLayer} />
          </Control>

          {data.dam && (
            <MarkerMapControl
              ref={eMarker}
              position={
                data.dam.DAM_COORDINATE.S
                  ? new LatLng(+data.dam.DAM_COORDINATE.S.split(',')[0], +data.dam.DAM_COORDINATE.S.split(',')[1])
                  : undefined
              }
              onClickMap={(e) => {
                eButtonModeEdit.current?.checked &&
                  eTextCoordinateX.current &&
                  eTextCoordinateY.current &&
                  (eTextCoordinateX.current.value = `${e.latlng.lat}`) &&
                  (eTextCoordinateY.current.value = `${e.latlng.lng}`);

                return !!eButtonModeEdit.current?.checked;
              }}
              icon={
                new Icon({
                  iconUrl: `/assets/dam.png`,
                  iconSize: [29, 33],
                  iconAnchor: [13, 37],
                })
              }
            />
          )}
        </MapContainer>
      </div>
    </form>
  ) : (
    <></>
  );
}
