import ApexCharts from 'apexcharts';
import { memo } from 'react';
import { TargetPoint } from 'src/modules/dashboard/dto/graph/target-point.entity';
import { ResponseDocument } from 'src/modules/dashboard/dto/response';

export function Chart({
  targetpoint,
  points,
  graphChartsRef,
}: {
  targetpoint: string;
  graphChartsRef: React.MutableRefObject<Record<string, ApexCharts>>;
  points: ResponseDocument<TargetPoint>;
}) {
  return (
    <div className="h-[400px] max-sm:h-[300px]">
      <div
        id={targetpoint}
        ref={(ref) => {
          if (!ref) return;
          graphChartsRef.current[targetpoint] = new ApexCharts(ref, {
            chart: {
              id: targetpoint,
              height: '100%',
              toolbar: {
                show: true,
                offsetX: 0,
                offsetY: 0,
                tools: {
                  download: true,
                  selection: false,
                  zoom: false,
                  zoomin: false,
                  zoomout: false,
                  pan: true,
                  reset: true,
                },
                autoSelected: 'pan',
              },
            },
            dataLabels: { enabled: false },
            title: {
              text: targetpoint,
              align: 'left',
              floating: false,
              style: {
                fontSize: '22px',
                fontWeight: 'bold',
                fontFamily: undefined,
                color: '#263238',
                backgroundColor: 'blue',
              },
            },

            grid: {
              borderColor: '#AEC6CF',
              strokeDashArray: 3,
              show: true,
            },
            legend: { position: 'top' },
            xaxis: {
              categories: [],
              tickPlacement: 'on',
              min: 0,
              max: 301,
              labels: {
                formatter() {
                  return '';
                },
              },
            },
            yaxis: {
              labels: {
                formatter() {
                  return '';
                },
              },
              stepSize: 2,
            },
            series: [
              {
                data: [],
              },
            ],
            stroke: {
              width: 3,
            },
            noData: {
              text: '読み込み中...',
              align: 'center',
              verticalAlign: 'middle',
              offsetX: 0,
              offsetY: 0,
              style: {
                color: undefined,
                fontSize: '24px',
                fontFamily: undefined,
              },
            },
          });
          graphChartsRef.current[targetpoint].render();
        }}></div>
    </div>
  );
}

export const ChartMemo = memo(Chart, (prev, curr) => {
  return prev.targetpoint !== curr.targetpoint && JSON.stringify(prev.points) !== JSON.stringify(curr.points);
});
