import type { GeoJsonObject } from 'geojson';
import { GeoJSON as GeoJSONEntity, Icon, Map, marker, TileLayer as TileLayerEntity } from 'leaflet';
import { useEffect, useRef, useState } from 'react';
import { GeoJSON, MapContainer, TileLayer } from 'react-leaflet';
import Control from 'react-leaflet-custom-control';
import { Link, useNavigate, useParams } from 'react-router-dom';
import { environment } from 'src/environments/environment';
import { Button } from 'src/lib/components/Button.component';
import { Checkbox } from 'src/lib/components/Checkbox.component';
import { InputFile } from 'src/lib/components/InputFile.component';
import { Select } from 'src/lib/components/Select.component';
import { TextArea } from 'src/lib/components/TextArea.component';
import { TextInput } from 'src/lib/components/TextInput.component';
import { toasting } from 'src/lib/components/Toast.component';
import { fdelete, fget, fput } from 'src/lib/fetch';
import { SelectBoxBaseLayer } from 'src/modules/map-layers/components/SelectBoxBaseLayer.component';
import { AreaEntity } from '../../dto/area/area.entity';
import { ResponseDocument, ResponseGetDocument, ResponseListDocuments } from '../../dto/response';
import { RiverEntity } from '../../dto/river/river.entity';
import { DeleteRiverReq } from '../../dto/river/river.req';

export function EditRiverPage() {
  const eForm = useRef<HTMLFormElement>(null);

  const eTextName = useRef<HTMLInputElement>(null);
  const eSelectAreaName = useRef<HTMLSelectElement>(null);

  const eInputFile = useRef<HTMLInputElement>(null);
  const eTextAreaJson = useRef<HTMLTextAreaElement>(null);
  const [geoJsonData, setGeoJsonData] = useState<any>(undefined);

  const baseTileLayer = useRef<TileLayerEntity>(new TileLayerEntity(''));
  const map = useRef<Map>(null);
  const eGeoJSON = useRef<GeoJSONEntity>(null);

  const eButtonSubmit = useRef<HTMLButtonElement>(null);
  const eButtonDelete = useRef<HTMLButtonElement>(null);

  const eSVGLoading = useRef<SVGSVGElement>(null);
  const eSVGDone = useRef<SVGSVGElement>(null);

  const eButtonModeEdit = useRef<HTMLInputElement>(null);

  const [checkedModeEdit, setCheckedModeEdit] = useState<boolean>(false);

  const navigate = useNavigate();

  const { id } = useParams();
  const [data, setData] = useState<{
    river?: ResponseDocument<RiverEntity>;
    areas?: ResponseListDocuments<AreaEntity>;
    geoJson?: any;
  }>();
  const [formChecked, setFormChecked] = useState<boolean>(false);

  const handleButtonSubmit = async (e: React.MouseEvent<HTMLButtonElement, MouseEvent>) => {
    e.preventDefault();

    // if (!eForm.current?.checkValidity()) {
    //   if (!eInputFile.current?.files?.[0]) {
    //     setFormChecked(true);
    //     alert('河川座標データが必須です');
    //   } else {
    //     setFormChecked(false);
    //   }
    //   return eForm.current?.reportValidity();
    // }

    if (eButtonSubmit.current) {
      eButtonSubmit.current.disabled = true;

      const riverData: any = {
        RIVER_ID: data?.river?.RIVER_ID.S,
        RIVER_NAME: eTextName.current?.value || '',
        REGION_NAME: eSelectAreaName.current?.value || '',
      };

      try {
        const filename = eInputFile.current?.files?.[0]?.name;
        let key = '';

        if (filename) {
          key = riverData.REGION_NAME + '_' + riverData.RIVER_NAME + '_' + filename;

          // get presigned url
          const data = await fget<[string, { url: string }]>(`api/s3/put-geojson-url?key=${key}`);
          const presignedUrl = data[1].url;

          // upload to s3
          await fetch(presignedUrl, {
            method: 'PUT',
            body: eInputFile.current?.files?.[0],
          });
        }

        await fput(`api/rivers/${id}`, {
          body: {
            river: {
              RIVER_NAME: riverData.RIVER_NAME,
              REGION_NAME: riverData.REGION_NAME,
              COORDINATE_DATA: key,
            },
          },
        });

        if (eButtonSubmit.current && eInputFile.current) {
          eButtonSubmit.current.disabled = false;
        }

        navigate('..');
      } catch (error) {
        console.error('Error uploading file:', error);
        toasting({ children: 'デバイスの追加に失敗しました。', containerProps: { className: 'border-red-600' } });
      }
    }
  };

  const handleButtonDelete = async (e: React.MouseEvent<HTMLButtonElement, MouseEvent>) => {
    e.preventDefault();

    if (!eForm.current?.checkValidity()) {
      return eForm.current?.reportValidity();
    }

    try {
      await fdelete<DeleteRiverReq>(`api/rivers/${id}`);

      navigate('..');
    } catch (error) {
      console.error('Error deleting river:', error);
      toasting({ children: '削除に失敗しました', containerProps: { className: 'border-red-600' } });
    }
  };

  const handleButtonPreview = async (e: React.MouseEvent<HTMLButtonElement, MouseEvent>) => {
    if (eSVGDone.current && eSVGLoading.current && eInputFile.current?.files?.[0]) {
      eSVGDone.current.classList.add('hidden');
      eSVGLoading.current.classList.remove('hidden');
    }

    try {
      const file = eInputFile.current?.files?.[0];
      if (!file) return;

      const value = await file.text();

      eGeoJSON.current?.clearLayers();
      eGeoJSON.current?.addData(JSON.parse(value));

      if (eTextAreaJson.current) {
        eTextAreaJson.current.value = value;
      }

      if (eSVGDone.current && eSVGLoading.current) {
        eSVGDone.current.classList.remove('hidden');
        eSVGLoading.current.classList.add('hidden');
      }
    } catch (error) {
      console.error('Error handling preview:', error);
      toasting({ children: 'プレビューに失敗しました', containerProps: { className: 'border-red-600' } });
    }
  };

  useEffect(() => {
    const fetchData = async () => {
      try {
        if (eSVGDone.current) {
          eSVGDone.current.classList.add('hidden');
        }

        const fetchRiver = async (id?: string) => {
          if (!id) return {};
          const res = await fget<unknown, ResponseGetDocument<RiverEntity>>(`api/rivers/${id}`);
          const item = res?.[1].Item;
          if (!item) return {};

          return { river: item };
        };

        const [areaResponse, riverResponse] = await Promise.all([
          fget<unknown, ResponseListDocuments<AreaEntity>>('api/areas'),
          fetchRiver(id),
        ]);

        const river = riverResponse.river;
        const s3CoordinateData = river?.COORDINATE_DATA?.S;

        if (s3CoordinateData) {
          // get s3 presigned url
          const key = s3CoordinateData;
          const res = await fget<string, { url: string }>(`api/s3/get-geojson-url?key=${key}`);
          const url = res?.[1].url;

          // get geojson
          const geoJsonRes = await fetch(url);
          setGeoJsonData(await geoJsonRes.json());
        }

        setData(() => ({ areas: areaResponse[1], river: riverResponse.river }));
      } catch (error) {
        console.error('Error fetching data:', error);
        toasting({ children: 'データの取得に失敗しました', containerProps: { className: 'border-red-600' } });
      }
    };

    if (!id) return;
    fetchData();
  }, [id]);

  const [reRenderFlag, setReRenderFlag] = useState<number>(0);

  useEffect(() => {
    if (!geoJsonData) return;

    if (eTextAreaJson.current) {
      eTextAreaJson.current.value = JSON.stringify(geoJsonData, null, 2);
    }
    console.log('🚀 ~ useEffect ~ geoJsonData:', geoJsonData);

    if (eSVGDone.current && eSVGLoading.current) {
      eSVGDone.current.classList.remove('hidden');
      eSVGLoading.current.classList.add('hidden');
    }

    setReRenderFlag((prev: number) => prev + 1);
  }, [geoJsonData]);

  useEffect(() => {
    if (!geoJsonData) return;

    eGeoJSON.current?.clearLayers();
    eGeoJSON.current?.addData(geoJsonData);
  }, [reRenderFlag]);

  return data?.river && data.areas ? (
    <form className="flex flex-col px-4 pt-12 md:px-12 md:py-8" ref={eForm}>
      <h1 className="mb-2 whitespace-nowrap  text-2xl">河川座標データ管理</h1>
      <span className="w-full border-b border-slate-800"></span>

      <div className="mt-4 flex basis-full justify-end">
        <Checkbox
          className="hidden"
          onChange={(e) => setCheckedModeEdit(e.target.checked)}
          defaultChecked={checkedModeEdit}
          ref={eButtonModeEdit}
          containerProps={{
            className: `ml-auto border border-gray-500`,
          }}
          labelProps={{
            children: '編集モード',
            className: `select-none p-2 ${checkedModeEdit ? 'bg-black text-white' : ''}`,
          }}
        />
      </div>

      <div className="mt-4 flex flex-nowrap gap-8">
        <div className="flex w-2/3 flex-row flex-wrap justify-between gap-8">
          <TextInput
            labelProps={{
              children: 'ID',
            }}
            type="text"
            containerProps={{
              className: 'w-1/3 basis-full',
              style: {
                minWidth: '24rem',
              },
            }}
            disabled
            readOnly
            required
            defaultValue={data.river.RIVER_ID.S}
          />

          <TextInput
            labelProps={{
              children: '川の名前',
            }}
            type="text"
            containerProps={{
              className: 'w-1/3 basis-full',
              style: {
                minWidth: '24rem',
              },
            }}
            required
            ref={eTextName}
            defaultValue={data.river.RIVER_NAME.S}
            disabled={!checkedModeEdit}
          />

          <Select
            defaultValue={data.river.REGION_NAME.S}
            labelProps={{ value: '地域名' }}
            containerProps={{
              className: 'w-1/3 basis-full',
              style: {
                minWidth: '24rem',
              },
            }}
            required
            ref={eSelectAreaName}
            disabled={!checkedModeEdit}>
            {data.areas.Items?.map((area, i) => (
              <option key={`Option-` + i} value={area.AREA_NAME.S}>
                {area.AREA_NAME.S}
              </option>
            ))}
          </Select>

          {checkedModeEdit && (
            <>
              <span className="flex w-96 basis-full flex-col">
                <div className="my-auto">
                  河川座標データ:{' '}
                  <a href="https://geojson.io/#map=6.56/34.926/135.711" className="text-blue-500" target="_blank">
                    geojson.io
                  </a>{' '}
                  から作成ください{' '}
                </div>
                <div className="mt-5">
                  <a href={'https://geojson.io/#map=6.56/34.926/135.711'} target="_blank">
                    <Button className="mr-3 bg-blue-400 text-xs">geojson.io へ移動</Button>
                  </a>
                </div>
              </span>
              <InputFile
                containerProps={{
                  className: 'mr-3 text-xs',
                }}
                labelInnerProps={{
                  className: formChecked && !eInputFile.current?.files?.[0] ? 'border-rose-600' : 'border-gray-300',
                }}
                required
                ref={eInputFile}>
                <div className="inline-flex flex-nowrap items-center">
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    fill="none"
                    viewBox="0 0 24 24"
                    strokeWidth={1.75}
                    stroke="currentColor"
                    className="mr-2 h-4 w-4">
                    <path
                      strokeLinecap="round"
                      strokeLinejoin="round"
                      d="M3 16.5v2.25A2.25 2.25 0 005.25 21h13.5A2.25 2.25 0 0021 18.75V16.5m-13.5-9L12 3m0 0l4.5 4.5M12 3v13.5"
                    />
                  </svg>
                  アップロード
                </div>
              </InputFile>
              <Button className="text-xs" onClick={handleButtonPreview}>
                プレビュー
              </Button>
            </>
          )}

          <span className="basis-full text-xs font-light">※GeoJSONファイル</span>

          <TextArea
            containerProps={{
              className: 'basis-full',
              style: {
                minWidth: '24rem',
              },
            }}
            className="custom-scrollbar-1"
            ref={eTextAreaJson}
            disabled={true}>
            <div>
              <svg
                className="-mt-1 mr-2 inline-block h-4 w-4 text-green-500"
                viewBox="0 0 24 24"
                strokeWidth={3}
                stroke="currentColor"
                fill="none"
                strokeLinecap="round"
                strokeLinejoin="round"
                ref={eSVGDone}>
                <path stroke="none" d="M0 0h24v24H0z" /> <path d="M5 12l5 5l10 -10" />
              </svg>
              <svg
                className="-mt-1 mr-2 inline-block h-4 w-4 animate-spin text-yellow-500"
                viewBox="0 0 24 24"
                strokeWidth={3}
                stroke="currentColor"
                fill="none"
                strokeLinecap="round"
                strokeLinejoin="round"
                ref={eSVGLoading}>
                <path stroke="none" d="M0 0h24v24H0z" /> <path d="M4.05 11a8 8 0 1 1 .5 4m-.5 5v-5h5" />
              </svg>
              <span className="font-light">GeoJSON</span>
            </div>
          </TextArea>

          {checkedModeEdit && (
            <>
              <Button
                className="w-full basis-full justify-center bg-black text-xs text-white"
                style={{
                  minWidth: '256px',
                }}
                type="submit"
                onClick={handleButtonSubmit}
                ref={eButtonSubmit}>
                変更
              </Button>
              <Button
                className="w-full basis-full justify-center rounded-md border bg-transparent text-xs text-orange-600"
                style={{
                  minWidth: '256px',
                }}
                type="submit"
                onClick={handleButtonDelete}
                ref={eButtonDelete}>
                削除
              </Button>
            </>
          )}
          <Link
            to=".."
            className={
              'mx-auto w-full  basis-full rounded-md border border-gray-600  text-center ' +
              (eButtonModeEdit.current?.checked ? 'p-2.5' : 'pt-1')
            }
            style={{
              minWidth: '256px',
            }}>
            {eButtonModeEdit.current?.checked ? 'キャンセル' : '戻る'}
          </Link>
        </div>

        <MapContainer
          attributionControl={false}
          minZoom={5}
          zoom={9}
          center={[33.9195, 133.1811]}
          style={{
            height: '80vh',
            width: '20vw',
            minWidth: '48rem',
            border: '0.25rem solid',
            flexBasis: '100%',
          }}
          ref={map}>
          <TileLayer
            url="https://cyberjapandata.gsi.go.jp/xyz/std/{z}/{x}/{y}.png"
            ref={baseTileLayer}
            maxNativeZoom={18}
          />
          <Control
            position="topright"
            container={{
              className: 'flex bg-none flex-col',
            }}>
            <SelectBoxBaseLayer baseTileLayer={baseTileLayer} />
          </Control>

          <GeoJSON
            ref={eGeoJSON}
            data={{ type: 'FeatureCollection', features: [] } as GeoJsonObject}
            pointToLayer={(feature, latlng) =>
              marker(latlng, {
                icon: new Icon({
                  iconUrl: `${environment.asset}assets/marker-icon.png`,
                  shadowUrl: `${environment.asset}assets/marker-shadow.png`,
                  iconSize: [25, 41],
                  iconAnchor: [12, 41],
                }),
              })
            }
          />
        </MapContainer>
      </div>
    </form>
  ) : (
    <></>
  );
}
