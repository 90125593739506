import type { PayloadAction } from '@reduxjs/toolkit';
import { createSlice } from '@reduxjs/toolkit';

const initialState = {
  enable: true,
  enableControlTopRight: true,
};

export const sidebarSlice = createSlice({
  name: 'sidebarSlice',
  initialState,
  reducers: {
    setEnableSidebar: (state, action: PayloadAction<boolean>) => {
      // Redux Toolkit allows us to write "mutating" logic in reducers. It
      // doesn't actually mutate the state because it uses the Immer library,
      // which detects changes to a "draft state" and produces a brand new
      // immutable state based off those changes

      state.enable = action.payload;
    },
  },
});

// Action creators are generated for each case reducer function
export const { setEnableSidebar } = sidebarSlice.actions;

export const sidebarReducer = sidebarSlice.reducer;
