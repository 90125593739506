import { createSlice, PayloadAction } from '@reduxjs/toolkit';

export const BASE_MAP_URL = {
  std: new URL('https://cyberjapandata.gsi.go.jp/xyz/std/{z}/{x}/{y}.png'),
  pale: new URL('https://cyberjapandata.gsi.go.jp/xyz/pale/{z}/{x}/{y}.png'),
  relief: new URL('https://cyberjapandata.gsi.go.jp/xyz/relief/{z}/{x}/{y}.png'),
  gray_std: new URL('https://cyberjapandata.gsi.go.jp/xyz/std/{z}/{x}/{y}.png?gray=true'),
  openstreetmap: new URL('https://{s}.tile.openstreetmap.jp/{z}/{x}/{y}.png'),
  photo: new URL('https://cyberjapandata.gsi.go.jp/xyz/seamlessphoto/{z}/{x}/{y}.jpg'),
};

const initialState = {
  url: decodeURI(BASE_MAP_URL.pale.href),
};

export const baseLayerSlice = createSlice({
  name: 'baseLayerSlice',
  initialState,
  reducers: {
    setBaseLayerUrl: (state, action: PayloadAction<string>) => {
      state.url = action.payload;
    },
  },
});

// Action creators are generated for each case reducer function
export const { setBaseLayerUrl } = baseLayerSlice.actions;

export const baseLayerReducer = baseLayerSlice.reducer;
