import React from 'react';
import { twMerge } from 'tailwind-merge';
import { uniqueId } from '../dayjs/unique-id';
import { LabelProps as FlowbiteLabelProps } from 'flowbite-react';
import {disabled} from "react-widgets/PropTypes";

interface LabelProps extends FlowbiteLabelProps {
  color?: string;
}

export const RadioGroup = React.forwardRef<
  HTMLDivElement,
  {
    options: { value: string; label: string }[];
    name: string;
    selectedValue: string;
    onChange: (value: string) => void;
    containerProps?: React.HTMLProps<HTMLDivElement>;
    labelProps?: LabelProps; // Sử dụng LabelProps tùy chỉnh
    disabledProp?: boolean;
  }
>(({ options, name, selectedValue, onChange, containerProps, labelProps, disabledProp }, ref) => {
  const id = uniqueId('RadioGroup-');

  return (
    <div {...containerProps} ref={ref} className={twMerge('flex flex-col gap-3', containerProps?.className)}>
      {labelProps && (
        <label
          {...labelProps}
          className={twMerge('mb-2 block text-sm font-normal text-gray-900 dark:text-white', labelProps?.className)}
          htmlFor={id}
        />
      )}

      <div className="flex gap-4">
        {options.map((option) => (
          <div key={option.value} className="flex items-center gap-2">
            <input
              type="radio"
              id={option.value}
              name={name}
              value={option.value}
              checked={selectedValue === option.value}
              onChange={() => onChange(option.value)}
              className="form-radio text-blue-600"
              disabled={disabledProp ?? true}
            />
            <label
              htmlFor={option.value}
              className="text-sm text-gray-900 dark:text-white"
            >
              {option.label}
            </label>
          </div>
        ))}
      </div>
    </div>
  );
});
