import ReactDOM from 'react-dom/client';
import { Provider } from 'react-redux';
import { BrowserRouter } from 'react-router-dom';
import App from './App';
import './index.css';
import { GlobalHistory } from './lib/react-router-dom/global-history';
import { store } from './redux/store';
import 'react-widgets/scss/styles.scss';

const root = ReactDOM.createRoot(document.getElementById('root') as HTMLElement);
root.render(
  <Provider store={store}>
    <BrowserRouter>
      <GlobalHistory />
      <App />
    </BrowserRouter>
  </Provider>,
);
