export const data = {
  labels: ['Jan', 'Feb', 'Mar', 'Apr', 'May', 'Jun', 'Jul', 'Aug', 'Sep', 'Oct', 'Nov', 'Dec'],
  datasets: [
    {
      label: '流量',
      data: [] as string[] | number[] | null[],
      fill: true,
      backgroundColor: 'rgba(75,192,192,0.2)',
      borderColor: '#015CAC',
    },
  ],
};

export const config = {
  type: 'line',
  data: data,
  options: {
    title: {
      display: false,
      text: '',
    },
    scales: {
      xAxes: [
        {
          scaleLabel: {
            display: true,
            labelString: '年',
          },
          ticks: {
            max: 100,
            min: 0,
          },
        },
      ],
      yAxes: [
        {
          scaleLabel: {
            display: true,
            labelString: '流量 (m3/s)',
          },
          ticks: {
            max: 1500,
            min: 0,
          },
        },
      ],
    },
    tooltips: {
      mode: 'nearest',
      intersect: false,
    },
    hover: {
      mode: 'nearest',
      intersect: false,
    },
  },
};
