import { useEffect, useRef, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { Button } from 'src/lib/components/Button.component';
import { Table, TableRefObject } from 'src/lib/components/Table.component';
import { fdelete, fget } from 'src/lib/fetch';
import { DamEntity } from '../../dto/dam/dam.entity';
import { DeleteDamReq } from '../../dto/dam/dam.req';
import { ResponseListDocuments } from '../../dto/response';

export function DamsPage() {
  const eTable = useRef<TableRefObject>({} as TableRefObject);

  const [dams, setDams] = useState<ResponseListDocuments<DamEntity>>();

  const navigate = useNavigate();

  const fetchData = async () => {
    try {
      const [_, data] = await fget<unknown, typeof dams>('api/dams');
      if (!data || !data?.Items?.length) return;
      setDams(data);
    } catch (error) {
      console.error('An error occurred:', error);
    }
  };

  const handleButtonDelete = async (input: DeleteDamReq) => {
    const id = input.dam.DAM_ID;
    try {
      await fdelete<DeleteDamReq>(`api/dams/${id}`);
      fetchData();
    } catch (error) {
      console.error('An error occurred:', error);
    }
  };

  useEffect(() => {
    fetchData();
  }, []);

  return (
    <div className="flex flex-row flex-wrap px-4 pt-12 md:px-12 md:py-8">
      <h1 className="mb-2 whitespace-nowrap  text-2xl">ダム情報</h1>
      <span className="w-full border-b border-slate-800"></span>

      <Button
        className="ml-auto mt-4 bg-black text-white"
        onClick={(e) => {
          navigate('create');
        }}>
        <svg className="mr-2 h-4 w-4 text-white" fill="none" viewBox="0 0 24 24" stroke="currentColor">
          <path strokeLinecap="round" strokeLinejoin="round" strokeWidth="2" d="M12 4v16m8-8H4" />
        </svg>
        新しいダム座標を追加
      </Button>

      <div className="relative mt-4 block w-full basis-full overflow-x-auto rounded-md border p-4 shadow-md">
        <Table
          hideCheckbox
          headCellData={['ID', 'ダム名', '川の名前', '座標', '地域名', '']}
          ref={eTable}
          cellData={dams?.Items?.map((dam, i) => [
            dam.DAM_ID.S,
            dam.DAM_NUMBER.S,
            dam.RIVER_NAME.S,
            dam.DAM_COORDINATE.S,
            dam.DAM_AREA.S,
            <a
              href="#"
              className="whitespace-nowrap font-medium text-red-500 hover:underline dark:text-blue-500"
              onClick={(e) => {
                e.stopPropagation();

                handleButtonDelete({
                  dam: { DAM_ID: dam.DAM_ID.S, DAM_NUMBER: dam.DAM_NUMBER.S },
                });
              }}>
              削除
            </a>,
          ])}
          rowProps={{
            onClick: (e) => {
              navigate(dams?.Items?.at(+(e.currentTarget.id.split('-').at(-1) || 0))?.DAM_ID.S || '');
            },
            className: 'cursor-pointer',
          }}
        />
      </div>
    </div>
  );
}
