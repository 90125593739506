import { createSlice, PayloadAction } from '@reduxjs/toolkit';

const initialState = {
  elevationLayer: false,
};

export const enableLayerStatusSlice = createSlice({
  name: 'enableLayerStatusSlice',
  initialState,
  reducers: {
    setEnableLayers: (state, action: PayloadAction<typeof initialState>) => {
      return { ...state, ...action.payload };
    },
  },
});

// Action creators are generated for each case reducer function
export const { setEnableLayers } = enableLayerStatusSlice.actions;

export const enableLayerStatusReducer = enableLayerStatusSlice.reducer;
