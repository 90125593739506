import ApexCharts from 'apexcharts';
import debounce from 'lodash.debounce';
import { useCallback, useEffect, useMemo, useRef, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { useAuth } from 'src/lib/auth/use-auth';
import { dayts } from 'src/lib/dayjs';
import { fget, fput } from 'src/lib/fetch';
import { TargetPoint } from 'src/modules/dashboard/dto/graph/target-point.entity';
import { Waterlevel } from 'src/modules/dashboard/dto/graph/waterlevel.entity';
import { ResponseDocument, ResponseListDocuments } from 'src/modules/dashboard/dto/response';
import { UserEntity } from 'src/modules/dashboard/dto/user/user.entity';
import { ChartMaxWaterlevel, ChartMaxWaterlevelRef } from '../components/ChartMaxWaterlevel.component';
import { ChartMemo } from '../components/ChartMemo.component';
import { GraphControls, GraphControlsRef } from '../components/GraphControls.component';
import { ResetButton } from '../components/ResetButton.component';
// eslint-disable-next-line @typescript-eslint/no-var-requires
const gc = require('js-gc');

const notGraphTargetPoints = new Set(['first', '仁池CH-1', '仁池CH-2']);

export function Graph() {
  const { user } = useAuth();
  const [usr, setUsr] = useState(user?.user);
  const navigate = useNavigate();

  const [points, setPoints] = useState<Record<string, ResponseDocument<TargetPoint>>>({});

  const [targetpoints, setTargetPoints] = useState<string[]>([]);

  const waterlevelsRef = useRef<Record<string, number[]>>({});
  const isXAxisSet = useRef(new Map());
  const isTargetPointsSet = useRef(false);

  const graphChartsRef = useRef<Record<string, ApexCharts>>({});
  const graphControlsRef = useRef<Record<string, GraphControlsRef>>({});

  const resetButtonRef = useRef<any>();

  const interval = useRef(8888);
  const lastTime = useRef(0);
  const isDataFetch = useRef(false);
  const pointsRef = useRef<typeof points>({});
  const isUpdateRef = useRef(false);
  const animationFrameRef = useRef(0);

  const maxWaterLevelIndexRef = useRef<Record<string, ChartMaxWaterlevelRef>>({});

  const getTargetPoints = useCallback(
    async (currentTime: number) => {
      if (currentTime - lastTime.current >= interval.current) {
        try {
          await fget<unknown, ResponseListDocuments<TargetPoint>>('api/kagawa/target-points').then(([_, pData]) => {
            const _points =
              pData.Items?.reduce((res, p) => {
                res[p.TARGET_POINT?.S || ''] = p;
                return res;
              }, {} as typeof points) || {};

            if (JSON.stringify(_points) === JSON.stringify(pointsRef.current)) return;

            pointsRef.current = _points;
            // if (!isUpdateRef.current) setPoints(_points);
            // isUpdateRef.current = false;
            setPoints(_points);

            if (!isTargetPointsSet.current) {
              isTargetPointsSet.current = true;
              setTargetPoints(Object.keys(pointsRef.current));
            }
          });
          lastTime.current = currentTime;
        } catch (e: any) {
          cancelAnimationFrame(animationFrameRef.current);

          if (e.status === 403) {
            navigate('/404');
          }

          return;
        }
      }
      if (isDataFetch.current) animationFrameRef.current = requestAnimationFrame(getTargetPoints);
      isDataFetch.current = true;
    },
    [points],
  );

  useEffect(() => {
    fget<unknown, ResponseListDocuments<UserEntity>>(`api/users/${user?.userId}`).then((user) => {
      setUsr(user[1].Items?.[0]);
    });
    getTargetPoints(15000).then(() => {
      animationFrameRef.current = requestAnimationFrame(getTargetPoints);
    });
  }, []);

  useEffect(() => {
    if (!targetpoints.length) return;

    const showTime = new Set(
      Array.from({ length: 30 }, (_, i) => `${9 + Math.floor(i / 2)}:${i % 2 === 0 ? '00' : '30'}`),
    );

    const fetchData = async (targetpoint: string) => {
      graphControlsRef.current[targetpoint]?.setLabel(
        dayts()
          .set('hours', 9)
          .set('minutes', 0)
          .add(+(points[targetpoint].VALUE.S || 0) + +(points[targetpoint].MAX_WL_IDX.S || 0), 'minutes')
          .format('HH:mm'),
      );
      maxWaterLevelIndexRef.current[targetpoint]?.setValue(
        dayts()
          .set('hours', 9)
          .set('minutes', 0)
          .add(+(points[targetpoint].VALUE.S || 0) + +(points[targetpoint].MAX_WL_IDX.S || 0), 'minutes')
          .format('HH:mm'),
      );

      if (notGraphTargetPoints.has(targetpoint)) return;

      const [_, wt] = await fget<unknown, ResponseListDocuments<Waterlevel>>(
        `api/kagawa/waterlevels?targetPointName=${targetpoint}`,
      );

      const waterlevelData = wt.Items?.map((item) => +(item.VALUE?.S || 0)) || [];
      waterlevelsRef.current[targetpoint] = waterlevelData;

      // if (!isXAxisSet.current.has(targetpoint)) {
      graphChartsRef.current[targetpoint].updateOptions({
        xaxis: {
          categories: wt.Items?.map((item) => item.TIMESTAMP.S),
          labels: {
            formatter(value: any) {
              if (value && showTime.has(value)) return value;
              return '';
            },
          },
          tooltip: {
            formatter(value: any) {
              return wt.Items?.[+value]?.TIMESTAMP.S || value;
            },
          },
        },
        yaxis: {
          labels: {
            formatter(value: any) {
              return +value?.toFixed(1) * 100 + '%';
            },
          },
          stepSize: 0.2,
        },
      });

      graphChartsRef.current[targetpoint].zoomX(0, 301);

      isXAxisSet.current.set(targetpoint, true);

      // }

      const forecastData = waterlevelData.slice(0, 301) || [];

      const targetPointValue = +(points[targetpoint].VALUE.S || 0);
      const realData =
        targetPointValue <= 0
          ? waterlevelData.slice(0 - targetPointValue, 301 - targetPointValue) || []
          : Array.from({ length: targetPointValue }, () => 0).concat(...forecastData);

      graphChartsRef.current[targetpoint].updateSeries(
        [
          // {
          //   data: forecastData,
          //   color: '#0000EE',
          //   name: '予測値',
          //   type: 'line',
          // },
          {
            data: realData,
            color: '#ff0000',
            name: '実測値',
            type: 'line',
          },
        ],
        false,
      );
    };

    const fetchDataAll = async () => {
      const chunks = (arr: any, size: number) =>
        Array.from({ length: Math.ceil(arr.length / size) }, (_, i) => arr.slice(i * size, i * size + size));

      for (const chunk of chunks(targetpoints, 3)) {
        await Promise.all(chunk.map(fetchData));
      }
    };
    fetchDataAll();
  }, [JSON.stringify(points)]);

  const updateTargetPoint = (currentTargetpoint: string, dis: number, targetpointsData: any[]) => {
    const currentPointValue = +(pointsRef.current[currentTargetpoint].VALUE.S || 0);
    const targetPointValue = currentPointValue + dis;
    const waterlevelData = waterlevelsRef.current[currentTargetpoint] || [];

    pointsRef.current[currentTargetpoint].VALUE.S = String(targetPointValue);

    const forecastData = waterlevelData.slice(0, 301) || [];
    const realLifeData =
      targetPointValue <= 0
        ? waterlevelData.slice(0 - targetPointValue, 301 - targetPointValue) || []
        : Array.from({ length: targetPointValue }, () => 0).concat(...(waterlevelData.slice(0, 301) || []));

    targetpointsData.push({ targetPointName: currentTargetpoint, value: targetPointValue });

    graphControlsRef.current[currentTargetpoint]?.setLabel(
      dayts()
        .set('hours', 9)
        .set('minutes', 0)
        .add(targetPointValue + +(pointsRef.current[currentTargetpoint].MAX_WL_IDX.S || 0), 'minutes')
        .format('HH:mm'),
    );
    graphControlsRef.current[currentTargetpoint]?.setNewValue(targetPointValue);

    maxWaterLevelIndexRef.current[currentTargetpoint]?.setValue(
      dayts()
        .set('hours', 9)
        .set('minutes', 0)
        .add(targetPointValue + +(pointsRef.current[currentTargetpoint].MAX_WL_IDX.S || 0), 'minutes')
        .format('HH:mm'),
    );

    if (notGraphTargetPoints.has(currentTargetpoint)) return;
    graphChartsRef.current[currentTargetpoint].updateSeries(
      [
        // {
        //   data: forecastData,
        //   color: '#0000EE',
        //   name: '予測値',
        //   type: 'line',
        // },
        {
          data: realLifeData,
          color: '#ff0000',
          name: '実測値',
          type: 'line',
        },
      ],
      false,
    );
  };

  const updateWaterlevelsHandler = async (targetpoint: string, value: number) => {
    gc();

    const dis = value - +(pointsRef.current[targetpoint].VALUE.S || 0);

    let isThisTarget = false;

    const targetpointsData: any = [];

    for (const currentTargetpoint of targetpoints) {
      if (targetpoint === currentTargetpoint) {
        isThisTarget = true;
      }
      if (!isThisTarget) continue;
      if (notGraphTargetPoints.has(currentTargetpoint)) continue;

      updateTargetPoint(currentTargetpoint, dis, targetpointsData);
    }

    await fput(`api/kagawa/target-points`, {
      body: targetpointsData,
    });
  };

  const updateWaterlevels = useMemo(() => {
    return debounce(({ targetpoint, value }: { targetpoint: string; value: number }) => {
      updateWaterlevelsHandler(targetpoint, value);
      gc();
    }, 600);
  }, [targetpoints]);

  const isEditable = useMemo(() => {
    return usr?.USER_ROLE?.S === 'admin' || usr?.KAGAWA_EDITABLE?.S?.toLocaleLowerCase() === '1';
  }, [usr]);

  const onChChange = (dis: number, ch: string) => {
    const targetpointsData: any = [];
    updateTargetPoint('仁池CH', dis, targetpointsData);
    updateTargetPoint(ch, dis, targetpointsData);

    fput(`api/kagawa/target-points`, {
      body: targetpointsData,
    });
  };

  return (
    <>
      <ul className="fixed left-0 top-0 z-10 ml-4 flex h-10 w-[calc(100%-32px)] border border-slate-300 bg-white">
        {targetpoints.length ? (
          <ResetButton
            onClick={async () => {
              gc();
              const promises = [];
              for (let i = 0; i < targetpoints.length; i++) {
                const targetpoint = targetpoints[i];
                graphControlsRef.current[targetpoint]?.setNewValue(0);
                promises.push(updateWaterlevelsHandler(targetpoint, 0));
              }
              await Promise.all(promises);
            }}
            ref={resetButtonRef}
          />
        ) : (
          <> </>
        )}
      </ul>
      {!targetpoints.length && <div className="bg-gray-200">読み込み中...</div>}
      <div className="grid grid-cols-1 gap-8 bg-gray-200 p-4 pt-12 max-md:gap-4 max-sm:p-1 sm:grid-cols-2">
        {targetpoints
          ?.filter((targetpoint) => !notGraphTargetPoints.has(targetpoint))
          .map((targetpoint, i, arr) => (
            <div className="relative z-0 flex flex-col rounded-md border bg-white p-4 max-sm:p-2" key={targetpoint}>
              <ChartMaxWaterlevel
                ref={(ref) => {
                  if (ref) maxWaterLevelIndexRef.current[targetpoint] = ref;
                }}
              />
              <ChartMemo targetpoint={targetpoint} points={points[targetpoint]} graphChartsRef={graphChartsRef} />
              {isEditable && targetpoint === '東西分水工' && (
                  <GraphControls
                  labelText="取水開始"
                  defaultValue={+(points['first'].VALUE.S || 0)}
                  onChangeLabel={(label) => {
                    const [h, m] = label.split(':');
                    if (!h || !m) return;
                    const diff = +h * 60 + +m - 9 * 60 - +(points['first'].MAX_WL_IDX.S || 0);
                    graphControlsRef.current['first']?.setNewValue(diff);

                    isUpdateRef.current = true;
                    updateWaterlevels({ targetpoint: 'first', value: diff });
                  }}
                  onChange={(value) => {
                    graphControlsRef.current['first']?.setLabel(
                      dayts()
                        .set('hours', 9)
                        .set('minutes', 0)
                        .add(value + +(points['first'].MAX_WL_IDX.S || 0), 'minutes')
                        .format('HH:mm'),
                    );
                    isUpdateRef.current = true;
                    updateWaterlevels({ targetpoint: 'first', value });
                  }}
                  ref={(ref) => {
                    if (!ref) return;
                    graphControlsRef.current['first'] = ref;
                  }}
                />
              )}
              {isEditable && targetpoint === '仁池CH' && (
                <div className="mx-16 rounded-lg bg-orange-50 px-4 pb-4">
                  <GraphControls
                    max={30}
                    labelText="水先到達"
                    defaultValue={+(points['仁池CH-1'].VALUE.S || 0)}
                    onChangeLabel={(label) => {
                      const [h, m] = label.split(':');
                      if (!h || !m) return;
                      const diff = +h * 60 + +m - 9 * 60 - +(points['仁池CH-1'].MAX_WL_IDX.S || 0);
                      graphControlsRef.current['仁池CH-1']?.setNewValue(diff);

                      isUpdateRef.current = true;
                      const dis = diff - +(points['仁池CH-1'].VALUE.S || 0);

                      onChChange(dis, '仁池CH-1');
                    }}
                    onChange={(value) => {
                      graphControlsRef.current['仁池CH-1']?.setLabel(
                        dayts()
                          .set('hours', 9)
                          .set('minutes', 0)
                          .add(value + +(points[targetpoint].MAX_WL_IDX.S || 0), 'minutes')
                          .format('HH:mm'),
                      );
                      isUpdateRef.current = true;
                      const dis = value - +(points['仁池CH-1'].VALUE.S || 0);
                      onChChange(dis, '仁池CH-1');
                    }}
                    ref={(ref) => {
                      if (!ref) return;
                      graphControlsRef.current['仁池CH-1'] = ref;
                    }}
                  />
                  <p className="pl-10">羽間</p>
                  <GraphControls
                    max={30}
                    labelText="水先到達"
                    defaultValue={+(points['仁池CH-2'].VALUE.S || 0)}
                    onChangeLabel={(label) => {
                      const [h, m] = label.split(':');
                      if (!h || !m) return;
                      const diff = +h * 60 + +m - 9 * 60 - +(points['仁池CH-2'].MAX_WL_IDX.S || 0);
                      graphControlsRef.current['仁池CH-2']?.setNewValue(diff);

                      isUpdateRef.current = true;
                      const dis = diff - +(points['仁池CH-2'].VALUE.S || 0);

                      onChChange(dis, '仁池CH-2');
                    }}
                    onChange={(value) => {
                      graphControlsRef.current['仁池CH-2']?.setLabel(
                        dayts()
                          .set('hours', 9)
                          .set('minutes', 0)
                          .add(value + +(points[targetpoint].MAX_WL_IDX.S || 0), 'minutes')
                          .format('HH:mm'),
                      );
                      isUpdateRef.current = true;
                      const dis = value - +(pointsRef.current['仁池CH-2'].VALUE.S || 0);
                      onChChange(dis, '仁池CH-2');
                    }}
                    ref={(ref) => {
                      if (!ref) return;
                      graphControlsRef.current['仁池CH-2'] = ref;
                    }}
                  />
                  <p className="pl-10">岡田</p>
                </div>
              )}

              {isEditable && i !== arr.length - 1 && (
                <GraphControls
                    labelText="水先到達"
                  defaultValue={+(points[targetpoint].VALUE.S || 0)}
                  onChangeLabel={(label) => {
                    const [h, m] = label.split(':');
                    if (!h || !m) return;
                    const diff = +h * 60 + +m - 9 * 60 - +(points[targetpoint].MAX_WL_IDX.S || 0);
                    graphControlsRef.current[targetpoint]?.setNewValue(diff);

                    isUpdateRef.current = true;
                    updateWaterlevels({ targetpoint: targetpoint, value: diff });
                  }}
                  onChange={(value) => {
                    graphControlsRef.current[targetpoint]?.setLabel(
                      dayts()
                        .set('hours', 9)
                        .set('minutes', 0)
                        .add(value + +(points[targetpoint].MAX_WL_IDX.S || 0), 'minutes')
                        .format('HH:mm'),
                    );
                    isUpdateRef.current = true;
                    updateWaterlevels({ targetpoint, value });
                  }}
                  ref={(ref) => {
                    if (!ref) return;
                    graphControlsRef.current[targetpoint] = ref;
                  }}
                />
              )}
            </div>
          ))}
      </div>
    </>
  );
}
