import React from 'react';

export const DashboardHeader = React.memo(() => {
  return (
    <header
      id="dashboard-header"
      className="flex w-screen flex-col justify-center border-b border-solid border-blue-400 bg-slate-50 text-sky-700">
      <span
        className="my-auto ml-3 whitespace-nowrap text-4xl font-semibold"
        style={{
          font: 'Noto Sans',
        }}>
        WATERGRAPH管理
      </span>
    </header>
  );
});
