import { useEffect, useRef, useState } from 'react';
import { Link, useNavigate, useParams } from 'react-router-dom';
import Select from 'react-select';
import { Button } from 'src/lib/components/Button.component';
import { Checkbox } from 'src/lib/components/Checkbox.component';
import { TextInput } from 'src/lib/components/TextInput.component';
import { toasting } from 'src/lib/components/Toast.component';
import { fdelete, fget, fput } from 'src/lib/fetch';
import locale from 'src/locales/ja.json';
import { AreaEntity } from '../../dto/area/area.entity';
import { ResponseDocument, ResponseListDocuments } from '../../dto/response';
import { UserEntity } from '../../dto/user/user.entity';
import { DeleteUserReq, UpdateUserReq } from '../../dto/user/user.req';

export function EditUserPage() {
  const eForm = useRef<HTMLFormElement>(null);

  const eTextUsername = useRef<HTMLInputElement>(null);
  const eTextEmail = useRef<HTMLInputElement>(null);
  const eTextPassword = useRef<HTMLInputElement>(null);
  const eTextRePassword = useRef<HTMLInputElement>(null);

  const eButtonSubmit = useRef<HTMLButtonElement>(null);
  const eButtonDelete = useRef<HTMLButtonElement>(null);

  const eButtonModeEdit = useRef<HTMLInputElement>(null);
  const eTextInputKagawaEditable = useRef<HTMLInputElement>(null);

  const [checkedModeEdit, setCheckedModeEdit] = useState<boolean>(false);

  const navigate = useNavigate();

  const { id } = useParams();
  const [data, setData] = useState<{
    user?: ResponseDocument<UserEntity>;
  }>();

  const [selectedAreas, setSelectedAreas] = useState<any>(new Set());

  const [areas, setAreas] = useState<any[]>([]);

  const [currAreas, setCurrAreas] = useState<any[]>([]);

  const [showEditWaterLevelCheckbox, setShowEditWaterLevelCheckbox] = useState<boolean>(false);
  const [kagawaChecked, setKagawaChecked] = useState<boolean>(false);

  const onSelectAreaChange = (e: any) => {
    setSelectedAreas(new Set(e.map((item: any) => item.value)));
    setCurrAreas(e.map((item: any) => item.value));
  };

  useEffect(() => {
    if (!currAreas) return;
    if (currAreas.includes('香川用水')) {
      setShowEditWaterLevelCheckbox(true);
    } else {
      setShowEditWaterLevelCheckbox(false);
    }
  }, [currAreas]);

  const handleButtonSubmit = async (e: React.MouseEvent<HTMLButtonElement, MouseEvent>) => {
    e.preventDefault();
    if (!eForm.current?.checkValidity()) return eForm.current?.reportValidity();

    try {
      const body: any = {
        user: {
          USER_NAME: eTextUsername.current?.value || '',
          USER_MAIL: eTextEmail.current?.value || '',
          USER_AREAS: Array.from(selectedAreas),
          USER_ROLE: 'user',
          KAGAWA_EDITABLE: eTextInputKagawaEditable.current?.checked ? '1' : '0',
        },
      };

      if (eTextPassword.current?.value) {
        body.user.USER_PASS = eTextPassword.current?.value;
      }

      await fput<UpdateUserReq>(`api/users/${id}`, {
        body,
      });

      navigate('..');
    } catch (e) {
      console.error(e);
      toasting({ children: '何か問題が発生しました', containerProps: { className: 'border-red-600' } });
    }
  };

  const handleButtonDelete = async (e: React.MouseEvent<HTMLButtonElement, MouseEvent>) => {
    e.preventDefault();
    if (!eForm.current?.checkValidity()) return eForm.current?.reportValidity();

    const id = data?.user?.USER_ID.S;
    try {
      await fdelete<DeleteUserReq>(`api/users/${id}`, {
        body: {
          user: {
            USER_ID: id,
            USER_MAIL: data?.user?.USER_MAIL.S,
          },
        },
      });
      navigate('..');
    } catch (e) {
      console.error(e);
      toasting({ children: '何か問題が発生しました', containerProps: { className: 'border-red-600' } });
    }
  };

  const fetchData = async () => {
    try {
      const [userResponse, areaResponse] = await Promise.all([
        fget<unknown, ResponseListDocuments<UserEntity>>(`api/users/${id}`),
        fget<unknown, ResponseListDocuments<AreaEntity>>('api/areas'),
      ]);

      const areaItems = areaResponse?.[1]?.Items || [];
      setAreas(areaItems);

      const user = userResponse?.[1]?.Items?.[0];
      if (user?.KAGAWA_EDITABLE?.S === '1') {
        setKagawaChecked(true);
      }

      setData({ user });

      const areas = user?.USER_AREAS?.L || [];
      setCurrAreas(areas.map((area: any) => area.S));
      setSelectedAreas(new Set(areas.map((area: any) => area.S)));
    } catch (error) {
      console.error(error);
      toasting({ children: '何か問題が発生しました', containerProps: { className: 'border-red-600' } });
    }
  };

  useEffect(() => {
    if (!id) return;

    fetchData();
  }, [id]);

  return (
    <form className="flex flex-row flex-wrap px-4 pt-12 md:px-12 md:py-8" ref={eForm}>
      <h1 className="mb-2 whitespace-nowrap text-2xl">ユーザー追加</h1>
      <span className="w-full border-b border-slate-800"></span>

      <div className="flex basis-full justify-end">
        <Checkbox
          className="hidden"
          onChange={(e) => setCheckedModeEdit(e.target.checked)}
          defaultChecked={checkedModeEdit}
          ref={eButtonModeEdit}
          containerProps={{
            className: `mt-4 ml-auto border border-gray-500`,
          }}
          labelProps={{
            children: '編集モード',
            className: `select-none p-2 ${checkedModeEdit ? 'bg-black text-white' : ''}`,
          }}
        />
      </div>

      <div className="flex w-2/5 flex-row flex-wrap">
        <TextInput
          labelProps={{
            children: 'ID',
          }}
          type="text"
          defaultValue={data?.user?.USER_ID.S}
          containerProps={{
            className: 'mt-4 w-1/3 basis-full',
            style: {
              minWidth: '24rem',
            },
          }}
          readOnly
          disabled
          required
        />

        <TextInput
          labelProps={{
            children: 'ユーザー名',
          }}
          type="text"
          defaultValue={data?.user?.USER_NAME.S}
          containerProps={{
            className: 'mt-4 w-1/3 basis-full',
            style: {
              minWidth: '24rem',
            },
          }}
          required
          ref={eTextUsername}
          disabled={!checkedModeEdit}
        />

        <TextInput
          labelProps={{
            children: 'メールアドレス',
          }}
          type="email"
          defaultValue={data?.user?.USER_MAIL.S}
          containerProps={{
            className: 'mt-4 w-1/3 basis-full',
            style: {
              minWidth: '24rem',
            },
          }}
          required
          ref={eTextEmail}
          disabled={true}
        />

        <TextInput
          labelProps={{
            children: 'パスワード',
          }}
          type="password"
          containerProps={{
            className: 'mt-4 w-1/3 basis-full',
            style: {
              minWidth: '24rem',
            },
          }}
          onChange={(e) => {
            if (e.target.value !== eTextPassword.current?.value) {
              e.target.setCustomValidity('Password and re-password do not match.');
            } else {
              // Reset custom validity when they match
              e.target.setCustomValidity('');
            }
          }}
          ref={eTextPassword}
          disabled={!checkedModeEdit}
        />

        <TextInput
          labelProps={{
            children: '再パスワード',
          }}
          type="password"
          containerProps={{
            className: 'mt-4 w-1/3 basis-full',
            style: {
              minWidth: '24rem',
            },
          }}
          onChange={(e) => {
            if (e.target.value !== eTextPassword.current?.value) {
              e.target.setCustomValidity('Password and re-password do not match.');
            } else {
              // Reset custom validity when they match
              e.target.setCustomValidity('');
            }
          }}
          ref={eTextRePassword}
          disabled={!checkedModeEdit}
        />
        {showEditWaterLevelCheckbox && (
          <div className="mt-4 flex items-center gap-5">
            <p className="text-sm text-[#000000]">Can edit Kagawa predicted</p>
            <input
              type="checkbox"
              ref={eTextInputKagawaEditable}
              disabled={!checkedModeEdit}
              checked={kagawaChecked}
              onChange={() => {
                setKagawaChecked(!kagawaChecked);
              }}
            />
          </div>
        )}
        <label className="mt-4 w-1/3 basis-full">
          <span className="text-sm text-[#000000]">{locale['area']['ja']}</span>
        </label>
        <Select
          isMulti
          name="areas"
          value={currAreas.map((area) => {
            return { label: area, value: area };
          })}
          options={areas.map((area) => {
            return {
              label: area?.AREA_NAME?.S,
              value: area?.AREA_NAME?.S,
            };
          })}
          className="mt-4 w-1/3 basis-full"
          onChange={onSelectAreaChange}
          isDisabled={!checkedModeEdit}
        />

        <div
          className="mt-8 flex basis-full flex-col items-center"
          style={{
            minWidth: '24rem',
          }}>
          {checkedModeEdit && (
            <>
              <Button
                className="w-1/2 basis-full justify-center rounded-md bg-black text-xs text-white"
                style={{
                  minWidth: '256px',
                }}
                type="submit"
                onClick={handleButtonSubmit}
                ref={eButtonSubmit}>
                編集
              </Button>
              <Button
                className="mt-4 w-1/2 basis-full justify-center rounded-md bg-transparent text-xs text-orange-600"
                style={{
                  minWidth: '256px',
                }}
                type="submit"
                onClick={handleButtonDelete}
                ref={eButtonDelete}>
                削除
              </Button>
            </>
          )}

          <Link
            className="mx-auto mt-4 w-1/2 basis-full rounded-md border border-gray-600 bg-transparent p-2.5  text-center text-xs"
            to=".."
            style={{
              minWidth: '256px',
            }}>
            {eButtonModeEdit.current?.checked ? 'キャンセル' : '戻る'}
          </Link>
        </div>
      </div>
    </form>
  );
}
