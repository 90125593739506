import { Select as FlowBiteSelect, Label, LabelProps, SelectProps } from 'flowbite-react';
import React, { useEffect, useImperativeHandle, useRef } from 'react';
import { twMerge } from 'tailwind-merge';
import { uniqueId } from '../dayjs/unique-id';
import { Props } from '../type/props.type';

export const Select = React.forwardRef<
  HTMLSelectElement,
  SelectProps & { labelProps?: LabelProps; containerProps?: Props<'div'> }
>(({ id = uniqueId('SelectBox-'), labelProps, containerProps, className, ...props }, ref) => {
  const e = useRef<HTMLSelectElement>({} as HTMLSelectElement);

  useEffect(() => {
    if (e.current) e.current.className = twMerge(e.current.className, 'disabled:bg-transparent', className);
  }, []);

  useImperativeHandle(ref, () => e.current);

  return (
    <div {...containerProps}>
      {labelProps && (
        <div className="block">
          <Label
            {...labelProps}
            htmlFor={id}
            className={twMerge('mb-2 block font-medium text-gray-900 dark:text-white', labelProps?.className)}
          />
        </div>
      )}
      <FlowBiteSelect {...props} id={id} ref={e} />
    </div>
  );
});
