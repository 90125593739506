import { Map, TileLayer as TileLayerEntity } from 'leaflet';
import {useMemo, useRef, useState} from 'react';
import Control from 'react-leaflet-custom-control';
import { useDispatch } from 'react-redux';
import { Link, useNavigate } from 'react-router-dom';
import { Checkbox } from 'src/lib/components/Checkbox.component';
import { uniqueId } from 'src/lib/dayjs/unique-id';
import { CheckBoxFloodRiskComponent } from 'src/modules/map-geo-jsons/components/CheckBoxFloodRisk.component';
import { CheckBoxPrecipitationLayer } from 'src/modules/map-layers/components/CheckBoxPrecipitationLayer.component';
import { CheckBoxRegionGeoJSON } from 'src/modules/map-layers/components/CheckBoxRegionGeoJSON.component';
import { CheckBoxRiverNetworkComponent } from 'src/modules/map-layers/components/CheckBoxRiverNetwork.component';
import { SelectBoxBaseLayer } from 'src/modules/map-layers/components/SelectBoxBaseLayer.component';
import { CheckBoxWaterLevelMarkers } from 'src/modules/map-markers/components/CheckBoxWaterLevel.component';
import { MapWeatherInfo } from 'src/modules/map-weather-info/components/MapWeatherInfo.component';
import { setControlBottomClassName } from 'src/redux/slices/precipitationPlaying.slice';
import { twMerge } from 'tailwind-merge';
import {useAuth} from "../../../lib/auth/use-auth";

export function TileLayerControlTopRight({
  baseTileLayer,
  map,
}: {
  baseTileLayer: React.RefObject<TileLayerEntity>;
  map: React.RefObject<Map>;
}) {
    const {user} = useAuth();
    const [usr, setUsr] = useState(user?.user);
  const id = useRef(uniqueId('tile-layer-control-top-right'));
  const [selectedAreaName, setSelectedAreaName] = useState<string>();
  const navigate = useNavigate();
  const dispatch = useDispatch();


  const isViewable = () => {
      const userAreas = usr?.USER_AREAS?.L;
      let areas = [];
      if (userAreas) {
          areas = userAreas.map((area:any) => {
              return area.S;
          })
      }
      return usr?.USER_ROLE?.S === 'admin' || areas.includes("香川用水")
  }

  return (
    <Control
      position="topright"
      container={{
        id: id.current,
        className: 'h-screen bg-white w-60 transition-all',
      }}>
      <div className="flex h-screen w-full flex-col gap-1 overflow-y-auto p-3 md:gap-4">
        <SelectBoxBaseLayer baseTileLayer={baseTileLayer} />

        <CheckBoxPrecipitationLayer baseTileLayer={baseTileLayer} />
        {/* <CheckBoxElevationLayer baseTileLayer={baseTileLayer} /> */}
        <CheckBoxRiverNetworkComponent baseTileLayer={baseTileLayer} />

        <CheckBoxRegionGeoJSON baseTileLayer={baseTileLayer} baseMap={map} />

        <div className="flex flex-wrap gap-3 border border-dashed border-gray-500 p-2 md:flex-col md:flex-nowrap md:p-4">
          <CheckBoxWaterLevelMarkers baseTileLayer={baseTileLayer} />
          {/* TODO: turn off b/c request customer */}
          {/* <CheckBoxFloodRiskComponent baseTileLayer={baseTileLayer} /> */}
        </div>

        <MapWeatherInfo
          onSelectArea={(area) => {
            setSelectedAreaName(area?.AREA_NAME.S);
          }}
        />

        {selectedAreaName && (
          <Link
            className="mb-2 mt-auto border-2 border-blue-500 p-1 text-center text-xl text-blue-500"
            to={`/flow?areaName=${selectedAreaName}`}>
            概要図表示
          </Link>
        )}
      {
          isViewable() && (
              <Link
                  className="mb-2 border-2 border-blue-500 p-1 text-center text-xl text-blue-500"
                  to={`/graph`}>
                  水先到達予測グラフ
              </Link>
          )
      }


        {/* logout */}
        <div
          onClick={() => {
            navigate('/logout');
          }}>
          <a href="#" className="flex items-center justify-center text-sm font-normal">
            <span className="ml-3 whitespace-nowrap md:inline-block">ログアウト</span>
          </a>
        </div>
      </div>

      <ButtonEnableControlTopRight
        onChange={(e) => {
          const eControlTopRight = document.getElementById(id.current);
          eControlTopRight &&
            (eControlTopRight.className = twMerge(eControlTopRight.className, e.target.checked ? 'w-60' : 'w-0'));

          dispatch(setControlBottomClassName(e.target.checked ? 'w-[calc(100vw-15rem)]' : 'w-[calc(100vw-0rem)]'));
        }}
      />
    </Control>
  );
}

export function ButtonEnableControlTopRight({
  onChange,
}: {
  onChange?: (e: React.ChangeEvent<HTMLInputElement>) => void;
}) {
  const [enable, setEnable] = useState<boolean>(true);

  return (
    <Checkbox
      containerProps={{
        className: `absolute top-0 text-center ${enable ? 'right-[15rem]' : 'right-[0.25rem]'}`,
      }}
      labelProps={{
        children: (
          <div className="m-1.5 cursor-pointer rounded-md border border-blue-900 p-1 shadow transition-all duration-500 hover:scale-110">
            {enable ? (
              <svg
                className="h-6 w-6 text-black mix-blend-multiply"
                viewBox="0 0 24 24"
                strokeWidth="2"
                stroke="currentColor"
                fill="none"
                strokeLinecap="round"
                strokeLinejoin="round">
                <path stroke="none" d="M0 0h24v24H0z" /> <polyline points="7 7 12 12 7 17" />
                <polyline points="13 7 18 12 13 17" />
              </svg>
            ) : (
              <svg
                className="h-6 w-6 text-black mix-blend-multiply"
                viewBox="0 0 24 24"
                strokeWidth="2"
                stroke="currentColor"
                fill="none"
                strokeLinecap="round"
                strokeLinejoin="round">
                <path stroke="none" d="M0 0h24v24H0z" /> <polyline points="11 7 6 12 11 17" />
                <polyline points="17 7 12 12 17 17" />
              </svg>
            )}
          </div>
        ),
      }}
      defaultChecked={enable}
      className="hidden"
      onChange={(e) => {
        onChange?.(e);
        setEnable(e.target.checked);
      }}
    />
  );
}
