import { useRef } from 'react';
import { useNavigate } from 'react-router-dom';
import { Button } from 'src/lib/components/Button.component';
import { TextInput } from 'src/lib/components/TextInput.component';
import { toasting } from 'src/lib/components/Toast.component';
import { fpost } from 'src/lib/fetch';
import locale from 'src/locales/ja.json';
import { LoginReq } from '../dto/login/login.req';
import { LoginRes } from '../dto/login/login.res';

export function LoginPage() {
  const eForm = useRef<HTMLFormElement>(null);

  const eTextEmail = useRef<HTMLInputElement>(null);
  const eTextPassword = useRef<HTMLInputElement>(null);

  const eButtonSubmit = useRef<HTMLButtonElement>(null);

  const navigate = useNavigate();

  const handleButtonSubmit = async (e: React.MouseEvent<HTMLButtonElement, MouseEvent>) => {
    e.preventDefault();
    if (!eForm.current?.checkValidity()) return eForm.current?.reportValidity();

    try {
      const res = await fpost<LoginReq, LoginRes>('api/auth/login', {
        body: {
          email: eTextEmail.current?.value || '',
          password: eTextPassword.current?.value || '',
          role: 'admin',
        },
      });

      const token = res?.[1]?.token;

      if (token) {
        localStorage.setItem('user', JSON.stringify(res[1]));
        navigate('..');
      }
    } catch (e) {
      console.error('Error logging in:', e);
      toasting({ children: '認証に失敗しました', containerProps: { className: 'border-red-600' } });
    }
  };

  return (
    <form className="mt-24 flex flex-col items-center justify-center px-16 py-10" ref={eForm}>
      <h1 className="basis-full text-center text-3xl">ログイン</h1>

      <TextInput
        labelProps={{
          children: 'メールアドレス',
        }}
        type="email"
        containerProps={{
          className: 'mt-16 basis-full',
          style: {
            minWidth: '24rem',
            maxWidth: '48rem',
          },
        }}
        required
        ref={eTextEmail}
      />

      <TextInput
        labelProps={{
          children: 'パスワード',
        }}
        type="password"
        containerProps={{
          className: 'mt-4 basis-full',
          style: {
            minWidth: '24rem',
            maxWidth: '48rem',
          },
        }}
        required
        ref={eTextPassword}
      />

      <Button
        className="mx-auto mt-6 rounded-md bg-black text-xs text-white"
        style={{
          minWidth: '256px',
        }}
        type="submit"
        onClick={handleButtonSubmit}
        ref={eButtonSubmit}>
        ログイン
      </Button>

      <div>
        <a
          href="/login"
          className="mt-5 flex items-center justify-center text-sm font-normal text-gray-900 dark:text-white md:justify-start md:rounded-md md:p-2 md:hover:bg-gray-100 md:dark:hover:bg-slate-500">
          <span className="ml-3 whitespace-nowrap md:inline-block">{locale['login-with-user']['ja']}</span>
        </a>
      </div>
    </form>
  );
}
