import { createAsyncThunk, createSlice, PayloadAction } from '@reduxjs/toolkit';
import { fget } from 'src/lib/fetch';
import { DeviceEntity } from './../../modules/dashboard/dto/device/device.entity';
import { ResponseListDocuments } from './../../modules/dashboard/dto/response';

export const thunkDeviceFlowGetDevices = createAsyncThunk('devices/get', (payload, thunk) => {
  return fget<any, (typeof initialState)['data']>('api/devices', {}).then((data) => data[1]);
});

const initialState: {
  data?: ResponseListDocuments<DeviceEntity>;
  editMode?: string;
  startLining?: string;
} = {};

export const devicesSlice = createSlice({
  name: 'devicesSlice',
  initialState,
  reducers: {
    deviceFlowSetEditMode: (state, action: PayloadAction<string>) => {
      state.editMode = action.payload;
    },
    deviceFlowSetLining: (state, action: PayloadAction<string>) => {
      state.startLining = action.payload;
    },
  },
  extraReducers: (builder) => {
    builder.addCase(thunkDeviceFlowGetDevices.fulfilled, (state, action) => {
      state.data = action.payload;
    });
  },
});

// Action creators are generated for each case reducer function
export const { deviceFlowSetEditMode, deviceFlowSetLining } = devicesSlice.actions;

export const devicesReducer = devicesSlice.reducer;
