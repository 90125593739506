import type { PayloadAction } from '@reduxjs/toolkit';
import { createSlice } from '@reduxjs/toolkit';

const initialState = {
  areaCoordinates: '',
};

export const areaSlice = createSlice({
  name: 'areaSlice',
  initialState,
  reducers: {
    setSelectedArea: (state, action: PayloadAction<Partial<typeof initialState>>) => {
      return {
        ...state,
        ...action.payload,
      };
    },
  },
});

export const { setSelectedArea } = areaSlice.actions;

export const areaReducer = areaSlice.reducer;
