import { TileLayer as TileLayerEntity } from 'leaflet';
import { check } from 'prettier';
import React, { useEffect, useRef, useState } from 'react';
import { TileLayer } from 'react-leaflet';
import { useDispatch, useSelector } from 'react-redux';
import { Select } from 'src/lib/components/Select.component';
import { toasting } from 'src/lib/components/Toast.component';
import {
  JmaTargetTime,
  setTimeMode,
  setUrls,
  urlTargetTimes,
  urlTargetTimesFuture,
  urlTargetTimesPast,
} from 'src/redux/slices/precipitation.slice';
import { initPrecipitationPlaying, setIsEnableControlBottom } from 'src/redux/slices/precipitationPlaying.slice';
import { RootState } from 'src/redux/store';

export const CheckBoxPrecipitationLayer = React.forwardRef(
  (
    {
      label = '降水量',
      baseTileLayer,
      checkBoxHrpnsNdGeoJSON,
      checked,
    }: {
      label?: string;
      baseTileLayer: React.RefObject<TileLayerEntity>;
      checkBoxHrpnsNdGeoJSON?: React.RefObject<TileLayerEntity>;
      checked?: boolean;
    },
    ref: React.Ref<{ setUrl: (url: string, hrpnsNdGeoJSONUrl: string) => void }>,
  ) => {
    // Handle checkbox
    const eLabel = useRef<HTMLLabelElement>(null);
    const eInputLabel = useRef<HTMLDivElement>(null);

    const [inputChecked, setInputChecked] = useState<boolean>(!!checked);
    const dispatch = useDispatch();

    const timeMode = useSelector((state: RootState) => state.precipitationReducer.mode);

    const changeCheckingStatus = async (check: boolean) => {
      if (check) {
        eInputLabel.current?.classList.add('text-white', 'bg-blue-500');
        eInputLabel.current?.classList.remove('text-blue-500');
      } else {
        eInputLabel.current?.classList.add('text-blue-500');
        eInputLabel.current?.classList.remove('text-white', 'bg-blue-500');
      }

      const fetchData = async (url: string) => {
        try {
          const res = await fetch(url);
          const data = await res.json();
          return data as JmaTargetTime[];
        } catch (e) {
          console.log('fetch error:', e);
          toasting({ children: 'データの取得に失敗しました。', containerProps: { className: 'border-red-600' } });
        }
      };

      if (timeMode === '5m') {
        const [pastTimes, futureTimes] = await Promise.all([
          fetchData(urlTargetTimesPast),
          fetchData(urlTargetTimesFuture),
        ]);

        if (pastTimes && futureTimes) {
          dispatch(
            setUrls({
              pastTimes: pastTimes.reverse(),
              futureTimes: futureTimes.reverse(),
            }),
          );
          dispatch(
            initPrecipitationPlaying({
              pastTimesLength: pastTimes.length,
              futureTimesLength: futureTimes.length,
              timeline: pastTimes.concat(futureTimes),
            }),
          );
        }
      } else if (timeMode === '30m') {
        const timeline = await fetchData(urlTargetTimes);

        if (timeline) {
          timeline.reverse();
          dispatch(
            setUrls({
              timeline,
            }),
          );
          dispatch(
            initPrecipitationPlaying({
              futureTimesLength:
                timeline.length -
                timeline.findIndex((time) => time.basetime === time.validtime && time.member === 'immed') -
                1,
              pastTimesLength:
                timeline.findIndex((time) => time.basetime === time.validtime && time.member === 'immed') + 1,
              timeline,
            }),
          );
        }
      }

      dispatch(setIsEnableControlBottom(check));
    };

    useEffect(() => {
      changeCheckingStatus(inputChecked);
    }, [inputChecked]);

    // Handle layer
    const eLayer = useRef<TileLayerEntity>(null);
    const currentUrls = useSelector((state: RootState) => state.precipitationReducer.currentUrls);

    const setUrl = (tileUrl: string, hrpnsNdUrl: string) => {
      window.tileRainFallLoaded = false;
      tileUrl && eLayer.current?.setUrl(tileUrl);
      hrpnsNdUrl && checkBoxHrpnsNdGeoJSON?.current?.setUrl(hrpnsNdUrl);
    };

    useEffect(() => {
      setUrl(currentUrls.tile, currentUrls.hrpnsNd);
    }, [inputChecked, currentUrls]);

    useEffect(() => {
      if (inputChecked) {
        changeCheckingStatus(false);
        setTimeout(() => {
          changeCheckingStatus(true);
        }, 555);
      }
    }, [timeMode]);

    return (
      <>
        <div className="flex items-center justify-center gap-2">
          <div className="flex flex-1 items-center" ref={eInputLabel}>
            <input
              id={label}
              type="checkbox"
              checked={inputChecked}
              onChange={(e) => {
                setInputChecked(e.target.checked);
              }}
              className="hidden h-4 w-4 rounded border-gray-300 bg-gray-100 text-blue-600 focus:ring-2 focus:ring-blue-500 dark:border-gray-600 dark:bg-gray-700 dark:ring-offset-gray-800 dark:focus:ring-blue-600"
            />
            <label
              htmlFor={label}
              className="flex-1 select-none border-2 border-blue-500 p-2 text-center text-sm font-medium text-inherit md:p-3"
              ref={eLabel}>
              {label}
            </label>
          </div>
        </div>

        {inputChecked && (
          <TileLayer
            url=""
            ref={eLayer}
            zIndex={500}
            opacity={0.8}
            maxNativeZoom={10}
            keepBuffer={100_000_000}
            eventHandlers={{
              add: () => {
                baseTileLayer.current?.getContainer()?.style?.setProperty('mix-blend-mode', 'multiply');
                baseTileLayer.current?.getContainer()?.style?.setProperty('z-index', '101');
              },
              load: () => {
                window.tileRainFallLoaded = true;
              },
            }}
          />
        )}
      </>
    );
  },
);
