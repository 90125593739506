import { useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import ShowMoreText from 'react-show-more-text';
import { Button } from 'src/lib/components/Button.component';
import { Table } from 'src/lib/components/Table.component';
import { toasting } from 'src/lib/components/Toast.component';
import { fdelete, fget } from 'src/lib/fetch';
import { AreaEntity } from '../../dto/area/area.entity';
import { DeleteAreaReq } from '../../dto/area/area.req';
import { ResponseListDocuments } from '../../dto/response';

export function AreasPage() {
  const [area, setArea] = useState<ResponseListDocuments<AreaEntity>>();

  const navigate = useNavigate();

  const handleButtonDelete = async (input: DeleteAreaReq) => {
    const id = input.area.AREA_ID;
    try {
      await fdelete<DeleteAreaReq>(`api/areas/${id}`);

      const data = await fget<any, typeof area>('api/areas');

      setArea(data[1]);
    } catch (error) {
      console.error(error);
      toasting({ children: '何か問題が発生しました', containerProps: { className: 'border-red-600' } });
    }
  };

  useEffect(() => {
    const fetchData = async () => {
      try {
        const data = await fget<any, typeof area>('api/areas');

        setArea(data[1]);
      } catch (error) {
        console.error(error);
        toasting({ children: '何か問題が発生しました', containerProps: { className: 'border-red-600' } });
      }
    };

    fetchData();
  }, []);

  return (
    <div className="flex flex-row flex-wrap px-4 pt-12 md:px-12 md:py-8">
      <h1 className="mb-2 whitespace-nowrap text-2xl">地域管理</h1>
      <span className="w-full border-b border-slate-800"></span>

      <div className="flex basis-full">
        <Button
          className="ml-auto mt-4 rounded-md bg-black text-white"
          onClick={(e) => {
            navigate('create');
          }}>
          <svg className="mr-2 h-4 w-4 text-white" fill="none" viewBox="0 0 24 24" stroke="currentColor">
            <path strokeLinecap="round" strokeLinejoin="round" strokeWidth="2" d="M12 4v16m8-8H4" />
          </svg>
          新しい地域を追加
        </Button>
      </div>

      <div className="relative mt-4 block w-full basis-full overflow-x-auto rounded-md border p-4 shadow-md">
        <Table
          hideCheckbox
          headCellData={['ID', '地域名', '代表座標', '説明', '概要図', '']}
          cellData={area?.Items?.map((a) => [
            a.AREA_ID.S,
            a.AREA_NAME.S,
            a.AREA_COORDINATE?.S?.split(',')
              ?.map((c) => {
                const ic = +c;
                if (isNaN(ic)) return 0;
                return Math.round(ic * 10000) / 10000;
              })
              .filter((v) => v)
              .join(', ') || '',
            <span
              onClick={(e) => {
                e.stopPropagation();
              }}>
              <ShowMoreText
                className="min-w-[10rem]"
                anchorClass="underline-offset-2 underline hover:no-underline font-semibold"
                more="もっと見せる"
                less="表示を減らす">
                {a.DETAIL?.S}
              </ShowMoreText>
            </span>,
            <a
              href="#"
              className="whitespace-nowrap font-medium text-blue-500 hover:underline dark:text-blue-500"
              onClick={(e) => {
                e.stopPropagation();

                navigate(`${a.AREA_NAME.S}/flow-chart`);
              }}>
              概要図を編集
            </a>,
            <a
              href="#"
              className="whitespace-nowrap font-medium text-red-500 hover:underline dark:text-blue-500"
              onClick={(e) => {
                e.stopPropagation();

                handleButtonDelete({
                  area: {
                    AREA_ID: a.AREA_ID.S,
                    AREA_NAME: a.AREA_NAME.S,
                  },
                });
              }}>
              削除
            </a>,
          ])}
          rowProps={{
            onClick: (e) => {
              navigate(area?.Items?.at(+(e.currentTarget.id.split('-').at(-1) || 0))?.AREA_ID.S || '');
            },
            className: 'cursor-pointer',
          }}
        />
      </div>
    </div>
  );
}
