import { data } from './FlowRateConfig';

export const dataDouble = {
  labels: ['Jan', 'Feb', 'Mar', 'Apr', 'May', 'Jun', 'Jul', 'Aug', 'Sep', 'Oct', 'Nov', 'Dec'],
  datasets: [
    {
      label: '流量',
      data: [] as string[] | number[] | null[],
      fill: true,
      backgroundColor: 'rgba(75,192,192,0.2)',
      borderColor: '#015CAC',
      yAxisID: 'y',
    },
    {
      label: '水位',
      data: [] as string[] | number[] | null[],
      fill: true,
      backgroundColor: 'rgba(75,192,192,0.2)',
      borderColor: 'red',
      yAxisID: 'y1',
    },
    {
      label: '流速',
      data: [] as string[] | number[] | null[],
      fill: true,
      backgroundColor: 'rgba(75,192,192,0.2)',
      borderColor: 'green',
      yAxisID: 'y2',
    },
  ],
};

export const configDouble = {
  type: 'line',
  data: data,
  options: {
    title: {
      display: false,
      text: '',
    },
    scales: {
      xAxes: [
        {
          scaleLabel: {
            display: true,
            labelString: '年',
          },
          ticks: {
            max: 100,
            min: 0,
          },
        },
      ],
      yAxes: [
        {
          id: 'y',
          scaleLabel: {
            color: '#015CAC',
            fontColor: '#015CAC',
            display: true,
            labelString: '流量 (m3/s)',
          },
          scalePositionLeft: true,
          position: 'left',
          ticks: {
            color: '#015CAC',
            fontColor: '#015CAC',
            max: 10,
            min: 0,
          },
        },
        {
          id: 'y1',
          scaleLabel: {
            display: true,
            labelString: '水位 (m)',
            color: 'red',
            fontColor: 'red',
          },
          color: 'red',
          name: 'Flow',
          type: 'linear',
          position: 'right',
          scalePositionLeft: false,
          ticks: {
            color: 'red',
            fontColor: 'red',
            max: 300,
            min: -200,
          },
        },
        {
          id: 'y2',
          scaleLabel: {
            display: true,
            labelString: '流速 (m/s)',
            color: 'green',
            fontColor: 'green',
          },
          scalePositionLeft: true,
          position: 'right',
          ticks: {
            max: 10,
            min: 0,
            fontColor: 'green',
          },
        },
      ],
    },
    tooltips: {
      mode: 'nearest',
      intersect: false,
    },
    hover: {
      mode: 'nearest',
      intersect: false,
    },
  },
};
