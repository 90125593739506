import dayjs from 'dayjs';
import { useEffect } from 'react';
import { useNavigate, useLocation } from 'react-router-dom';
import { getUser } from '../fetch';

export function useAuth() {
  const navigate = useNavigate();
  const user = getUser();
  const { state: locationState, pathname } = useLocation<string>();

  const isNotExpired = (user?.loginTime || 0) / 1000 + (user?.expiresInSeconds || 0) > dayjs().unix();
  const isAuthenticated = isNotExpired;
  if (!isAuthenticated) {
    localStorage.removeItem('user');
  }

  useEffect(() => {
    navigate(isAuthenticated ? pathname : '/login', { state: { pathname } });
  }, []);

  return {
    isAuthenticated,
    user,
    locationState,
  };
}
