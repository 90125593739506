import { Button } from 'flowbite-react';
import { forwardRef } from 'react';
import { twMerge } from 'tailwind-merge';

export const ResetButton = forwardRef<{ setLoading: (loading: boolean) => void }, { onClick?: () => void }>(
  ({ onClick }, ref) => {
    const handleClick = () => {
      const oke = confirm('グラフをリセットしてもよろしいですか？');
      if (!oke) {
        return;
      }
      if (onClick) {
        onClick();
      }
    };
    return (
      <Button
        className={twMerge(
          'border-r-1 rounded-sm p-0 text-black transition-all ease-in-out hover:bg-slate-500 hover:text-white',
        )}
        title="リセット"
        onClick={handleClick}>
        リセット
      </Button>
    );
  },
);
