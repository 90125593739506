import { LatLng, Map, Marker as MarkerEntity, TileLayer as TileLayerEntity } from 'leaflet';
import { useEffect, useRef, useState } from 'react';
import { MapContainer, TileLayer } from 'react-leaflet';
import Control from 'react-leaflet-custom-control';
import { Link, useNavigate, useParams } from 'react-router-dom';
import { Button } from 'src/lib/components/Button.component';
import { Checkbox } from 'src/lib/components/Checkbox.component';
import { TextArea } from 'src/lib/components/TextArea.component';
import { TextInput } from 'src/lib/components/TextInput.component';
import { toasting } from 'src/lib/components/Toast.component';
import { fdelete, fget, fput } from 'src/lib/fetch';
import { SelectBoxBaseLayer } from 'src/modules/map-layers/components/SelectBoxBaseLayer.component';
import { MarkerMapControl } from 'src/modules/map-markers/components/MarkerMapControl.component';
import { BASE_MAP_URL } from 'src/redux/slices/baseLayer.slice';
import { AreaEntity } from '../../dto/area/area.entity';
import { DeleteAreaReq, UpdateAreaReq } from '../../dto/area/area.req';
import { ResponseDocument, ResponseGetDocument } from '../../dto/response';

export function EditAreaPage() {
  const eForm = useRef<HTMLFormElement>(null);

  const eTextName = useRef<HTMLInputElement>(null);
  const eTextDetail = useRef<HTMLTextAreaElement>(null);
  const eTextCoordinateX = useRef<HTMLInputElement>(null);
  const eTextCoordinateY = useRef<HTMLInputElement>(null);

  const eButtonSubmit = useRef<HTMLButtonElement>(null);
  const eButtonDelete = useRef<HTMLButtonElement>(null);

  const baseTileLayer = useRef<TileLayerEntity>(new TileLayerEntity(''));
  const map = useRef<Map>(null);
  const eMarker = useRef<MarkerEntity>(null);

  const eButtonModeEdit = useRef<HTMLInputElement>(null);

  const [checkedModeEdit, setCheckedModeEdit] = useState<boolean>(false);

  const navigate = useNavigate();

  const { id } = useParams();
  const [area, setArea] = useState<ResponseDocument<AreaEntity>>();

  const handleButtonSubmit = async (e: React.MouseEvent<HTMLButtonElement, MouseEvent>) => {
    e.preventDefault();

    if (!eForm.current?.checkValidity()) {
      return eForm.current?.reportValidity();
    }

    try {
      await fput<UpdateAreaReq>(`api/areas/${id}`, {
        body: {
          area: {
            AREA_ID: area?.AREA_ID.S || '',
            AREA_NAME: eTextName.current?.value || '',
            DETAIL: eTextDetail.current?.value || '',
            AREA_COORDINATE: `${eTextCoordinateX.current?.value || ''},${eTextCoordinateY.current?.value || ''}`,
          },
        },
      });

      navigate('..');
    } catch (error) {
      console.error(error);
      toasting({ children: '何か問題が発生しました', containerProps: { className: 'border-red-600' } });
    }
  };

  const handleButtonDelete = async (e: React.MouseEvent<HTMLButtonElement, MouseEvent>) => {
    e.preventDefault();

    if (!eForm.current?.checkValidity()) {
      return eForm.current?.reportValidity();
    }

    try {
      await fdelete<DeleteAreaReq>(`api/areas/${id}`);

      navigate('..');
    } catch (error) {
      console.error(error);
      toasting({ children: '何か問題が発生しました', containerProps: { className: 'border-red-600' } });
    }
  };

  useEffect(() => {
    const fetchData = async () => {
      try {
        const [, area] = await fget<unknown, ResponseGetDocument<AreaEntity>>(`api/areas/${id}`);

        setArea(area.Item);
      } catch (error) {
        console.error(error);
        toasting({ children: '何か問題が発生しました', containerProps: { className: 'border-red-600' } });
      }
    };

    fetchData();
  }, []);

  return (
    <form className="flex flex-col px-4 pt-12 md:px-12 md:py-8" ref={eForm}>
      <h1 className="mb-2 whitespace-nowrap  text-2xl">地域追加</h1>
      <span className="w-full border-b border-slate-800"></span>

      <Checkbox
        className="hidden"
        onChange={(e) => setCheckedModeEdit(e.target.checked)}
        defaultChecked={checkedModeEdit}
        ref={eButtonModeEdit}
        containerProps={{
          className: `justify-end mt-2 ml-auto border border-gray-500`,
        }}
        labelProps={{
          children: '編集モード',
          className: `select-none p-2 ${checkedModeEdit ? 'bg-black text-white' : ''}`,
        }}
      />

      <div className="mt-2 flex flex-nowrap gap-8">
        <div className="flex w-2/3 flex-row flex-wrap gap-8">
          <TextInput
            labelProps={{
              children: 'ID',
            }}
            type="text"
            containerProps={{
              className: 'basis-full',
              style: {
                minWidth: '24rem',
              },
            }}
            disabled
            readOnly
            required
            defaultValue={area?.AREA_ID.S}
          />

          <TextInput
            labelProps={{
              children: '地域名',
            }}
            type="text"
            containerProps={{
              className: 'basis-full',
              style: {
                minWidth: '24rem',
              },
            }}
            required
            defaultValue={area?.AREA_NAME.S}
            ref={eTextName}
            disabled={true}
          />

          <div className="flex basis-full flex-col">
            <label className="mb-2 text-sm font-normal text-gray-900 dark:text-white">代表座標</label>
            <div className="flex flex-row flex-wrap justify-between gap-4">
              <TextInput
                labelProps={{
                  className: 'mb-0 mr-2',
                  children: 'X',
                }}
                type="text"
                containerProps={{
                  className: 'flex flex-row items-center',
                  style: {
                    minWidth: '12rem',
                  },
                }}
                required
                defaultValue={area?.AREA_COORDINATE?.S?.split(',')[0]}
                onChange={(e) => {
                  if (eTextCoordinateY.current?.value)
                    eMarker.current?.setLatLng(new LatLng(+e.target.value, +eTextCoordinateY.current.value));
                }}
                ref={eTextCoordinateX}
                disabled={!checkedModeEdit}
              />

              <TextInput
                labelProps={{
                  className: 'mb-0 mr-2',
                  children: 'Y',
                }}
                type="text"
                containerProps={{
                  className: 'flex flex-row items-center',
                  style: {
                    minWidth: '12rem',
                  },
                }}
                required
                defaultValue={area?.AREA_COORDINATE?.S?.split(',')[1]}
                onChange={(e) => {
                  if (eTextCoordinateX.current?.value)
                    eMarker.current?.setLatLng(new LatLng(+e.target.value, +eTextCoordinateX.current.value));
                }}
                ref={eTextCoordinateY}
                disabled={!checkedModeEdit}
              />
            </div>
          </div>

          <TextArea
            labelProps={{
              value: '説明',
            }}
            containerProps={{
              className: 'basis-full',
              style: {
                minWidth: '24rem',
              },
            }}
            className="custom-scrollbar-1 rounded-t-md"
            ref={eTextDetail}
            defaultValue={area?.DETAIL?.S}
            disabled={!checkedModeEdit}></TextArea>

          {checkedModeEdit && (
            <>
              <Button
                className="basis-full justify-center rounded-md bg-black text-xs text-white"
                style={{
                  minWidth: '256px',
                }}
                type="submit"
                onClick={handleButtonSubmit}
                ref={eButtonSubmit}>
                変更
              </Button>
              <Button
                className="basis-full justify-center rounded-md bg-transparent text-xs text-orange-600"
                style={{
                  minWidth: '256px',
                }}
                type="submit"
                onClick={handleButtonDelete}
                ref={eButtonDelete}>
                削除
              </Button>
            </>
          )}
          <Link
            to=".."
            className="mx-auto basis-full  rounded-md border border-gray-600 bg-transparent p-2.5 text-center text-xs"
            style={{
              minWidth: '256px',
            }}>
            {eButtonModeEdit.current?.checked ? 'キャンセル' : '戻る'}
          </Link>
        </div>

        <MapContainer
          attributionControl={false}
          minZoom={5}
          zoom={9}
          center={[33.9195, 133.1811]}
          style={{
            height: '100%',
            minHeight: '60vh',
            width: '20vw',
            border: '0.25rem solid',
            flexBasis: '100%',
          }}
          ref={map}>
          <TileLayer url={decodeURI(BASE_MAP_URL.std.href)} ref={baseTileLayer} maxNativeZoom={18} />
          <Control
            position="topright"
            container={{
              className: 'flex bg-none flex-col',
            }}>
            <SelectBoxBaseLayer baseTileLayer={baseTileLayer} />
          </Control>

          {area?.AREA_COORDINATE && (
            <MarkerMapControl
              ref={eMarker}
              position={
                area.AREA_COORDINATE.S
                  ? new LatLng(+area?.AREA_COORDINATE.S.split(',')[0], +area?.AREA_COORDINATE.S.split(',')[1])
                  : undefined
              }
              onClickMap={(e) => {
                eButtonModeEdit.current?.checked &&
                  eTextCoordinateX.current &&
                  eTextCoordinateY.current &&
                  (eTextCoordinateX.current.value = `${e.latlng.lat}`) &&
                  (eTextCoordinateY.current.value = `${e.latlng.lng}`);

                return !!eButtonModeEdit.current?.checked;
              }}
            />
          )}
        </MapContainer>
      </div>
    </form>
  );
}
