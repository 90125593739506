import type { PayloadAction } from '@reduxjs/toolkit';
import { createSlice } from '@reduxjs/toolkit';
import { dayts } from 'src/lib/dayjs';
//https://www.jma.go.jp/bosai/nowc/#lat:35.451721/lon:138.592529/zoom:6/colordepth:deep/elements:hrpns&liden&amds_rain10m

//tiles url
export function url5m({ basetime, validtime, member }: JmaTargetTime) {
  return `https://www.jma.go.jp/bosai/jmatile/data/nowc/${basetime}/${
    member || 'none'
  }/${validtime}/surf/hrpns/{z}/{x}/{y}.png`;
}
//surrounded area url
export function url5mHrpnsNd({ basetime, validtime, member }: JmaTargetTime) {
  return `https://www.jma.go.jp/bosai/jmatile/data/nowc/${basetime}/${
    member || 'none'
  }/${validtime}/surf/hrpns_nd/data.geojson?id=hrpns_nd`;
}

//tiles url
export function url30m({ basetime, validtime, member }: JmaTargetTime) {
  return `https://www.jma.go.jp/bosai/jmatile/data/rasrf/${basetime}/${
    member || 'none'
  }/${validtime}/surf/rasrf/{z}/{x}/{y}.png`;
}
export function url30mHrpnsNd({ basetime, validtime, member }: JmaTargetTime) {
  return `https://www.jma.go.jp/bosai/jmatile/data/rasrf/${basetime}/${
    member || 'none'
  }/${validtime}/surf/rasrf_nd/data.geojson?id=rasrf_nd`;
}

//timeline created by jma
export const urlTargetTimesPast = 'https://www.jma.go.jp/bosai/jmatile/data/nowc/targetTimes_N1.json';
export const urlTargetTimesFuture = 'https://www.jma.go.jp/bosai/jmatile/data/nowc/targetTimes_N2.json';
export const urlTargetTimes = 'https://www.jma.go.jp/bosai/jmatile/data/rasrf/targetTimes.json';

export interface JmaTargetTime {
  basetime: string;
  validtime: string;
  member: string;
}

const initialState = {
  mode: '5m',

  urlTile: [] as string[],
  urlHrpnsNd: [] as string[],

  currentUrls: {
    tile: '',
    hrpnsNd: '',
  },
};

export const precipitationSlice = createSlice({
  name: 'precipitationSlice',
  initialState,
  reducers: {
    setUrls: (
      state,
      action: PayloadAction<{ pastTimes?: JmaTargetTime[]; futureTimes?: JmaTargetTime[]; timeline?: JmaTargetTime[] }>,
    ) => {
      // Redux Toolkit allows us to write "mutating" logic in reducers. It
      // doesn't actually mutate the state because it uses the Immer library,
      // which detects changes to a "draft state" and produces a brand new
      // immutable state based off those changes

      if (action.payload.futureTimes && action.payload.pastTimes) {
        const timeline = action.payload.pastTimes.concat(action.payload.futureTimes);
        state.urlTile = timeline.map((time) => url5m(time));
        state.urlHrpnsNd = timeline.map((time) => url5mHrpnsNd(time));
      } else if (action.payload.timeline) {
        state.urlTile = action.payload.timeline.map((time) => url30m(time));
        state.urlHrpnsNd = action.payload.timeline.map((time) => url30mHrpnsNd(time));
      }
    },
    setCurrentUrls: (state, action: PayloadAction<Partial<(typeof initialState)['currentUrls']>>) => {
      state.currentUrls = { ...state.currentUrls, ...action.payload };
    },
    setTimeMode: (state, action: PayloadAction<string>) => {
      state.mode = action.payload;
    },
  },
});

// Action creators are generated for each case reducer function
export const { setUrls, setCurrentUrls, setTimeMode } = precipitationSlice.actions;

export const precipitationReducer = precipitationSlice.reducer;
