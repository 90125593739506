import { TileLayer as TileLayerEntity } from 'leaflet';
import { useEffect, useRef, useState } from 'react';
import { TileLayer } from 'react-leaflet';
import { useDispatch } from 'react-redux';
import { Select } from 'src/lib/components/Select.component';
import { BASE_MAP_URL, setBaseLayerUrl } from 'src/redux/slices/baseLayer.slice';

export function SelectBoxBaseLayer({
  baseTileLayer,
}: {
  label?: string;
  baseTileLayer: React.RefObject<TileLayerEntity>;
}) {
  const eSelect = useRef<HTMLSelectElement>(null);

  const dispatch = useDispatch();

  const onSelectBaseLayer = (url: string, elevationChecked: boolean) => {
    const isGray = !!new URL(url).searchParams.get('gray');
    const container = baseTileLayer.current?.getContainer();

    if (container) {
      container.style.setProperty('filter', isGray ? 'grayscale(1)' : 'grayscale(0)');
    }

    if (elevationChecked) {
      baseTileLayer.current?.setUrl(decodeURI(BASE_MAP_URL.std.href));
    } else {
      baseTileLayer.current?.setUrl(url);
    }
  };

  useEffect(() => {
    onSelectBaseLayer(decodeURI(BASE_MAP_URL.pale.href), false);
  }, []);

  const [elevationChecked, setElevationChecked] = useState<boolean>(false);
  const onChange = (e: React.ChangeEvent<HTMLSelectElement>) => {
    const elevationChecked = e.target.value === decodeURI(BASE_MAP_URL.relief.href);
    setElevationChecked(elevationChecked);

    onSelectBaseLayer(e.target.value, elevationChecked);
    dispatch(setBaseLayerUrl(e.target.value));
  };

  return (
    <>
      <Select
        className="rounded-md border-dashed border-gray-600 bg-transparent py-2 md:py-3"
        ref={eSelect}
        onChange={onChange}>
        <option value={decodeURI(BASE_MAP_URL.pale.href)}>淡色地図</option>
        <option value={decodeURI(BASE_MAP_URL.std.href)}>立面図</option>
        <option value={decodeURI(BASE_MAP_URL.photo.href)}>イメージマップ</option>
        <option value={decodeURI(BASE_MAP_URL.relief.href)}>標高</option>
      </Select>

      {elevationChecked && (
        <TileLayer
          url={decodeURI(BASE_MAP_URL.relief.href)}
          maxNativeZoom={15}
          eventHandlers={{
            add: () => {
              baseTileLayer.current?.getContainer()?.style?.setProperty('mix-blend-mode', 'multiply');
              baseTileLayer.current?.getContainer()?.style?.setProperty('z-index', '101');
            },
          }}
        />
      )}
    </>
  );
}
