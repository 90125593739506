import { useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { Button } from 'src/lib/components/Button.component';
import { Table } from 'src/lib/components/Table.component';
import { toasting } from 'src/lib/components/Toast.component';
import { fdelete, fget } from 'src/lib/fetch';
import { ResponseListDocuments } from '../../dto/response';
import { UserEntity } from '../../dto/user/user.entity';
import { DeleteUserReq } from '../../dto/user/user.req';

export function AdminUsersPage() {
  const [users, setUsers] = useState<ResponseListDocuments<UserEntity>>();

  const navigate = useNavigate();

  const handleButtonDelete = async (input: DeleteUserReq) => {
    const id = input.user.USER_ID;
    try {
      await fdelete<DeleteUserReq>(`api/users/${id}`, {
        body: input,
      });

      const data = await fget<any, typeof users>('api/users?type=admin');

      setUsers(data[1]);
    } catch (error) {
      console.error(error);
      toasting({ children: '何か問題が発生しました', containerProps: { className: 'border-red-600' } });
    }
  };

  useEffect(() => {
    const fetchData = async () => {
      try {
        const data = await fget<any, typeof users>('api/users?type=admin');

        setUsers(data[1]);
      } catch (error) {
        console.error(error);
        toasting({ children: '何か問題が発生しました', containerProps: { className: 'border-red-600' } });
      }
    };

    fetchData();
  }, []);

  return (
    <div className="flex flex-row flex-wrap px-4 pt-12 md:px-12 md:py-8">
      <h1 className="mb-2 whitespace-nowrap  text-2xl">管理アカウント設定</h1>
      <span className="w-full border-b border-slate-800"></span>

      <div className="flex basis-full">
        <Button
          className="ml-auto mt-4 rounded-md bg-black text-white"
          onClick={(e) => {
            navigate('create');
          }}>
          <svg className="mr-2 h-4 w-4 text-white" fill="none" viewBox="0 0 24 24" stroke="currentColor">
            <path strokeLinecap="round" strokeLinejoin="round" strokeWidth="2" d="M12 4v16m8-8H4" />
          </svg>
          新しいアカウントを追加
        </Button>
      </div>

      <div className="relative mt-4 block w-full basis-full overflow-x-auto rounded-md border p-4 shadow-md">
        <Table
          hideCheckbox
          headCellData={['ID', 'ユーザー名', 'メールアドレス', '']}
          cellData={users?.Items?.map((user) => [
            user.USER_ID.S,
            user.USER_NAME.S,
            <a href="#" className="text-blue-500 hover:underline">
              {user.USER_MAIL.S}
            </a>,
            <a
              href="#"
              className="whitespace-nowrap font-medium text-red-500 hover:underline dark:text-blue-500"
              onClick={(e) => {
                e.stopPropagation();

                handleButtonDelete({
                  user: {
                    USER_ID: user.USER_ID.S,
                    USER_MAIL: user.USER_MAIL.S,
                  },
                });
              }}>
              削除
            </a>,
          ])}
          rowProps={{
            onClick: (e) => {
              navigate(users?.Items?.at(+(e.currentTarget.id.split('-').at(-1) || 0))?.USER_ID.S || '');
            },
            className: 'cursor-pointer',
          }}
        />
      </div>
    </div>
  );
}
