import chunk from 'lodash.chunk';
import { useEffect, useRef, useState } from 'react';
import { environment } from 'src/environments/environment';
import { RadioGroup } from 'src/lib/components/RadioGroup.component';
import { Select } from 'src/lib/components/Select.component';
import { Table } from 'src/lib/components/Table.component';
import { toasting } from 'src/lib/components/Toast.component';
import { dayts } from 'src/lib/dayjs';
import { fget } from 'src/lib/fetch';
import { AreaEntity } from '../../dto/area/area.entity';
import { ResponseListDocuments } from '../../dto/response';
import { ReverseGeoCodingResponse } from '../../dto/weather/reverse-geocoding.res';
import { WeatherHourly, WeatherResponse } from '../../dto/weather/weather.res';

export function proceedOpenMeteoData(w: WeatherResponse) {
  const daysMap = new Map();
  const hoursMap = new Map();

  for (let i = w.hourly?.time.length - 1; i >= 0; i--) {
    const time = dayts(w.hourly?.time[i] * 1000).tz(w.timezone_abbreviation);

    daysMap.set(`${time.month() + 1}-${time.date()}`, time.locale('ja').format('dddd').split('')[0]);

    hoursMap.set(`${time.date()}-${time.hour()}-${time.hour() + 1}`, {
      ...Object.keys(w.hourly).reduce((result, key) => {
        result[key] = (w.hourly as any)[key][i];
        return result;
      }, {} as any),
      time,
    });
  }

  return {
    ...w,
    hourly: {
      monthAndDay: Array.from(daysMap.keys()).reverse(),
      daysInWeek: Array.from(daysMap.values()).reverse(),
      hourToHour: Array.from(new Set(Array.from(hoursMap.keys()).map((k) => k.slice(1, 2)))).reverse(),
      hoursData: chunk(Array.from(hoursMap.values()).reverse(), 24),
    },
  };
}

function getDateDayInWeek(w?: WeatherResponse) {
  return (
    w?.daily?.time.map((t) => {
      const time = dayts(t * 1000).tz(w.timezone_abbreviation);

      return `${time.date()} (${time.locale('ja').format('dddd').split('')[0]})`;
    }) || []
  );
}

function getMonthDateDayInWeek(w?: WeatherResponse) {
  return (
    w?.daily?.time.map((t) => {
      const time = dayts(t * 1000).tz(w.timezone_abbreviation);

      return `${time.month() + 1}月${time.date()}日 (${time.locale('ja').format('dddd').split('')[0]})`;
    }) || []
  );
}

export function getMonthDateDayInWeekCurrent(w?: Pick<WeatherResponse, 'current_weather' | 'timezone_abbreviation'>) {
  if (!w) return '';

  const time = dayts(w.current_weather.time * 1000).tz(w.timezone_abbreviation);

  return `${time.month() + 1}月${time.date()}日 (${time.locale('ja').format('dddd').split('')[0]})`;
}

export function getWeatherCodeObject(weathercode: number) {
  const result = { fileName: 'weather-sprite.svg', backgroundUrl: '', explain: { ja: '' } };

  switch (weathercode) {
    case 0: //Clearest
      result.fileName = 'day.svg';
      result.explain.ja = '最も澄んだ空';
      break;
    case 1: //Clear
      result.fileName = 'day.svg';
      result.explain.ja = '青空';
      break;
    case 2: //Cloudy
      result.fileName = 'cloudy.svg';
      result.explain.ja = '曇りです';
      break;
    case 3: //Overcast
      result.fileName = 'overcast-day.svg';
      result.explain.ja = '暗い空';
      break;
    case 45: //Fog
      result.fileName = 'fog.svg';
      result.explain.ja = '霧';
      break;
    case 48: //Depositing rime fog
      result.fileName = 'overcast-fog.svg';
      result.explain.ja = 'ライム';
      break;
    case 51: //Drizzle light
      result.fileName = 'drizzle.svg';
      result.explain.ja = '小雨';
      break;
    case 53: //Drizzle moderate
      result.fileName = 'overcast-drizzle.svg';
      result.explain.ja = '霧雨適度';
      break;
    case 55: //Drizzle dense
      result.fileName = 'extreme-drizzle.svg';
      result.explain.ja = '霧雨';
      break;
    case 56: //Freezing drizzle light
      result.fileName = 'overcast-drizzle.svg';
      result.explain.ja = '軽い霧雨と寒さ';
      break;
    case 57: //Freezing drizzle dense
      result.fileName = 'overcast-drizzle.svg';
      result.explain.ja = '霧雨と寒さ';
      break;
    case 61: //Rain slight
      result.fileName = 'rainy-3.svg';
      result.explain.ja = '小雨';
      break;
    case 63: //Rain moderate
      result.fileName = 'rainy-4.svg';
      result.explain.ja = '適度な雨';
      break;
    case 65: //Rain heavy
      result.fileName = 'rainy-5.svg';
      result.explain.ja = '大雨';
      break;
    case 66: //Freezing rain light
      result.fileName = 'rainy-6.svg';
      result.explain.ja = '小雨と寒さ';
      break;
    case 67: //Freezing rain heavy
      result.fileName = 'rainy-7.svg';
      result.explain.ja = '大雨と寒さ';
      break;
    case 71: //Snow fall slight
      result.fileName = 'snow.svg';
      result.explain.ja = '小雪が降る';
      break;
    case 73: //Snow moderate
      result.fileName = 'snowy-4.svg';
      result.explain.ja = '中程度の降雪';
      break;
    case 75: //Snow fall heavy
      result.fileName = 'snowy-5.svg';
      result.explain.ja = '大雪';
      break;
    case 77: //Snow grains
      result.fileName = 'snowflake.svg';
      result.explain.ja = '雪の粒';
      break;
    case 80: //Rain showers slight
      result.fileName = 'rain.svg';
      result.explain.ja = 'にわか雨';
      break;
    case 81: //Rain showers moderate
      result.fileName = 'rain-7.svg';
      result.explain.ja = '適度なにわか雨';
      break;
    case 82: //Rain showers violent
      result.fileName = 'extreme-rain.svg';
      result.explain.ja = '激しい雨';
      break;
    case 85: //Snow showers slight
      result.fileName = 'overcast-snow.svg';
      result.explain.ja = '小雪雨';
      break;
    case 86: //Snow showers heavy
      result.fileName = 'extreme-snow.svg';
      result.explain.ja = '豪雪';
      break;

    default:
      break;
  }

  result.backgroundUrl = `url('${environment.asset}assets/weather/${result.fileName}'), url('http://${window.location.host}/assets/weather/${result.fileName}')`;

  return result;
}

function getWeatherCodeStastitics(weathercodes: number[]) {
  const weathercodesMap: { [key: number]: number } = {};

  for (let i = weathercodes.length - 1; i >= 0; i--) {
    weathercodesMap[weathercodes[i]] = (weathercodesMap[weathercodes[i]] || 0) + 1;
  }

  const keysOfWeathercodesMap = Object.keys(weathercodesMap);
  const valuesOfWeathercodesMap = Object.values(weathercodesMap);

  const maxValueWeathercodesMap = Math.max(...valuesOfWeathercodesMap);
  const maxValueWeathercodesMap2nd = Math.max(...valuesOfWeathercodesMap.filter((v) => v !== maxValueWeathercodesMap));

  return {
    mostAppearedWeathercodes: keysOfWeathercodesMap.filter((key) => weathercodesMap[+key] === maxValueWeathercodesMap),
    ...(maxValueWeathercodesMap !== maxValueWeathercodesMap2nd
      ? {
          mostAppeared2ndWeathercodes: keysOfWeathercodesMap.filter(
            (key) => weathercodesMap[+key] === maxValueWeathercodesMap2nd,
          ),
        }
      : {}),
  };
}

function getHourlyTimeRange(w?: WeatherResponse) {
  return (
    w?.hourly?.time.slice(0, 24).map((t) => {
      const time = dayts(t * 1000).tz(w.timezone_abbreviation);

      return `${String(time.hour()).padStart(2, '0')}:00 - ${String(time.hour() + 1).padStart(2, '0')}:00`;
    }) || []
  );
}

function getHourlyChunkData(w?: WeatherResponse, chunkSize = 24) {
  return Object.keys(w?.hourly || []).reduce((result, key) => {
    const k = key as keyof WeatherHourly;

    result[k] = chunk(w?.hourly[k] || [], chunkSize);

    return result;
  }, {} as { [key in keyof WeatherHourly]: number[][] });
}

export function WeatherPage() {
  const eForm = useRef<HTMLFormElement>(null);

  const eSelectArea = useRef<HTMLSelectElement>(null);
  const eRadioGroup = useRef<HTMLInputElement[]>([]);

  const [data, setData] = useState<{
    weather?: WeatherResponse;
    areas?: ResponseListDocuments<AreaEntity>;
    chunkIndex?: number;
    meteoUrl?: URL;
    address?: ReverseGeoCodingResponse;
  }>();

  useEffect(() => {
    const fetchAreaData = async () => {
      try {
        const [_, areas] = await fget<unknown, ResponseListDocuments<AreaEntity>>('api/areas');

        const [lat, lng] = areas.Items?.[0].AREA_COORDINATE?.S?.split(',') || [33.9195, 133.1811];

        setData((prev) => ({
          ...prev,
          areas,
          meteoUrl: new URL(
            `https://api.open-meteo.com/v1/forecast?latitude=${lat}&longitude=${lng}&hourly=temperature_2m,relativehumidity_2m,apparent_temperature,precipitation,rain,showers,snowfall,snow_depth,weathercode,visibility,windspeed_10m,winddirection_10m,windgusts_10m&daily=weathercode,temperature_2m_max,temperature_2m_min,apparent_temperature_max,apparent_temperature_min,precipitation_sum,rain_sum,showers_sum,snowfall_sum,precipitation_hours,windspeed_10m_max,windgusts_10m_max,winddirection_10m_dominant&current_weather=true&windspeed_unit=ms&timeformat=unixtime&timezone=Asia%2FTokyo`,
          ),
        }));
      } catch (error) {
        toasting({ children: '地域の取得に失敗しました。', containerProps: { className: 'border-red-600' } });
      }
    };

    fetchAreaData();
  }, []);

  useEffect(() => {
    if (data?.meteoUrl) {
      const fetchWeatherData = async () => {
        try {
          const [weatherResponse, addressResponse] = await Promise.all([
            fetch(decodeURIComponent(data.meteoUrl?.href || '')).then((response) =>
              response.json(),
            ) as Promise<WeatherResponse>,

            fetch(
              `https://nominatim.openstreetmap.org/reverse?lat=${data.meteoUrl?.searchParams.get(
                'latitude',
              )}&lon=${data.meteoUrl?.searchParams.get('longitude')}&format=json&accept-language=ja`,
            ).then((response) => response.json()) as Promise<ReverseGeoCodingResponse>,
          ]);

          setData((prev) => ({
            ...prev,
            weather: weatherResponse,
            address: addressResponse,
            chunkIndex: 0,
          }));
        } catch (error) {
          console.error('Error fetching data:', error);
          toasting({ children: '天気情報の取得に失敗しました。', containerProps: { className: 'border-red-600' } });
        }
      };

      fetchWeatherData();
    }
  }, [data?.meteoUrl]);

  return (
    <form className="flex flex-row flex-wrap px-4 pt-12 md:px-12 md:py-8" ref={eForm}>
      <h1 className="mb-2 whitespace-nowrap text-2xl">天気情報</h1>
      <span className="w-full border-b border-slate-800"></span>

      <div className="mt-4 flex w-full flex-col gap-4">
        <Select
          defaultValue={data?.areas?.Items?.[0]?.AREA_COORDINATE?.S}
          labelProps={{ value: '地域', className: 'mb-0' }}
          className="w-96"
          containerProps={{
            className: 'mt-2 basis-full flex flex-row items-center gap-6',
          }}
          onChange={(e) => {
            eRadioGroup.current[0].click();

            setData((prev) => {
              if (prev?.meteoUrl) {
                const [lat, lng] = e.target.value.split(',');

                const meteoUrl = new URL(prev.meteoUrl);

                meteoUrl.searchParams.set('latitude', lat);
                meteoUrl.searchParams.set('longitude', lng);

                return { ...prev, meteoUrl };
              }

              return undefined;
            });
          }}
          required
          ref={eSelectArea}>
          {data?.areas?.Items?.map((region, i) => (
            <option key={`Option-` + i} value={region.AREA_COORDINATE?.S}>
              {region.AREA_NAME.S}
            </option>
          ))}
        </Select>

        <RadioGroup
          labels={getDateDayInWeek(data?.weather)}
          className="hidden"
          checkedLabelProps={{
            className: 'text-white bg-gray-800',
          }}
          labelProps={{
            className:
              'inline-flex border items-center justify-center border border-l-0 border-gray-400 py-1 px-3 text-sm whitespace-nowrap',
          }}
          ulProps={{
            className: 'flex border-l flex-wrap gap-y-2 border-gray-400 w-full',
          }}
          liProps={{
            className: 'w-auto',
          }}
          onChange={(e) => {
            setData((prev) => ({ ...prev, chunkIndex: +e.target.value }));
          }}
          ref={eRadioGroup}
        />

        <h1 className="bg-blue-900 px-12 py-4 text-4xl font-semibold text-white shadow-lg">今日の天気</h1>

        <div className="flex flex-col gap-2 border border-dotted border-gray-600 p-4">
          <span>{data?.address?.display_name}</span>
          <span className="text-3xl">{getMonthDateDayInWeek(data?.weather)[data?.chunkIndex || 0]}</span>
          {data?.weather && (
            <div className="flex flex-row items-center gap-6">
              {/** getWeatherIconUrl() */}
              <i
                className="h-32 w-32 bg-contain bg-no-repeat"
                style={{
                  backgroundImage: `${
                    getWeatherCodeObject(
                      +getWeatherCodeStastitics(
                        data.weather.hourly.weathercode.slice(
                          (data.chunkIndex || 0) * 24,
                          23 + (data.chunkIndex || 0) * 24,
                        ) || [],
                      ).mostAppearedWeathercodes[0],
                    ).backgroundUrl
                  }`,
                  backgroundSize: 'cover',
                  backgroundRepeat: 'no-repeat',
                  backgroundPosition: 'center',
                }}
              />
              <span className="text-xl underline underline-offset-2">
                {getWeatherCodeStastitics(
                  data.weather.hourly.weathercode.slice(
                    (data.chunkIndex || 0) * 24,
                    23 + (data.chunkIndex || 0) * 24,
                  ) || [],
                )
                  .mostAppearedWeathercodes.map((v) => getWeatherCodeObject(+v).explain.ja)
                  .join(', ')}
              </span>
              {getWeatherCodeStastitics(
                data.weather.hourly.weathercode.slice((data.chunkIndex || 0) * 24, 23 + (data.chunkIndex || 0) * 24) ||
                  [],
              ).mostAppeared2ndWeathercodes && (
                <span className="text-xl">
                  {getWeatherCodeStastitics(
                    data.weather?.hourly.weathercode.slice(
                      (data.chunkIndex || 0) * 24,
                      23 + (data.chunkIndex || 0) * 24,
                    ) || [],
                  )
                    .mostAppeared2ndWeathercodes?.map((v) => getWeatherCodeObject(+v).explain.ja)
                    .join(', ')}
                  {' (時折)'}
                </span>
              )}
              <span className="text-2xl text-cyan-700">
                {`${data.weather.daily.temperature_2m_min[data?.chunkIndex || 0] || ''} ${
                  data.weather.daily_units.temperature_2m_min || ''
                } ⇌ ${data.weather.daily.temperature_2m_max[data?.chunkIndex || 0] || ''} ${
                  data.weather.daily_units.temperature_2m_max || ''
                }`}
              </span>
            </div>
          )}
          <Table
            hideCheckbox
            headCellData={[
              '時間',
              ...getHourlyTimeRange(data?.weather).map((t, i) => {
                return (
                  <div className="flex flex-col items-center">
                    <span className="text-center">{t}</span>
                    <i
                      className="h-16 w-16 bg-contain bg-no-repeat"
                      style={{
                        background: getWeatherCodeObject(
                          data?.weather?.hourly?.weathercode[(data.chunkIndex || 0) * 24 + i] || 0,
                        ).backgroundUrl,
                        backgroundSize: '4rem',
                      }}
                    />
                    <span className="text-center text-base font-normal">
                      {
                        getWeatherCodeObject(data?.weather?.hourly?.weathercode[(data.chunkIndex || 0) * 24 + i] || 0)
                          .explain.ja
                      }
                    </span>
                  </div>
                );
              }),
            ]}
            cellData={Object.keys(data?.weather?.hourly || {}).reduce((result, key) => {
              const k = key as keyof WeatherHourly;

              if (k !== 'time' && k !== 'weathercode')
                result.push([
                  `${key} (${data?.weather?.hourly_units[k]})`,
                  ...getHourlyChunkData(data?.weather)[k][data?.chunkIndex || 0],
                ]);

              return result;
            }, [] as (number | string)[][])}
            headProps={{
              className: 'bg-transparent text-black',
            }}
            headCellProps={{
              className: 'border-collapse border whitespace-nowrap bg-gray-300',
            }}
            cellProps={{
              className: 'border-collapse border',
            }}
            className="border"
          />
        </div>
      </div>
    </form>
  );
}
