import { Dayjs } from 'dayjs';
import { useEffect, useMemo, useRef, useState } from 'react';
import { Link, useSearchParams } from 'react-router-dom';
import ReactFlow, {
  Background,
  ConnectionMode,
  Edge,
  Handle,
  MarkerType,
  Node,
  Position,
  useEdgesState,
  useNodesState,
} from 'reactflow';
import { toasting } from 'src/lib/components/Toast.component';
import { dayts } from 'src/lib/dayjs';
import { fget } from 'src/lib/fetch';
import { DeviceEntity } from 'src/modules/dashboard/dto/device/device.entity';
import { ResponseDocument, ResponseListDocuments } from 'src/modules/dashboard/dto/response';
import { StepFloatingEdge } from 'src/modules/dashboard/pages/area/flow-chart/Area.FlowChart.page';
import { DialogWaterLevel } from 'src/modules/map-dialog/components/DialogWaterLevel.component';
import { DeviceFlowData, DeviceFlowListData } from '../dto/device-data.res';

export const DeviceNode = ({
  data: device,
}: {
  data: ResponseDocument<DeviceEntity> & {
    flow: ResponseDocument<DeviceFlowData>;
  };
}) => {
  return (
    <div className="relative flex w-28 flex-col border border-sky-400">
      <span className="inline-flex items-center justify-center whitespace-nowrap bg-sky-700 p-1.5 text-white">
        {device.DEVICE_NAME.S}
      </span>
      <div className="flex h-full flex-col justify-center bg-slate-100 px-2 py-1 text-xs">
        <span className="whitespace-nowrap text-center">{device.flow?.FLOW_RATE?.S || '-'} m3/s</span>
        <span className="whitespace-nowrap text-center">{device.flow?.WATER_LEVEL?.S || '-'} cm</span>
      </div>

      <Handle className="hidden h-full rounded-none opacity-0" position={Position.Left} type="source" />
      <Handle className="hidden h-full rounded-none opacity-0" position={Position.Right} type="source" />
      <Handle className="hidden w-full rounded-none opacity-0" position={Position.Top} type="source" />
      <Handle className="hidden w-full rounded-none opacity-0" position={Position.Bottom} type="source" />
    </div>
  );
};

export function DeviceFlowClientPage() {
  const mark = document.getElementsByClassName('react-flow__panel react-flow__attribution bottom right').item(0);
  if (mark) mark.innerHTML = '';

  const [searchParams] = useSearchParams();

  const eRange = useRef<HTMLInputElement>(null);

  const [flows, setFlows] = useState<DeviceFlowListData>();
  const [devices, setDevices] = useState<ResponseDocument<DeviceEntity>[]>([]);
  const [flowLength, setFlowLength] = useState<number>();

  const [nodes, setNodes, onNodesChange] = useNodesState([]);
  const [edges, setEdges, onEdgesChange] = useEdgesState([]);

  const nodeTypes = useMemo(() => ({ device: DeviceNode }), []);
  const edgeTypes = useMemo(() => ({ floating: StepFloatingEdge }), []);

  const [playing, setPlaying] = useState<boolean>(false);
  const [currentPlayingTime, setCurrentPlayingTime] = useState<Dayjs>();

  const intervalPlaying = useRef<number>();

  useEffect(() => {
    const fetchData = async () => {
      try {
        const [_, _devices] = await fget<unknown, ResponseListDocuments<DeviceEntity>>(
          `api/deviceByArea?AREA_NAME=${searchParams.get('areaName')}`,
        );
        if (!_devices.Items?.length) {
          toasting({ children: 'デバイスがありません', containerProps: { className: 'border-blue-500' } });
          return;
        }

        const deviceIds = _devices.Items?.map((d) => d.DEVICE_ID.S || '') || [];

        const [__, _flows] = await fget<unknown, DeviceFlowListData>(
          `api/device-records?deviceIds=${deviceIds.join(',')}`,
        );

        const _flowLength = Object.values(_flows)[0]?.length;

        setFlows(_flows);
        setDevices(_devices.Items || []);
        setFlowLength(_flowLength);
      } catch (error) {
        console.error('Error fetching data:', error);
      }
    };

    fetchData();
  }, []);

  const pushFlowDataToNodes = (sliderIndex: number) => {
    setNodes((prev) => {
      const newVal = devices.reduce((deviceNodes: Node[], device) => {
        const arrayCoord = device.POSITIONS?.S?.split(',').map((coord) => +coord);
        if (arrayCoord?.length === 2 && device.DEVICE_ID.S) {
          const position = {
            x: arrayCoord[0],
            y: arrayCoord[1],
          };

          deviceNodes.push({
            data: {
              ...device,
              flow: flows?.[device.DEVICE_ID.S]?.[sliderIndex],
            },
            id: device.DEVICE_ID.S,
            position: prev.find((node) => node.data.DEVICE_ID.S === device.DEVICE_ID.S)?.position || position,
            type: 'device',
            deletable: false,
          });
        }

        return deviceNodes;
      }, []);

      return newVal || prev;
    });

    if (flows && flowLength) {
      const currentTimeStr = Object.values(flows)[0][sliderIndex].CREATE_AT.S;
      if (currentTimeStr) {
        setCurrentPlayingTime(dayts.unix(+currentTimeStr / 1000));
      }
    }
  };

  const jump = (step: number) => {
    if (eRange.current && flows && flowLength) {
      const currentIndex = eRange.current.valueAsNumber + step;
      if (currentIndex > flowLength - 1) eRange.current.valueAsNumber = 0;
      else eRange.current.valueAsNumber += step;

      if (currentIndex < 0) eRange.current.valueAsNumber = flowLength - 1;

      pushFlowDataToNodes(eRange.current.valueAsNumber);
    }
  };

  useEffect(() => {
    setEdges(
      devices.reduce((edges: Edge[], device, i) => {
        const _edges: Edge[] =
          device.CONNECTED_DEVICE_IDS?.L?.map((deviceId, j) => ({
            id: `react-flow____${i + j}`,
            source: device.DEVICE_ID.S || '',
            target: deviceId.S,
            deletable: false,
          })) || [];

        edges.push(..._edges);

        return edges;
      }, []),
    );

    if (flows) pushFlowDataToNodes((flowLength || 1) - 1);
  }, [devices]);

  useEffect(() => {
    if (flows && devices.length && eRange.current) {
      if (playing) {
        let last = dayts().valueOf();
        const render = () => {
          const now = dayts().valueOf();
          if (!last || now - last >= 1000) {
            last = now;
            jump(1);
          }
          intervalPlaying.current = requestAnimationFrame(render);
        };
        render();
      } else if (intervalPlaying.current) cancelAnimationFrame(intervalPlaying.current);
    }

    return () => {
      if (intervalPlaying.current) cancelAnimationFrame(intervalPlaying.current);
    };
  }, [playing]);

  return (
    <>
      <div className="custom-scrollbar-1 relative mx-auto h-[100vh] min-w-[64rem] overflow-y-auto overflow-x-hidden">
        <Link
          className="absolute left-2 top-2 z-10 border-2 bg-white px-6 text-center text-xl leading-10"
          to=".."
          style={{ color: '#015CAC', borderColor: '#015CAC' }}>
          戻り
        </Link>
        <ReactFlow
          nodes={nodes}
          edges={edges}
          nodeTypes={nodeTypes}
          edgeTypes={edgeTypes}
          // onNodesChange={onNodesChange}
          onEdgesChange={onEdgesChange}
          connectionMode={ConnectionMode.Loose}
          defaultEdgeOptions={{
            type: 'floating',
            markerEnd: {
              type: MarkerType.ArrowClosed,
              color: 'black',
              width: 8,
              height: 8,
            },
            style: {
              strokeWidth: 4,
            },
          }}
          className="h-full border-sky-600"
          fitView>
          <Background />
        </ReactFlow>
      </div>

      <DialogWaterLevel />

      {flows && devices.length && (
        <div className="absolute bottom-0 left-1/2 z-10 min-w-[36rem] -translate-x-1/2 text-center text-black">
          <span className="rounded-md border border-slate-600 bg-zinc-200 px-6 py-2 text-sm">
            {(currentPlayingTime || dayts())?.tz('JST').format('HH:mm:ss')} JST
          </span>
          <div className="flex flex-col rounded-t-md border border-b-0 border-slate-600 bg-zinc-200 bg-opacity-30 px-3">
            <div className="mx-auto my-2 flex w-full items-center justify-between px-2 text-xs">
              <div
                className="flex w-12 cursor-pointer select-none justify-center rounded-md border-2 border-white bg-sky-700 py-2.5"
                onClick={() => {
                  jump(-1);
                }}>
                <img
                  src="assets/play.png"
                  width="13rem"
                  className="translate-x-0.5"
                  alt={'back'}
                  style={{ transform: 'rotate(180deg)' }}></img>
              </div>

              <span
                className=""
                onClick={() => {
                  setPlaying((prev) => !prev);
                }}>
                <svg
                  className="h-6 w-6"
                  viewBox="0 0 24 24"
                  fill="whitesmoke"
                  stroke="currentColor"
                  strokeWidth="2"
                  strokeLinecap="round"
                  strokeLinejoin="round">
                  {playing ? (
                    <>
                      <rect x="6" y="4" width="4" height="16" /> <rect x="14" y="4" width="4" height="16" />
                    </>
                  ) : (
                    <>
                      <polygon points="5 3 19 12 5 21 5 3" />
                    </>
                  )}
                </svg>
              </span>

              <div
                className="flex w-12 cursor-pointer select-none justify-center rounded-md border-2 border-white bg-sky-700 py-2.5"
                onClick={() => {
                  jump(1);
                }}>
                <img src="assets/play.png" width="13rem" className="translate-x-0.5" alt={'back'} style={{}}></img>
              </div>
            </div>

            <div className="flex justify-around">
              <span className="text-sm">1時間前</span>
              <input
                id="range-style-1"
                type="range"
                ref={eRange}
                onChange={(e) => {
                  pushFlowDataToNodes(e.target.valueAsNumber);
                }}
                //3 hours ago
                min={0}
                //1 hour later
                max={(flowLength || 1) - 1}
                // now
                defaultValue={(flowLength || 1) - 1}
                step={1}
                className="range-sm h-1 w-[76%] cursor-pointer appearance-none self-center bg-slate-600 text-center dark:bg-gray-700"
              />
              <span className="text-sm">現在</span>
            </div>

            <div className="flex h-6 justify-between overflow-hidden">
              <span className="ml-5 opacity-0">1時間前</span>

              <div className="flex w-[76%] items-center justify-between">
                {Array.from({ length: flowLength || 1 }, () => (
                  <div className="h-3 border border-black"></div>
                ))}
              </div>

              <span className="mr-6 opacity-0">現在</span>
            </div>
          </div>
        </div>
      )}
    </>
  );
}
