import React, { useImperativeHandle, useRef } from 'react';
import DatePicker, { DatePickerProps } from 'react-widgets/DatePicker';
import { DateLocalizer } from 'react-widgets/IntlLocalizer';
import Localization from 'react-widgets/Localization';
import { UserProvidedMessages } from 'react-widgets/messages';
import { twMerge } from 'tailwind-merge';
import { uniqueId } from '../dayjs/unique-id';
import { Props } from '../type/props.type';

export interface DatePickerRef {
  value: number;
}

export const Datepicker = React.forwardRef<
  DatePickerRef,
  DatePickerProps & {
    id?: string;
    labelProps?: Props<'label'>;
    containerProps?: Props<'div'>;
    locale?: string;
    messages?: UserProvidedMessages;
  }
>(({ id = uniqueId('DatePicker-'), labelProps, containerProps, locale = 'en', messages, ...props }, ref) => {
  const e = useRef<DatePickerRef>({} as DatePickerRef);

  useImperativeHandle(ref, () => e.current);

  return (
    <div {...containerProps}>
      <label
        {...labelProps}
        className={twMerge('mb-2 block text-sm font-normal text-gray-900 dark:text-white', labelProps?.className)}
        htmlFor={id}
      />
      <Localization date={new DateLocalizer({ culture: locale })} messages={messages}>
        <DatePicker
          valueDisplayFormat={{
            day: 'numeric',
            month: 'numeric',
            year: 'numeric',
            hour: 'numeric',
            minute: 'numeric',
            second: 'numeric',
          }}
          valueFormat={{
            day: 'numeric',
            month: 'numeric',
            year: 'numeric',
            hour: 'numeric',
            minute: 'numeric',
            second: 'numeric',
          }}
          valueEditFormat={{
            day: 'numeric',
            month: 'numeric',
            year: 'numeric',
            hour: 'numeric',
            minute: 'numeric',
            second: 'numeric',
          }}
          dropUp
          containerClassName={twMerge('text-sm !rounded-md', props.containerClassName)}
          includeTime
          timePrecision="seconds"
          {...props}
          id={id}
        />
      </Localization>
    </div>
  );
});
