import { CheckboxProps, Checkbox as FlowBiteCheckbox, Label, LabelProps } from 'flowbite-react';
import React, { useEffect, useImperativeHandle, useRef } from 'react';
import { twMerge } from 'tailwind-merge';
import { uniqueId } from '../dayjs/unique-id';
import { Props } from '../type/props.type';

export const Checkbox = React.forwardRef<
  HTMLInputElement,
  CheckboxProps & {
    labelProps?: LabelProps;
    containerProps?: Props<'div'>;
  }
>(({ id = uniqueId('Checkbox-'), className = '', containerProps, labelProps, ...props }, ref) => {
  const e = useRef<HTMLInputElement>({} as HTMLInputElement);

  useEffect(() => {
    if (e.current)
      e.current.className = twMerge(e.current.className, 'rounded-md border-gray-500 text-black', className);
  }, []);

  useImperativeHandle(ref, () => e.current);

  return (
    <div {...containerProps} className={twMerge('flex items-center gap-2', containerProps?.className)}>
      <FlowBiteCheckbox {...props} id={id} ref={e} />
      <Label htmlFor={id} {...labelProps} />
    </div>
  );
});
