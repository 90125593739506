import { JmaTargetTime } from 'src/redux/slices/precipitation.slice';
import type { PayloadAction } from '@reduxjs/toolkit';
import { createSlice } from '@reduxjs/toolkit';

const initialState = {
  pastTimesLength: 0,
  futureTimesLength: 0,

  timeline: [] as JmaTargetTime[],

  isPlaying: false,
  isEnableControlBottom: false,
  controlBottomClassName: 'w-[calc(100vw-15rem)]',
};

export const precipitationPlayingSlice = createSlice({
  name: 'precipitationPlayingSlice',
  initialState,
  reducers: {
    setPlaying: (state, action: PayloadAction<boolean>) => {
      // Redux Toolkit allows us to write "mutating" logic in reducers. It
      // doesn't actually mutate the state because it uses the Immer library,
      // which detects changes to a "draft state" and produces a brand new
      // immutable state based off those changes

      state.isPlaying = action.payload;
    },
    setIsEnableControlBottom: (state, action: PayloadAction<boolean>) => {
      state.isEnableControlBottom = action.payload;
    },
    setControlBottomClassName: (state, action: PayloadAction<string>) => {
      state.controlBottomClassName = action.payload;
    },
    initPrecipitationPlaying: (
      state,
      action: PayloadAction<{
        pastTimesLength: number;
        futureTimesLength: number;
        timeline: JmaTargetTime[];
      }>,
    ) => {
      state.pastTimesLength = action.payload.pastTimesLength;
      state.futureTimesLength = action.payload.futureTimesLength;
      state.timeline = action.payload.timeline;
    },
  },
});

// Action creators are generated for each case reducer function
export const { setPlaying, setIsEnableControlBottom, initPrecipitationPlaying, setControlBottomClassName } =
  precipitationPlayingSlice.actions;

export const precipitationPlayingReducer = precipitationPlayingSlice.reducer;
