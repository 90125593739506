import type { GeoJsonObject } from 'geojson';
import { GeoJSON as GeoJSONEntity, LatLng, Map, TileLayer as TileLayerEntity } from 'leaflet';
import { useEffect, useRef, useState } from 'react';
import { GeoJSON } from 'react-leaflet';
import { useSelector } from 'react-redux';
import { toasting } from 'src/lib/components/Toast.component';
import { fget } from 'src/lib/fetch';
import { ResponseDocument, ResponseListDocuments } from 'src/modules/dashboard/dto/response';
import { RiverEntity } from 'src/modules/dashboard/dto/river/river.entity';
import { RootState } from 'src/redux/store';

export function CheckBoxRegionGeoJSON({
  label = '対象河川',
  checked,
  baseMap,
}: {
  label?: string;
  baseTileLayer: React.RefObject<TileLayerEntity>;
  checked?: boolean;
  baseMap?: React.RefObject<Map>;
}) {
  // Handle checkbox
  const eLabel = useRef<HTMLLabelElement>(null);
  const eInputLabel = useRef<HTMLDivElement>(null);
  const eGeoJSON = useRef<GeoJSONEntity>(null);

  const [inputChecked, setInputChecked] = useState<boolean>(!!checked);

  const selectedAreaCoordinates = useSelector((state: RootState) => state.areaReducer.areaCoordinates);

  const changeCheckingStatus = (check: boolean) => {
    if (check) {
      eInputLabel.current?.classList.add('text-white', 'bg-blue-500');
      eInputLabel.current?.classList.remove('text-blue-500');
    } else {
      eInputLabel.current?.classList.add('text-blue-500');
      eInputLabel.current?.classList.remove('text-white', 'bg-blue-500');
    }
  };

  useEffect(() => {
    changeCheckingStatus(inputChecked);
  }, [inputChecked]);

  useEffect(() => {
    const fetchData = async () => {
      try {
        const riverResponse = await fget<string, ResponseListDocuments<RiverEntity>>('/api/rivers');
        const rivers = riverResponse?.[1]?.Items;

        const geoJsonDataList = rivers
          ?.map((river: ResponseDocument<RiverEntity>) => {
            return river?.COORDINATE_DATA?.S;
          })
          .filter((v) => v);

        // get presigned url
        const getPresignedUrl = async (key: string | undefined) => {
          if (!key) return;
          const res = await fget<string, { url: string }>(`/api/s3/get-geojson-url?key=${key}`);
          return res?.[1]?.url;
        };

        const geoJsonUrlList = await Promise.all(geoJsonDataList?.map((key) => getPresignedUrl(key)) || []);

        // get geojson data
        const geoJsonData = (
          await Promise.all(
            geoJsonUrlList?.map(async (url, i) => {
              if (!url) return;
              const res = await fetch(url);
              try {
                const data = await res.json();

                return data;
              } catch (e) {
                return null;
              }
            }) || [],
          )
        ).filter((v) => v);

        geoJsonData?.forEach((data) => {
          eGeoJSON.current?.addData(data);
        });
      } catch (e) {
        console.error(e);
        toasting({ children: '地図データの取得に失敗しました', containerProps: { className: 'border-red-600' } });
      }
    };
    fetchData();
  }, [inputChecked]);

  return (
    <>
      <div className="flex items-center" ref={eInputLabel}>
        <input
          id={label}
          type="checkbox"
          checked={inputChecked}
          onChange={(e) => {
            setInputChecked(e.target.checked);
            const [lat, lng] = selectedAreaCoordinates.split(',');
            if (isNaN(parseFloat(lat)) || isNaN(parseFloat(lng))) return;

            const cvtSelectedAreaCoordinates: LatLng = new LatLng(parseFloat(lat), parseFloat(lng));
            baseMap?.current?.setView(cvtSelectedAreaCoordinates, 10);
          }}
          className="hidden h-4 w-4 rounded border-gray-300 bg-gray-100 text-blue-600 focus:ring-2 focus:ring-blue-500 dark:border-gray-600 dark:bg-gray-700 dark:ring-offset-gray-800 dark:focus:ring-blue-600"
        />
        <label
          htmlFor={label}
          className="flex-1 select-none border-2 border-blue-500 p-2 text-center text-sm font-medium text-inherit md:p-3"
          ref={eLabel}>
          {label}
        </label>
      </div>

      {inputChecked && <GeoJSON ref={eGeoJSON} data={{ type: 'FeatureCollection', features: [] } as GeoJsonObject} />}
    </>
  );
}
