import React, { forwardRef, useEffect, useImperativeHandle, useRef, useState } from 'react';
import { twMerge } from 'tailwind-merge';
import { uniqueId } from '../dayjs/unique-id';
import { Props } from '../type/props.type';

export interface PaginationRef {
  page: number;
}

export const Pagination = forwardRef<
  PaginationRef,
  Props<'ul'> & {
    total: number;
    defaultPageValue?: number;
    size?: number;
    iconPrevious?: React.ReactNode;
    iconNext?: React.ReactNode;
    hops?: number | 'lastpoint';
    onPageChange?: (page: number) => boolean | void;
    hidePages?: boolean;
  }
>(
  (
    {
      id = uniqueId('Pagination-'),
      total,
      defaultPageValue = 0,
      size = 5,
      iconNext,
      iconPrevious,
      hops = 1,
      onPageChange,
      hidePages,
      ...props
    },
    ref,
  ) => {
    const e = useRef<PaginationRef>({ page: defaultPageValue });

    const [page, setPage] = useState<number>(defaultPageValue);

    const listPage = () => {
      let min = page - Math.floor(size / 2);

      if (min < 0) min = 0;
      else if (min + size - 1 > total) min = total - (size - 1);

      return Array.from({ length: Math.min(size, total) }, (_, i) => ({
        value: min + i,
        selected: min + i === page,
      }));
    };

    useEffect(() => {
      e.current.page = page;
      onPageChange && onPageChange(page);
    }, [page]);

    useImperativeHandle(ref, () => e.current);

    return total ? (
      <ul {...props} className={twMerge('inline-flex items-center -space-x-px', props.className)}>
        <li>
          <a
            href="#"
            className="ml-0 block rounded-l-lg border border-gray-300 bg-white px-3 py-2 leading-tight text-gray-500 hover:bg-gray-100 hover:text-gray-700 dark:border-gray-700 dark:bg-gray-800 dark:text-gray-400 dark:hover:bg-gray-700 dark:hover:text-white"
            onClick={() => {
              switch (hops) {
                case 'lastpoint':
                  setPage(0);
                  break;
                default:
                  setPage((currentPage) => (currentPage - hops <= 0 ? 0 : currentPage - hops));
                  break;
              }
            }}>
            <span className="sr-only">Previous</span>
            {iconPrevious ? (
              iconPrevious
            ) : (
              <svg
                aria-hidden="true"
                className="h-5 w-5"
                fill="currentColor"
                viewBox="0 0 20 20"
                xmlns="http://www.w3.org/2000/svg">
                <path
                  fillRule="evenodd"
                  d="M12.707 5.293a1 1 0 010 1.414L9.414 10l3.293 3.293a1 1 0 01-1.414 1.414l-4-4a1 1 0 010-1.414l4-4a1 1 0 011.414 0z"
                  clipRule="evenodd"></path>
              </svg>
            )}
          </a>
        </li>

        {hidePages ? (
          <></>
        ) : (
          listPage().map(({ value, selected }, i) => (
            <li
              key={i}
              onClick={() => {
                setPage(value);
              }}>
              {selected ? (
                <a
                  href="#"
                  aria-current="page"
                  className="z-10 border border-blue-300 bg-blue-50 px-3 py-2 leading-tight text-blue-600 hover:bg-blue-100 hover:text-blue-700 dark:border-gray-700 dark:bg-gray-700 dark:text-white">
                  {value + 1}
                </a>
              ) : (
                <a
                  href="#"
                  className="border border-gray-300 bg-white px-3 py-2 leading-tight text-gray-500 hover:bg-gray-100 hover:text-gray-700 dark:border-gray-700 dark:bg-gray-800 dark:text-gray-400 dark:hover:bg-gray-700 dark:hover:text-white">
                  {value + 1}
                </a>
              )}
            </li>
          ))
        )}
        <li>
          <a
            href="#"
            className="block rounded-r-lg border border-gray-300 bg-white px-3 py-2 leading-tight text-gray-500 hover:bg-gray-100 hover:text-gray-700 dark:border-gray-700 dark:bg-gray-800 dark:text-gray-400 dark:hover:bg-gray-700 dark:hover:text-white"
            onClick={() => {
              switch (hops) {
                case 'lastpoint':
                  setPage(total);
                  break;
                default:
                  setPage((currentPage) => (currentPage + hops >= total ? total : currentPage + hops));
                  break;
              }
            }}>
            <span className="sr-only">Next</span>
            {iconNext ? (
              iconNext
            ) : (
              <svg
                aria-hidden="true"
                className="h-5 w-5"
                fill="currentColor"
                viewBox="0 0 20 20"
                xmlns="http://www.w3.org/2000/svg">
                <path
                  fillRule="evenodd"
                  d="M7.293 14.707a1 1 0 010-1.414L10.586 10 7.293 6.707a1 1 0 011.414-1.414l4 4a1 1 0 010 1.414l-4 4a1 1 0 01-1.414 0z"
                  clipRule="evenodd"></path>
              </svg>
            )}
          </a>
        </li>
      </ul>
    ) : (
      <></>
    );
  },
);
