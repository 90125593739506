import { configureStore } from '@reduxjs/toolkit';
import thunk from 'redux-thunk';
import { areaReducer } from './slices/area.slice';
import { baseLayerReducer } from './slices/baseLayer.slice';
import { devicesReducer } from './slices/devices.slice';
import { enableLayerStatusReducer } from './slices/enableLayerStatus.slice';
import { precipitationReducer } from './slices/precipitation.slice';
import { precipitationPlayingReducer } from './slices/precipitationPlaying.slice';
import { showDialogReducer } from './slices/showDialog.slice';
import { sidebarReducer } from './slices/sidebar.slice';

export const store = configureStore({
  reducer: {
    precipitationPlayingReducer: precipitationPlayingReducer,
    precipitationReducer: precipitationReducer,
    showDialogReducer: showDialogReducer,
    selectBaseLayerReducer: baseLayerReducer,
    enableLayerStatusReducer: enableLayerStatusReducer,
    sidebarReducer: sidebarReducer,
    devicesReducer: devicesReducer,
    areaReducer: areaReducer,
  },
  middleware: (getDefaultMiddleware) => getDefaultMiddleware().concat(thunk),
});

// Infer the `RootState` and `AppDispatch` types from the store itself
export type RootState = ReturnType<typeof store.getState>;

// Inferred type: {posts: PostsState, comments: CommentsState, users: UsersState}
export type AppDispatch = typeof store.dispatch;
