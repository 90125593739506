import type { PayloadAction } from '@reduxjs/toolkit';
import { createSlice } from '@reduxjs/toolkit';

const initialState = {
  showDialogWaterLevel: false,
  position: {
    deviceId: '',
    flowRate: '',
    pos: '',
    waterLevel: '',
    velocity: ''
  },
  isMap: true,
  isWaterLevel: false,
  isFlowRate: false,
  isVelocity: false,
};

export const showDialogSlice = createSlice({
  name: 'showDialogSlice',
  initialState,
  reducers: {
    setShowingDialogState: (state, action: PayloadAction<Partial<typeof initialState>>) => {
      // Redux Toolkit allows us to write "mutating" logic in reducers. It
      // doesn't actually mutate the state because it uses the Immer library,
      // which detects changes to a "draft state" and produces a brand new
      // immutable state based off those changes
      return {
        ...state,
        ...action.payload,
      };
    },
  },
});

// Action creators are generated for each case reducer function
export const { setShowingDialogState } = showDialogSlice.actions;

export const showDialogReducer = showDialogSlice.reducer;
