import { useEffect, useRef, useState } from 'react';
import Control from 'react-leaflet-custom-control';
import { useDispatch, useSelector } from 'react-redux';
import { Select } from 'src/lib/components/Select.component';
import { dayts } from 'src/lib/dayjs';
import { setCurrentUrls, setTimeMode } from 'src/redux/slices/precipitation.slice';
import { setPlaying } from 'src/redux/slices/precipitationPlaying.slice';
import { RootState } from 'src/redux/store';
import { twMerge } from 'tailwind-merge';

export function TileLayerControlBellow({
  events,
}: {
  events?: {
    onChangeTimeRangeCurrent?: (value: string) => void;
  };
}) {
  const intervalPlaying = useRef<number>();
  const eRange = useRef<HTMLInputElement>(null);
  const eSelect = useRef<HTMLSelectElement>(null);

  const precipitationUrl = useSelector((state: RootState) => state.precipitationReducer.urlTile);
  const precipitationUrlHrpnsNd = useSelector((state: RootState) => state.precipitationReducer.urlHrpnsNd);
  const precipitationPlaying = useSelector((state: RootState) => state.precipitationPlayingReducer);
  const timeMode = useSelector((state: RootState) => state.precipitationReducer.mode);

  const [currentIndex, setCurrentIndex] = useState(precipitationPlaying.pastTimesLength - 1);

  const dispatch = useDispatch();

  const setDataUrl = (index: number) => {
    setCurrentIndex(index);
    dispatch(setCurrentUrls({ tile: precipitationUrl[index], hrpnsNd: precipitationUrlHrpnsNd[index] }));
  };

  const jump = (step: number) => {
    if (eRange.current) {
      const _currentIndex = eRange.current.valueAsNumber + step;
      if (_currentIndex > precipitationPlaying.pastTimesLength + precipitationPlaying.futureTimesLength - 1)
        eRange.current.valueAsNumber = 0;
      else eRange.current.valueAsNumber += step;

      if (_currentIndex < 0)
        eRange.current.valueAsNumber =
          precipitationPlaying.pastTimesLength + precipitationPlaying.futureTimesLength - 1;

      setDataUrl(eRange.current.valueAsNumber);
    }
  };

  useEffect(() => {
    if (precipitationPlaying.isPlaying) {
      let last = dayts().valueOf();
      const render = () => {
        const now = dayts().valueOf();
        if (!last || now - last >= 1000) {
          last = now;
          if (window.tileRainFallLoaded) {
            jump(1);
          }
        }
        intervalPlaying.current = requestAnimationFrame(render);
      };
      render();
    } else if (intervalPlaying.current) cancelAnimationFrame(intervalPlaying.current);

    return () => {
      if (intervalPlaying.current) cancelAnimationFrame(intervalPlaying.current);
    };
  }, [precipitationPlaying.isPlaying]);

  useEffect(() => {
    setCurrentIndex(precipitationPlaying.pastTimesLength - 1);
  }, [precipitationPlaying.pastTimesLength]);

  useEffect(() => {
    jump(0);
  }, [precipitationUrl]);

  return precipitationPlaying.isEnableControlBottom ? (
    <Control
      position="bottomleft"
      container={{
        id: 'tile-layer-control-bellow',
        className: twMerge('flex flex-col text-black', precipitationPlaying.controlBottomClassName),
      }}>
      <div className="mb-2 text-center">
        {precipitationPlaying.timeline[currentIndex] && (
          <span className="rounded-md bg-slate-100 bg-opacity-70 px-8 py-2 text-lg font-semibold">
            {dayts(precipitationPlaying.timeline[currentIndex].validtime, {
              format: 'YYYYMMDDhhmmss',
              utc: true,
            })
              .tz('JST')
              .format('MM/DD HH:mm')}{' '}
            JST
          </span>
        )}
      </div>
      <div className="bg-slate-100 bg-opacity-50 px-8 py-2">
        <div className="flex justify-center gap-4">
          <div className="text-center">
            <div>
              <span className="mr-5 text-lg" style={{}}>
                降水強度
              </span>
              <span style={{ background: '#D9D9D9' }}></span>
              <span className="px-5 text-xl" style={{ background: '#9AEBFF' }}></span>
              <span className="px-5 text-xl" style={{ background: '#48E1FF' }}></span>
              <span className="px-5 text-xl" style={{ background: '#25AEFF' }}></span>
              <span className="px-5 text-xl" style={{ background: '#00F42E' }}></span>
              <span className="px-5 text-xl" style={{ background: '#FAF714' }}></span>
              <span className="px-5 text-xl" style={{ background: '#FF6666' }}></span>
              <span className="px-5 text-xl" style={{ background: '#FF0000' }}></span>
            </div>
            <div>
              <span className=" text-l mr-5 pl-6" style={{}}>
                (mm/h)
              </span>
              <span className="ml-4" style={{ background: '#D9D9D9' }}></span>
              <span className="text-l px-4">1</span>
              <span className="text-l px-4">5</span>
              <span className="text-l px-4">10</span>
              <span className="text-l px-4">20</span>
              <span className="text-l px-4">30</span>
              <span className="text-l px-4">50</span>
              <span className="text-l px-4">80</span>
            </div>
          </div>
          <Select
            className="rounded-sm text-xs"
            defaultValue={timeMode}
            onChange={(e) => {
              dispatch(setPlaying(false));
              dispatch(setTimeMode(e.target.value));
            }}
            ref={eSelect}>
            <option value="5m">雨雲の動き(5m)</option>
            <option value="30m">今後の雨(30m)</option>
          </Select>
        </div>

        <div className="mx-auto flex w-full items-center justify-between px-2 text-xs">
          <div
            className="-mt-12 flex w-16 justify-center rounded-lg border-2 border-white bg-sky-700 py-3"
            onClick={() => {
              jump(-1);
            }}>
            <img src="assets/play.png" width="13rem" alt={'back'} style={{ transform: 'rotate(180deg)' }}></img>
          </div>

          <span
            className=""
            onClick={() => {
              dispatch(setPlaying(!precipitationPlaying.isPlaying));
            }}>
            <svg
              className="h-6 w-6"
              viewBox="0 0 24 24"
              fill="none"
              stroke="currentColor"
              strokeWidth="2"
              strokeLinecap="round"
              strokeLinejoin="round">
              {precipitationPlaying.isPlaying ? (
                <>
                  <rect x="6" y="4" width="4" height="16" /> <rect x="14" y="4" width="4" height="16" />
                </>
              ) : (
                <>
                  <polygon points="5 3 19 12 5 21 5 3" />
                </>
              )}
            </svg>
          </span>

          <div
            className="-mt-12 flex w-16 justify-center rounded-lg border-2 border-white bg-sky-700 py-3"
            onClick={() => {
              jump(1);
            }}>
            <img src="assets/play.png" width="13rem" alt={'back'} style={{}}></img>
          </div>
        </div>

        <div className="flex justify-center gap-2">
          <span className="ml-1 text-sm">
            {dayts(precipitationPlaying.timeline[0]?.validtime, {
              format: 'YYYYMMDDhhmmss',
              utc: true,
            })
              .tz('JST')
              .format('MM/DD HH:mm') || '1時間前'}
          </span>
          <input
            id="small-range"
            type="range"
            ref={eRange}
            onChange={(e) => {
              setDataUrl(e.target.valueAsNumber);
            }}
            //3 hours ago
            min={0}
            //1 hour later
            max={precipitationPlaying.pastTimesLength + precipitationPlaying.futureTimesLength - 1}
            // now
            defaultValue={precipitationPlaying.pastTimesLength - 1}
            // 5 minute
            step={1}
            className="range-sm h-1 w-4/5 cursor-pointer appearance-none self-center rounded-lg bg-slate-500 text-center dark:bg-gray-700"
          />
          <span className="mr-1 text-sm">
            {dayts(precipitationPlaying.timeline[precipitationPlaying.timeline.length - 1]?.validtime, {
              format: 'YYYYMMDDhhmmss',
              utc: true,
            })
              .tz('JST')
              .format('MM/DD HH:mm') || '1時間後'}
          </span>
        </div>

        <div className="flex w-full justify-center">
          <div className="flex w-4/5 justify-between text-xs">
            <span>|</span>
            <span>|</span>
            <span>|</span>
            <span>|</span>
            <span>|</span>
          </div>
        </div>
      </div>
    </Control>
  ) : (
    <Control position="bottomleft"></Control>
  );
}
