import { ButtonProps, Button as FlowBiteButton } from 'flowbite-react';
import React, { useEffect, useImperativeHandle, useRef } from 'react';
import { twMerge } from 'tailwind-merge';

export const Button = React.forwardRef<HTMLButtonElement, ButtonProps>(({ id, className = '', ...props }, ref) => {
  const e = useRef<HTMLButtonElement>({} as HTMLButtonElement);

  useEffect(() => {
    if (e.current)
      e.current.className = twMerge(
        e.current.className,
        'rounded-md border-gray-500 text-black disabled:bg-transparent',
        className,
      );
  }, []);

  useImperativeHandle(ref, () => e.current);

  return <FlowBiteButton {...props} id={id} ref={e} />;
});
