import { Toaster } from 'react-hot-toast';
import { Outlet, Route, Routes } from 'react-router-dom';
import { ToastLimit } from './lib/components/ToastLimit.component';
import { DashboardContainer } from './modules/dashboard/components/DashboardContainer.component';
import { DashboardContentLayout } from './modules/dashboard/components/DashboardContentLayout.component';
import { LoginPage } from './modules/dashboard/pages/Login.page';
import './modules/dashboard/css/dashboard.css';
import { LogoutPage } from './modules/dashboard/pages/Logout.page';
import { UserLoginPage } from './modules/dashboard/pages/UserLogin.page';
import { UserLogoutPage } from './modules/dashboard/pages/UserLogout.page';
import { CreateAdminUserPage } from './modules/dashboard/pages/admin-user/CreateUser.page';
import { EditAdminUserPage } from './modules/dashboard/pages/admin-user/EditUser.page';
import { AdminUsersPage } from './modules/dashboard/pages/admin-user/Users.page';
import { AreasPage } from './modules/dashboard/pages/area/Areas.page';
import { CreateAreaPage } from './modules/dashboard/pages/area/CreateArea.page';
import { EditAreaPage } from './modules/dashboard/pages/area/EditArea.page';
import AreaFlowChartPage from './modules/dashboard/pages/area/flow-chart/Area.FlowChart.page';
import { CreateDamPage } from './modules/dashboard/pages/dam/CreateDam.page';
import { DamsPage } from './modules/dashboard/pages/dam/Dams.page';
import { EditDamPage } from './modules/dashboard/pages/dam/EditDam.page';
import { CreateDevicePage } from './modules/dashboard/pages/device/CreateDevice.page';
import { DevicesPage } from './modules/dashboard/pages/device/Devices.page';
import { EditDevicePage } from './modules/dashboard/pages/device/EditDevice.page';
import { RawPage } from './modules/dashboard/pages/raw/Raw.page';
import { CreateRiverPage } from './modules/dashboard/pages/river/CreateRiver.page';
import { EditRiverPage } from './modules/dashboard/pages/river/EditRiver.page';
import { RiversPage } from './modules/dashboard/pages/river/Rivers.page';
import { CreateUserPage } from './modules/dashboard/pages/user/CreateUser.page';
import { EditUserPage } from './modules/dashboard/pages/user/EditUser.page';
import { UsersPage } from './modules/dashboard/pages/user/Users.page';
import { WeatherPage } from './modules/dashboard/pages/weather/Weather.page';
import { NotFoundPage } from './modules/error/pages/NotFoundPage.page';
import { MapPage } from './modules/map/pages';
import { RouteMiddleware } from './modules/route-middleware/pages/RouteMiddleware.page';
import { DeviceFlowClientPage } from './modules/water-navigation/pages/DeviceFlowClient.page';
import { Graph } from './modules/graph/pages/Graph.page';

function App() {
  return (
    <>
      <Routes>
        <Route path="/" element={<RouteMiddleware />}>
          <Route index path="map" element={<MapPage />} />
          <Route index path="login" element={<UserLoginPage />} />
          <Route index path="logout" element={<UserLogoutPage />} />

          <Route path="admin" element={<DashboardContainer />}>
            <Route
              path="login"
              element={
                <DashboardContentLayout className="!ml-0 !w-full">
                  <LoginPage />
                </DashboardContentLayout>
              }
            />

            <Route
              path="logout"
              element={
                <DashboardContentLayout>
                  <LogoutPage />
                </DashboardContentLayout>
              }
            />

            <Route
              path="areas"
              element={
                <DashboardContentLayout>
                  <Outlet />
                </DashboardContentLayout>
              }>
              <Route index element={<AreasPage />} />

              <Route path="create" element={<CreateAreaPage />} />
              <Route path=":id/flow-chart" element={<AreaFlowChartPage />}></Route>
              <Route path=":id" element={<EditAreaPage />} />
            </Route>

            <Route
              path="rivers"
              element={
                <DashboardContentLayout>
                  <Outlet />
                </DashboardContentLayout>
              }>
              <Route index element={<RiversPage />} />

              <Route path="create" element={<CreateRiverPage />} />
              <Route path=":id" element={<EditRiverPage />} />
            </Route>

            <Route
              path="devices"
              element={
                <DashboardContentLayout>
                  <Outlet />
                </DashboardContentLayout>
              }>
              <Route index element={<DevicesPage />} />

              <Route path="create" element={<CreateDevicePage />} />
              <Route path=":id" element={<EditDevicePage />} />
            </Route>

            <Route
              path="raw"
              element={
                <DashboardContentLayout>
                  <Outlet />
                </DashboardContentLayout>
              }>
              <Route index element={<RawPage />} />
              <Route path=":id" element={<RawPage />} />
            </Route>

            <Route
              path="weather"
              element={
                <DashboardContentLayout>
                  <Outlet />
                </DashboardContentLayout>
              }>
              <Route index element={<WeatherPage />} />
            </Route>

            <Route
              path="dams"
              element={
                <DashboardContentLayout>
                  <Outlet />
                </DashboardContentLayout>
              }>
              <Route index element={<DamsPage />} />

              <Route path="create" element={<CreateDamPage />} />
              <Route path=":id" element={<EditDamPage />} />
            </Route>

            <Route
              path="users"
              element={
                <DashboardContentLayout>
                  <Outlet />
                </DashboardContentLayout>
              }>
              <Route index element={<UsersPage />} />

              <Route path="create" element={<CreateUserPage />} />
              <Route path=":id" element={<EditUserPage />} />
            </Route>

            <Route
              path="admin-users"
              element={
                <DashboardContentLayout>
                  <Outlet />
                </DashboardContentLayout>
              }>
              <Route index element={<AdminUsersPage />} />

              <Route path="create" element={<CreateAdminUserPage />} />
              <Route path=":id" element={<EditAdminUserPage />} />
            </Route>

            <Route path="*" element={<DashboardContentLayout></DashboardContentLayout>} />
          </Route>

          <Route path="graph" element={<Graph />} />

          <Route path="flow" element={<DeviceFlowClientPage />} />

          <Route path="*" element={<NotFoundPage />} />
        </Route>
      </Routes>
      <Toaster />
      <ToastLimit />
    </>
  );
}

export default App;
