import { Map, TileLayer as TileLayerEntity } from 'leaflet';
import 'leaflet/dist/leaflet.css';
import { useRef } from 'react';
import { MapContainer, TileLayer } from 'react-leaflet';
import { DialogWaterLevel } from 'src/modules/map-dialog/components/DialogWaterLevel.component';
import { CheckBoxHrpnsNdGeoJSON } from 'src/modules/map-geo-jsons/components/CheckBoxHrpnsNdGeoJSON.component';
import { TileLayerControlBellow } from './TileLayerControlBellow.component';
import { TileLayerControlTopRight } from './TileLayerControlTopRight.component';
import { LeafletProps } from './types/leaflet-props.type';

export function Leaflet({ mapContainerProps }: LeafletProps) {
  const baseTileLayer = useRef<TileLayerEntity>(new TileLayerEntity(''));
  const map = useRef<Map>(null);

  return (
    <>
      <DialogWaterLevel />
      <MapContainer attributionControl={false} {...mapContainerProps} ref={map}>
        <TileLayer url="" ref={baseTileLayer} maxNativeZoom={18} />
        <TileLayerControlBellow />
        <CheckBoxHrpnsNdGeoJSON baseTileLayer={baseTileLayer} checked />
        <TileLayerControlTopRight baseTileLayer={baseTileLayer} map={map} />
      </MapContainer>
    </>
  );
}
