import type { GeoJsonObject } from 'geojson';
import { TileLayer as TileLayerEntity } from 'leaflet';
import React, { useEffect, useImperativeHandle, useRef, useState } from 'react';
import { TileLayer } from 'react-leaflet';

export const CheckBoxRiverNetworkComponent = React.forwardRef(
  (
    {
      label = '河川網',
      baseTileLayer,
    }: {
      label?: string;
      baseTileLayer: React.RefObject<TileLayerEntity>;
    },
    ref: React.Ref<{ setGeoData?: (data: GeoJsonObject) => void }>,
  ) => {
    const eInputLabel = useRef<HTMLDivElement>(null);

    const [riverChecked, setRiverChecked] = useState<boolean>(false);
    const eLabel = useRef<HTMLLabelElement>(null);
    useImperativeHandle(ref, () => ({}));

    const changeCheckingStatus = (check: boolean) => {
      if (check) {
        eInputLabel.current?.classList.add('text-white', 'bg-blue-500');
        eInputLabel.current?.classList.remove('text-blue-500');
      } else {
        eInputLabel.current?.classList.add('text-blue-500');
        eInputLabel.current?.classList.remove('text-white', 'bg-blue-500');
      }
    };
    useEffect(() => {
      changeCheckingStatus(riverChecked);
    }, [riverChecked]);

    return (
      <>
        <div className="flex items-center" ref={eInputLabel}>
          <input
            type="checkbox"
            id={label}
            checked={riverChecked}
            onChange={(e) => {
              setRiverChecked(e.target.checked);
            }}
            className="hidden h-4 w-4 rounded border-gray-300 bg-gray-100 text-blue-600 focus:ring-2 focus:ring-blue-500 dark:border-gray-600 dark:bg-gray-700 dark:ring-offset-gray-800 dark:focus:ring-blue-600"
          />
          <label
            htmlFor={label}
            className="flex-1 select-none border-2 border-blue-500 p-2 text-center text-sm font-medium text-inherit md:p-3"
            ref={eLabel}>
            {label}
          </label>
        </div>

        {riverChecked && (
          <TileLayer
            url={'https://www.jma.go.jp/bosai/jmatile/data/map/none/none/none/surf/flood/{z}/{x}/{y}.png'}
            zIndex={500}
            opacity={0.8}
            maxNativeZoom={10}
            eventHandlers={{
              add: () => {
                baseTileLayer.current?.getContainer()?.style?.setProperty('mix-blend-mode', 'multiply');
                baseTileLayer.current?.getContainer()?.style?.setProperty('z-index', '101');
              },
            }}
          />
        )}
      </>
    );
  },
);
