import { forwardRef, useImperativeHandle, useState } from 'react';

export interface ChartMaxWaterlevelRef {
  setValue: (value: string) => void;
}

export const ChartMaxWaterlevel = forwardRef<ChartMaxWaterlevelRef, { defaultValue?: string }>(
  ({ defaultValue = '' }, ref) => {
    const [value, setValue] = useState<string>(defaultValue);

    useImperativeHandle(ref, () => ({
      setValue,
    }));

    return (
      <span className="absolute left-1/2 -translate-x-1/2 max-md:flex max-md:flex-col max-md:items-center max-md:text-sm">
        <span>予測到達時刻: </span>
        <span>{value}</span>
      </span>
    );
  },
);
