import { useAuth } from 'src/lib/auth/use-auth';
import { Leaflet } from '../components/Leaftlet.component';

export function MapPage() {
  const { user } = useAuth();

  return (
    <Leaflet
      mapContainerProps={{
        center: [37.5, 138],
        zoom: 6,
        minZoom: 5,
        style: { height: '100vh' },
      }}
    />
  );
}
