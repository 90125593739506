import { Toast as FlowBiteToast } from 'flowbite-react';
import toast, { Toast as HotToast } from 'react-hot-toast';
import { twMerge } from 'tailwind-merge';
import { Props } from '../type/props.type';

export function toasting({
  iconProps,
  containerProps,
  togglerProps,
  className,
  toastOptions,
  ...props
}: Props<'div'> & {
  iconProps?: Props<'div'>;
  containerProps?: Props<'div'>;
  toastOptions?: Partial<
    Pick<HotToast, 'style' | 'className' | 'id' | 'icon' | 'duration' | 'ariaProps' | 'position' | 'iconTheme'>
  >;
  togglerProps?: Props<'button'>;
}) {
  toast.custom(
    (t) => (
      <FlowBiteToast
        {...containerProps}
        className={twMerge('w-fit border-2 border-solid border-gray-700', containerProps?.className)}>
        {iconProps && (
          <div
            {...iconProps}
            className={twMerge(
              'inline-flex shrink-0 items-center justify-center rounded-lg bg-green-100 text-green-500 dark:bg-green-800 dark:text-green-200',
              iconProps.className,
            )}
          />
        )}
        <div {...props} className={twMerge('mx-3 text-sm font-normal', className)} />
        <FlowBiteToast.Toggle
          {...togglerProps}
          onClick={() => {
            toast.dismiss(t.id);
          }}
        />
      </FlowBiteToast>
    ),
    toastOptions,
  );
}
