import React, { useEffect, useImperativeHandle, useRef, useState } from 'react';
import { twMerge } from 'tailwind-merge';
import { uniqueId } from '../dayjs/unique-id';
import { Props } from '../type/props.type';

export const RadioGroup = React.forwardRef<
  (HTMLInputElement | null)[],
  Props<'input'> & {
    labelProps?: Props<'label'>;
    labels?: React.ReactNode[];
    liProps?: Props<'li'>;
    checkedLabelProps?: Props<'label'>;
    ulProps?: Props<'ul'>;
    changeAfterLoad?: boolean;
  }
>(
  (
    {
      id = uniqueId('RadioButton-'),
      labelProps,
      labels,
      liProps,
      ulProps,
      checkedLabelProps,
      defaultValue,
      changeAfterLoad,
      ...props
    },
    ref,
  ) => {
    const e = useRef<(HTMLInputElement | null)[]>([]);

    const [checkedIndex, setCheckedIndex] = useState<number>(+(defaultValue || 0));

    useEffect(() => {
      changeAfterLoad && e.current[0]?.click();
    }, []);

    useImperativeHandle(ref, () => e.current);

    return (
      <ul
        {...ulProps}
        className={twMerge(
          'w-full font-medium text-gray-900 dark:border-gray-600 dark:bg-gray-700 dark:text-white',
          ulProps?.className,
        )}
        onChange={(ev) => {
          const value = +(ev.target as any).value;
          setCheckedIndex(value);
        }}>
        {labels?.map((label, i) => (
          <li
            key={i}
            {...liProps}
            className={twMerge('w-full border-gray-200 dark:border-gray-600', liProps?.className)}>
            <div className="flex h-full items-center">
              <input
                {...props}
                id={`${id}-input-${i}`}
                type="radio"
                value={i}
                name={id}
                {...(!changeAfterLoad ? { defaultChecked: i === checkedIndex } : {})}
                className={twMerge(
                  'h-4 w-4 border-gray-300 bg-gray-100 text-blue-600 focus:ring-2 focus:ring-blue-500 dark:border-gray-500 dark:bg-gray-600 dark:ring-offset-gray-700 dark:focus:ring-blue-600',
                  props.className,
                )}
                ref={(ref) => (e.current[i] = ref)}
              />
              {checkedIndex === i ? (
                <label
                  {...checkedLabelProps}
                  htmlFor={`${id}-input-${i}`}
                  className={twMerge(
                    'h-full w-full text-gray-900 dark:text-gray-300',
                    labelProps?.className,
                    checkedLabelProps?.className,
                  )}>
                  {label}
                </label>
              ) : (
                <label
                  {...labelProps}
                  htmlFor={`${id}-input-${i}`}
                  className={twMerge('h-full w-full text-gray-900 dark:text-gray-300', labelProps?.className)}>
                  {label}
                </label>
              )}
            </div>
          </li>
        ))}
      </ul>
    );
  },
);
