import dayjs from 'dayjs';
import { useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { Button } from 'src/lib/components/Button.component';
import { Table } from 'src/lib/components/Table.component';
import { toasting } from 'src/lib/components/Toast.component';
import { fdelete, fget } from 'src/lib/fetch';
import { DeviceEntity } from '../../dto/device/device.entity';
import { DeleteDeviceReq } from '../../dto/device/device.req';
import { ResponseListDocuments } from '../../dto/response';

export function DevicesPage() {
  const [devices, setDevices] = useState<ResponseListDocuments<DeviceEntity>>();
  const [statusFilter, setStatusFilter] = useState<string | null>(null);

  const navigate = useNavigate();

  const handleButtonDelete = async (input: DeleteDeviceReq) => {
    const id = input.device.DEVICE_ID;
    try {
      await fdelete<DeleteDeviceReq>(`api/devices/${id}`);

      const data = await fget<any, typeof devices>('api/devices');

      setDevices(data[1]);
    } catch (error) {
      console.error('Error deleting device:', error);
      toasting({ children: 'デバイスの削除に失敗しました。', containerProps: { className: 'border-red-600' } });
    }
  };
  const fetchData = async (args?: any) => {
    try {
      let hasParam = false;
      let endpoint = 'api/devices';
      if (args?.['order-by']) {
        endpoint = `${endpoint}?order-by=${args['order-by']}`;
        hasParam = true;
      }

      if (args?.['status']) {
        endpoint += hasParam ? `&status=${args?.['status']}` : `?status=${args?.['status']}`;
      }

      const data = await fget<any, typeof devices>(endpoint);

      setDevices(data[1]);
    } catch (error) {
      console.error('Error fetching data:', error);
      toasting({ children: 'デバイスの取得に失敗しました。', containerProps: { className: 'border-red-600' } });
    }
  };

  useEffect(() => {
    fetchData({ 'order-by': 'AREA_NAME', 'status': 'ACTIVE' });
  }, []);

  const [orderByState, setOrderByState] = useState<any>({
    DEVICE_NAME: {
      '-': false,
      '+': false,
    },
    DEVICE_NUMBER: {
      '-': false,
      '+': false,
    },
    DEVICE_TYPE: {
      '-': false,
      '+': false,
    },
    AREA_NAME: {
      '-': false,
      '+': true,
    },
    RIVER_NAME: {
      '-': false,
      '+': false,
    },
    INSTALL_DATE: {
      '-': false,
      '+': false,
    },
  });

  const orderBy = async (name: string) => {
    if (name.startsWith('-')) {
      const key = name.replace('-', '');
      const keys = Object.keys(orderByState);
      setOrderByState({
        ...keys.reduce((acc, key) => ({ ...acc, [key]: { '-': false, '+': false } }), {}), // reset all
        [key]: {
          '-': true,
          '+': false,
        },
      });
    } else {
      const key = name;
      const keys = Object.keys(orderByState);
      setOrderByState({
        ...keys.reduce((acc, key) => ({ ...acc, [key]: { '-': false, '+': false } }), {}), // reset all
        [key]: {
          '-': false,
          '+': true,
        },
      });
    }

    return await fetchData({ 'order-by': name , 'status': statusFilter });
  };

  return (
    <div className="flex flex-row flex-wrap px-4 pt-12 md:px-12 md:py-8">
      <h1 className="mb-2 whitespace-nowrap  text-2xl">デバイス管理</h1>
      <span className="w-full border-b border-slate-800"></span>

      <div className="mt-1 flex basis-full flex-row-reverse gap-2">
        <Button
          className=" rounded-md bg-black text-white"
          onClick={(e) => {
            navigate('create');
          }}>
          <svg className="mr-2 h-4 w-4 text-white" fill="none" viewBox="0 0 24 24" stroke="currentColor">
            <path strokeLinecap="round" strokeLinejoin="round" strokeWidth="2" d="M12 4v16m8-8H4"/>
          </svg>
          新しいデバイスを追加
        </Button>
        <div className="filter-container">
          <select
            value={statusFilter || ''}
            onChange={async (e) => {
              setStatusFilter(e.target.value);
              await fetchData({'status': e.target.value});
            }}
          >
            <option value="ACTIVE">アクティブ</option>
            <option value="INACTIVE">非アクティブ</option>
          </select>
        </div>


      </div>

      <div className="relative mt-4 block w-full basis-full overflow-x-auto rounded-md border p-4 shadow-md">
        <Table
          hideCheckbox
          headCellData={[
            <div className="flex">
              <div className="mt-4">デバイス名</div>
              <span className="ml-1 cursor-pointer">
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  width="24"
                  height="24"
                  viewBox="0 0 24 5"
                  onClick={() => orderBy('DEVICE_NAME')}>
                  <path fill={orderByState['DEVICE_NAME']['+'] ? 'white' : 'grey'} d="M12 2l-8 8h16z" />
                </svg>
                <svg
                  transform="scale(1 -1)"
                  xmlns="http://www.w3.org/2000/svg"
                  width="24"
                  height="24"
                  viewBox="0 0 24 5"
                  onClick={() => orderBy('-DEVICE_NAME')}>
                  <path fill={orderByState['DEVICE_NAME']['-'] ? 'white' : 'grey'} d="M12 2l-8 8h16z" />
                </svg>
              </span>
            </div>,

            <div className="flex">
              <div className="mt-4">デバイス番号</div>
              <span className="ml-1 cursor-pointer">
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  width="24"
                  height="24"
                  viewBox="0 0 24 5"
                  onClick={() => orderBy('DEVICE_NUMBER')}>
                  <path fill={orderByState['DEVICE_NUMBER']['+'] ? 'white' : 'grey'} d="M12 2l-8 8h16z" />
                </svg>
                <svg
                  transform="scale(1 -1)"
                  xmlns="http://www.w3.org/2000/svg"
                  width="24"
                  height="24"
                  viewBox="0 0 24 5"
                  onClick={() => orderBy('-DEVICE_NUMBER')}>
                  <path fill={orderByState['DEVICE_NUMBER']['-'] ? 'white' : 'grey'} d="M12 2l-8 8h16z" />
                </svg>
              </span>
            </div>,

            <div className="flex">
              <div className="mt-4">種類</div>
              <span className="ml-1 cursor-pointer">
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  width="24"
                  height="24"
                  viewBox="0 0 24 5"
                  onClick={() => orderBy('DEVICE_TYPE')}>
                  <path fill={orderByState['DEVICE_TYPE']['+'] ? 'white' : 'grey'} d="M12 2l-8 8h16z" />
                </svg>
                <svg
                  transform="scale(1 -1)"
                  xmlns="http://www.w3.org/2000/svg"
                  width="24"
                  height="24"
                  viewBox="0 0 24 5"
                  onClick={() => orderBy('-DEVICE_TYPE')}>
                  <path fill={orderByState['DEVICE_TYPE']['-'] ? 'white' : 'grey'} d="M12 2l-8 8h16z" />
                </svg>
              </span>
            </div>,

            <div className="flex">
              <div className="mt-4">地域名</div>
              <span className="ml-1 cursor-pointer">
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  width="24"
                  height="24"
                  viewBox="0 0 24 5"
                  onClick={() => orderBy('AREA_NAME')}>
                  <path fill={orderByState['AREA_NAME']['+'] ? 'white' : 'grey'} d="M12 2l-8 8h16z" />
                </svg>
                <svg
                  transform="scale(1 -1)"
                  xmlns="http://www.w3.org/2000/svg"
                  width="24"
                  height="24"
                  viewBox="0 0 24 5"
                  onClick={() => orderBy('-AREA_NAME')}>
                  <path fill={orderByState['AREA_NAME']['-'] ? 'white' : 'grey'} d="M12 2l-8 8h16z" />
                </svg>
              </span>
            </div>,

            <div className="flex">
              <div className="mt-4">川の名前</div>
              <span className="ml-1 cursor-pointer">
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  width="24"
                  height="24"
                  viewBox="0 0 24 5"
                  onClick={() => orderBy('RIVER_NAME')}>
                  <path fill={orderByState['RIVER_NAME']['+'] ? 'white' : 'grey'} d="M12 2l-8 8h16z" />
                </svg>
                <svg
                  transform="scale(1 -1)"
                  xmlns="http://www.w3.org/2000/svg"
                  width="24"
                  height="24"
                  viewBox="0 0 24 5"
                  onClick={() => orderBy('-RIVER_NAME')}>
                  <path fill={orderByState['RIVER_NAME']['-'] ? 'white' : 'grey'} d="M12 2l-8 8h16z" />
                </svg>
              </span>
            </div>,

            <div className="flex">
              <div className="mt-4">設置日</div>
              <span className="ml-1 cursor-pointer">
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  width="24"
                  height="24"
                  viewBox="0 0 24 5"
                  onClick={() => orderBy('INSTALL_DATE')}>
                  <path fill={orderByState['INSTALL_DATE']['+'] ? 'white' : 'grey'} d="M12 2l-8 8h16z" />
                </svg>
                <svg
                  transform="scale(1 -1)"
                  xmlns="http://www.w3.org/2000/svg"
                  width="24"
                  height="24"
                  viewBox="0 0 24 5"
                  onClick={() => orderBy('-INSTALL_DATE')}>
                  <path fill={orderByState['INSTALL_DATE']['-'] ? 'white' : 'grey'} d="M12 2l-8 8h16z" />
                </svg>
              </span>
            </div>,
            '',
            '',
          ]}
          cellData={devices?.Items?.map((device, i) => [
            device.DEVICE_NAME?.S,
            device.DEVICE_NUMBER?.S,
            device.DEVICE_TYPE?.S,
            device.AREA_NAME?.S,
            device.RIVER_NAME?.S,
            device.INSTALL_DATE?.S ? dayjs.unix(+device.INSTALL_DATE?.S).format('YYYY/MM/DD HH:mm') : '',
            <a
              href={`/admin/raw/${device.DEVICE_ID.S}`}
              target="_blank"
              onClick={(e) => {
                e.stopPropagation();
              }}
              className="whitespace-nowrap font-medium text-blue-600 hover:underline dark:text-blue-500">
              データ確認
            </a>,
            <a
              href="#"
              className="whitespace-nowrap font-medium text-red-500 hover:underline dark:text-blue-500"
              onClick={(e) => {
                e.stopPropagation();

                const isConfirmed = window.confirm('本当に削除しますか？');
                if (!isConfirmed) return;

                handleButtonDelete({
                  device: {
                    DEVICE_ID: device.DEVICE_ID.S,
                    DEVICE_NAME: device.DEVICE_NAME.S,
                  },
                });
              }}>
              削除
            </a>,
          ])}
          rowProps={{
            onClick: (e) => {
              navigate(devices?.Items?.at(+(e.currentTarget.id.split('-').at(-1) || 0))?.DEVICE_ID.S || '');
            },
            className: 'cursor-pointer',
          }}
        />
      </div>
    </div>
  );
}
