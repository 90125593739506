import { useEffect, useRef, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { Button } from 'src/lib/components/Button.component';
import { Table, TableRefObject } from 'src/lib/components/Table.component';
import { toasting } from 'src/lib/components/Toast.component';
import { fdelete, fget } from 'src/lib/fetch';
import { ResponseListDocuments } from '../../dto/response';
import { RiverEntity } from '../../dto/river/river.entity';
import { DeleteRiverReq } from '../../dto/river/river.req';

export function RiversPage() {
  const eTable = useRef<TableRefObject>({} as TableRefObject);

  const [rivers, setRivers] = useState<ResponseListDocuments<RiverEntity>>();

  const navigate = useNavigate();

  const handleButtonDelete = async (input: DeleteRiverReq) => {
    const id = input.river.RIVER_ID;
    try {
      await fdelete<DeleteRiverReq>(`api/rivers/${id}`);

      const data = await fget<unknown, typeof rivers>('api/rivers');

      setRivers(data[1]);
    } catch (error) {
      console.error('Error deleting river:', error);
      toasting({ children: '河川座標データの削除に失敗しました。', containerProps: { className: 'border-red-600' } });
    }
  };

  useEffect(() => {
    const fetchData = async () => {
      try {
        const data = await fget<unknown, typeof rivers>('api/rivers');

        setRivers(data[1]);
      } catch (error) {
        console.error('Error fetching data:', error);
        toasting({ children: '河川座標データの取得に失敗しました。', containerProps: { className: 'border-red-600' } });
      }
    };

    fetchData();
  }, []);

  return (
    <div className="flex flex-row flex-wrap px-4 pt-12 md:px-12 md:py-8">
      <h1 className="mb-2 whitespace-nowrap  text-2xl">河川座標データ管理</h1>
      <span className="w-full border-b border-slate-800"></span>

      <Button
        className="ml-auto mt-4 bg-black text-white"
        onClick={(e) => {
          navigate('create');
        }}>
        <svg className="mr-2 h-4 w-4 text-white" fill="none" viewBox="0 0 24 24" stroke="currentColor">
          <path strokeLinecap="round" strokeLinejoin="round" strokeWidth="2" d="M12 4v16m8-8H4" />
        </svg>
        新しい河川座標データを追加
      </Button>

      <div className="relative mt-4 block w-full basis-full overflow-x-auto rounded-md border p-4 shadow-md">
        <Table
          hideCheckbox
          headCellData={['ID', '川の名前', '地域名', '']}
          ref={eTable}
          cellData={rivers?.Items?.map((river, i) => [
            river.RIVER_ID.S,
            river.RIVER_NAME.S,
            river.REGION_NAME.S,
            <a
              href="#"
              className="whitespace-nowrap font-medium text-red-500 hover:underline dark:text-blue-500"
              onClick={(e) => {
                e.stopPropagation();

                handleButtonDelete({
                  river: {
                    RIVER_ID: river.RIVER_ID.S,
                    RIVER_NAME: river.RIVER_NAME.S,
                  },
                });
              }}>
              削除
            </a>,
          ])}
          rowProps={{
            onClick: (e) => {
              navigate(rivers?.Items?.at(+(e.currentTarget.id.split('-').at(-1) || 0))?.RIVER_ID.S || '');
            },
            className: 'cursor-pointer',
          }}
        />
      </div>
    </div>
  );
}
