import type { GeoJsonObject } from 'geojson';
import { GeoJSON as GeoJSONEntity, TileLayer as TileLayerEntity } from 'leaflet';
import React, { useEffect, useImperativeHandle, useRef, useState } from 'react';
import { GeoJSON } from 'react-leaflet';
import { useSelector } from 'react-redux';
import { toasting } from 'src/lib/components/Toast.component';
import { RootState } from 'src/redux/store';

export const CheckBoxHrpnsNdGeoJSON = React.forwardRef(
  (
    {
      label = 'CheckBoxHrpnsNdGeoJSON',
      checked,
    }: {
      label?: string;
      baseTileLayer: React.RefObject<TileLayerEntity>;
      checked?: boolean;
    },
    ref: React.Ref<{ setGeoData: (data: GeoJsonObject) => void }>,
  ) => {
    // Handle checkbox
    const eLabel = useRef<HTMLLabelElement>(null);
    const eInputLabel = useRef<HTMLDivElement>(null);
    const eGeoJSON = useRef<GeoJSONEntity>(null);

    const [inputChecked, setInputChecked] = useState<boolean>(!!checked);

    function changeCheckingStatus(check: boolean) {
      if (check) {
        eInputLabel.current?.classList.add('text-white', 'bg-blue-500');
        eInputLabel.current?.classList.remove('text-blue-500');
      } else {
        eInputLabel.current?.classList.add('text-blue-500');
        eInputLabel.current?.classList.remove('text-white', 'bg-blue-500');
      }
    }

    useEffect(() => {
      changeCheckingStatus(inputChecked);
    }, [inputChecked]);

    // Handle GeoJSON
    const geoDataUrl = useSelector((state: RootState) => state.precipitationReducer.urlHrpnsNd[0]);

    useEffect(() => {
      async function fetchData() {
        if (!geoDataUrl) return;
        try {
          const response = await fetch(geoDataUrl);
          const data = await response.json();
          setGeoData(data);
        } catch (error) {
          console.error('Fetch error:', error);
          toasting({ children: 'データの取得に失敗しました。', containerProps: { className: 'border-red-600' } });
        }
      }

      fetchData();
    }, [geoDataUrl, inputChecked]);

    // Handle ref
    useImperativeHandle(ref, () => ({ setGeoData }));

    function setGeoData(data: GeoJsonObject) {
      eGeoJSON.current?.clearLayers();
      eGeoJSON.current?.addData(data);
    }

    return (
      <>
        <div className="hidden items-center" ref={eInputLabel}>
          <input
            id={label}
            type="checkbox"
            checked={inputChecked}
            onChange={(e) => {
              setInputChecked(e.target.checked);
            }}
            className="hidden h-4 w-4 rounded border-gray-300 bg-gray-100 text-blue-600 focus:ring-2 focus:ring-blue-500 dark:border-gray-600 dark:bg-gray-700 dark:ring-offset-gray-800 dark:focus:ring-blue-600"
          />
          <label
            htmlFor={label}
            className="flex-1 select-none border-2 border-blue-500 p-2 text-center text-sm font-medium text-inherit md:p-3"
            ref={eLabel}>
            {label}
          </label>
        </div>

        {inputChecked && (
          <GeoJSON
            ref={eGeoJSON}
            data={{ type: 'FeatureCollection', features: eGeoJSON.current?.feature || [] } as GeoJsonObject}
          />
        )}
      </>
    );
  },
);
