export const dataVelocity = {
  labels: ['Jan', 'Feb', 'Mar', 'Apr', 'May', 'Jun', 'Jul', 'Aug', 'Sep', 'Oct', 'Nov', 'Dec'],
  datasets: [
    {
      label: '流速',
      data: [] as string[] | number[] | null[],
      fill: true,
      backgroundColor: 'rgba(75,192,192,0.2)',
      borderColor: 'green',
    },
  ],
};

export const configVelocity = {
  type: 'line',
  data: dataVelocity,
  options: {
    title: {
      display: false,
      text: '',
    },
    scales: {
      xAxes: [
        {
          scaleLabel: {
            display: true,
            labelString: '年',
          },
          ticks: {
            max: 100,
            min: 0,
          },
        },
      ],
      yAxes: [
        {
          scaleLabel: {
            display: true,
            labelString: '水位 (m/s)',
            fontColor: 'green'
          },
          ticks: {
            max: 100,
            min: 0,
            fontColor: 'green'
          },
        },
      ],
    },
    tooltips: {
      mode: 'nearest',
      intersect: false,
    },
    hover: {
      mode: 'nearest',
      intersect: false,
    },
  },
};
