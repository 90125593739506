import {forwardRef, useEffect, useImperativeHandle, useRef, useState} from 'react';
import {AiOutlineLeft, AiOutlineRight} from 'react-icons/ai';

export interface GraphControlsProps {
    defaultValue?: number,
    defaultLabel?: string,
    onChange?: (value: number) => void,
    onChangeLabel?: (value: string) => void,
    max?: number,
    min?: number,
    labelText?: string
}

export interface GraphControlsRef {
    setNewValue: (value: number) => void;
    setLabel: (label: string) => void;
}

export const GraphControls = forwardRef<GraphControlsRef, GraphControlsProps>(
    ({defaultValue = 0, onChange, max = 100, min = 0, defaultLabel = '', onChangeLabel, labelText}, ref) => {
        const [value, setValue] = useState<number>(defaultValue);
        const [label, setLabel] = useState<string>(defaultLabel);
        const isChangeRef = useRef(false);

        useEffect(() => {
            if (isChangeRef.current) {
                onChange?.(value);
                isChangeRef.current = false;
            }
        }, [value]);

        useImperativeHandle(ref, () => ({
            setNewValue(value) {
                setValue(value);
            },
            setLabel(value) {
                setLabel(value);
            },
        }));

        return (
            <div>
                <p className="mt-2">{labelText}</p>
                <div
                    className="flex items-center gap-4 pt-2 max-sm:grid max-sm:grid-cols-1 max-sm:gap-1 max-sm:align-middle">
                    <input
                        type="time"
                        id="time"
                        className="h-full w-32 rounded-md border border-gray-300 bg-gray-50 p-1.5 text-sm leading-none text-gray-900 focus:border-blue-500 focus:ring-blue-500 dark:border-gray-600 dark:bg-gray-700 dark:text-white dark:placeholder-gray-400 dark:focus:border-blue-500 dark:focus:ring-blue-500"
                        min="09:00"
                        max="17:00"
                        value={label}
                        onChange={(e) => {
                            setLabel(e.target.value);
                            onChangeLabel?.(e.target.value);
                        }}
                        required
                    />
                    <div className="flex basis-full items-center gap-1">
                        <AiOutlineLeft
                            size={28}
                            className="cursor-pointer p-1"
                            onClick={() => {
                                setValue((prev) => (prev - 5 < 0 ? 0 : prev - 5));
                                isChangeRef.current = true;
                            }}
                        />
                        <input
                            type="range"
                            min={min}
                            max={max}
                            step={1}
                            value={value}
                            className="range-sm h-0.5 basis-full cursor-pointer appearance-none rounded-lg bg-gray-200 dark:bg-gray-700"
                            onChange={(e) => {
                                setValue(e.target.valueAsNumber);
                                isChangeRef.current = true;
                            }}
                        />

                        <AiOutlineRight
                            size={28}
                            className="cursor-pointer p-1"
                            onClick={() => {
                                setValue((prev) => (prev + 5 > max ? max : prev + 5));
                                isChangeRef.current = true;
                            }}
                        />
                    </div>
                </div>
            </div>
        );
    },
);
