import { Label, LabelProps, Textarea } from 'flowbite-react';
import React, { useEffect, useImperativeHandle, useRef } from 'react';
import { twMerge } from 'tailwind-merge';
import { uniqueId } from '../dayjs/unique-id';
import { Props } from '../type/props.type';

export const TextArea = React.forwardRef<
  HTMLTextAreaElement,
  Props<'textarea'> & {
    labelProps?: LabelProps;
    containerProps?: Props<'div'>;
    toolBarProps?: Props<'div'>;
    containerToolBarAndTextAreaProps?: Props<'div'>;
  }
>(
  (
    {
      id = uniqueId('TextArea-'),
      labelProps,
      containerProps,
      toolBarProps,
      containerToolBarAndTextAreaProps,
      className = '',
      children,
      ...props
    },
    ref,
  ) => {
    const e = useRef<HTMLTextAreaElement>({} as HTMLTextAreaElement);

    useEffect(() => {
      if (e.current)
        e.current.className = twMerge(
          e.current.className,
          'custom-scrollbar-1 rounded-none rounded-b-md disabled:bg-transparent',
          className,
        );
    }, []);

    useImperativeHandle(ref, () => e.current);

    return (
      <div {...containerProps}>
        <div className="mb-2 block">
          <Label {...labelProps} htmlFor={id} />
        </div>
        <div {...containerToolBarAndTextAreaProps} className={twMerge('mb-4 w-full rounded-md border-gray-300')}>
          {children && (
            <div
              {...toolBarProps}
              className={twMerge(
                'flex items-center justify-between rounded-t-md border-x border-t border-gray-300  bg-gray-50 px-3 py-2 text-sm font-light',
                toolBarProps?.className,
              )}>
              {children}
            </div>
          )}
          <Textarea rows={8} {...props} ref={e} />
        </div>
      </div>
    );
  },
);
