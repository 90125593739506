import { Icon, LatLng, Marker as MarkerEntity } from 'leaflet';
import React, { useEffect, useImperativeHandle, useRef, useState } from 'react';
import { Marker, MarkerProps, useMap, useMapEvents } from 'react-leaflet';
import { environment } from 'src/environments/environment';

export const MarkerMapControl = React.forwardRef<
  MarkerEntity,
  Partial<
    MarkerProps & {
      onClickMap: (e: { latlng: LatLng }) => boolean;
      position?: LatLng;
      allowUseEffect?: boolean;
      hideIcon?: boolean;
    }
  >
>(({ onClickMap, allowUseEffect = true, hideIcon = false, ...props }, ref) => {
  const e = useRef<MarkerEntity>({} as MarkerEntity);

  const [markerPos, setMarkerPos] = useState<LatLng>(props.position || useMap().getCenter());

  useMapEvents({
    click: (e) => {
      let isClickable = false;

      if (onClickMap) isClickable = onClickMap(e);

      isClickable && setMarkerPos(e.latlng);

      return false;
    },
  });

  allowUseEffect &&
    useEffect(() => {
      if (onClickMap && e.current) onClickMap({ latlng: markerPos });
    }, []);

  useImperativeHandle(ref, () => e.current);

  return markerPos && !hideIcon ? (
    <Marker
      {...props}
      position={markerPos}
      ref={e}
      icon={
        props.icon ||
        new Icon({
          iconUrl: `${environment.asset}assets/marker-icon.png`,
          shadowUrl: `${environment.asset}assets/marker-shadow.png`,
          iconSize: [25, 41],
          iconAnchor: [12, 41],
        })
      }
    />
  ) : (
    <></>
  );
});
