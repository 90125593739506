import { Icon, LatLng, Map, Marker as MarkerEntity, TileLayer as TileLayerEntity } from 'leaflet';
import { useEffect, useRef, useState } from 'react';
import { MapContainer, TileLayer } from 'react-leaflet';
import Control from 'react-leaflet-custom-control';
import { Link, useNavigate } from 'react-router-dom';
import { Button } from 'src/lib/components/Button.component';
import { Select } from 'src/lib/components/Select.component';
import { TextInput } from 'src/lib/components/TextInput.component';
import { fget, fpost } from 'src/lib/fetch';
import { SelectBoxBaseLayer } from 'src/modules/map-layers/components/SelectBoxBaseLayer.component';
import { MarkerMapControl } from 'src/modules/map-markers/components/MarkerMapControl.component';
import { BASE_MAP_URL } from 'src/redux/slices/baseLayer.slice';
import { AreaEntity } from '../../dto/area/area.entity';
import { CreateDamReq } from '../../dto/dam/dam.req';
import { ResponseListDocuments } from '../../dto/response';
import { RiverEntity } from '../../dto/river/river.entity';

export function CreateDamPage() {
  const eForm = useRef<HTMLFormElement>(null);

  const eTextNumber = useRef<HTMLInputElement>(null);
  const eSelectAreaName = useRef<HTMLSelectElement>(null);
  const eTextCoordinateX = useRef<HTMLInputElement>(null);
  const eTextCoordinateY = useRef<HTMLInputElement>(null);
  const eSelectRiver = useRef<HTMLSelectElement>(null);

  const baseTileLayer = useRef<TileLayerEntity>(new TileLayerEntity(''));
  const map = useRef<Map>(null);
  const eMarker = useRef<MarkerEntity>(null);

  const eButtonSubmit = useRef<HTMLButtonElement>(null);

  const [data, setData] = useState<{
    rivers?: ResponseListDocuments<RiverEntity>;
    areas?: ResponseListDocuments<AreaEntity>;
  }>();

  const navigate = useNavigate();

  const handleButtonSubmit = async (e: React.MouseEvent<HTMLButtonElement, MouseEvent>) => {
    e.preventDefault();

    try {
      if (!eForm.current?.checkValidity()) {
        return eForm.current?.reportValidity();
      }

      if (eButtonSubmit.current) {
        eButtonSubmit.current.disabled = true;
        eButtonSubmit.current.classList.add('bg-gray-400');

        await fpost<CreateDamReq>('api/dams', {
          method: 'POST',
          body: {
            dam: {
              DAM_AREA: eSelectAreaName.current?.value || '',
              DAM_COORDINATE: `${eTextCoordinateX.current?.value || ''},${eTextCoordinateY.current?.value || ''}`,
              DAM_NUMBER: eTextNumber.current?.value || '',
              RIVER_NAME: eSelectRiver.current?.value || '',
            },
          },
        });

        if (eButtonSubmit.current) {
          eButtonSubmit.current.classList.remove('bg-gray-400');
          eButtonSubmit.current.disabled = false;
        }

        navigate('..');
      }
    } catch (error) {
      console.error('An error occurred:', error);
    }
  };

  useEffect(() => {
    const fetchData = async () => {
      try {
        const [_, areaResponse] = await fget<unknown, ResponseListDocuments<AreaEntity>>('api/areas');

        if (areaResponse?.Items?.[0]?.AREA_NAME?.S) {
          const AREA_NAME = areaResponse.Items[0].AREA_NAME.S;
          const [_, riverResponse] = await fget(`api/riverByArea?AREA_NAME=${AREA_NAME}`);

          setData({ areas: areaResponse, rivers: riverResponse });
        }
      } catch (error) {
        console.error('An error occurred:', error);
      }
    };

    fetchData();
  }, []);

  return (
    <form className="flex flex-row flex-wrap px-4 pt-12 md:px-12 md:py-8" ref={eForm}>
      <h1 className="mb-2 whitespace-nowrap text-2xl">デバイス編集</h1>
      <span className="w-full border-b border-slate-800"></span>

      <div className="mt-8 flex flex-nowrap gap-8">
        <div className="flex w-3/4 flex-row flex-wrap gap-12">
          <TextInput
            labelProps={{
              children: '番号',
            }}
            type="text"
            containerProps={{
              className: 'w-1/3 basis-full',
              style: {
                minWidth: '24rem',
              },
            }}
            required
            ref={eTextNumber}
          />

          <Select
            defaultValue={data?.areas?.Items?.[0]?.AREA_NAME.S}
            labelProps={{ value: '地域名' }}
            containerProps={{
              className: 'w-1/3 basis-full',
              style: {
                minWidth: '24rem',
              },
            }}
            required
            onChange={(e) => {
              fget(`api/riverByArea?AREA_NAME=${e.target.value}`, {}).then((river) => {
                setData((prev) => ({ ...prev, rivers: river[1] }));
              });
            }}
            ref={eSelectAreaName}>
            {data?.areas?.Items?.map((area, i) => (
              <option key={`Option-` + i} value={area.AREA_NAME.S}>
                {area.AREA_NAME.S}
              </option>
            ))}
          </Select>

          <Select
            defaultValue={data?.rivers?.Items?.[0]?.RIVER_NAME.S}
            labelProps={{ value: '川の名前' }}
            containerProps={{
              className: 'w-1/3 basis-full',
              style: {
                minWidth: '24rem',
              },
            }}
            required
            ref={eSelectRiver}>
            {data?.rivers?.Items?.map((river, i) => (
              <option key={`Option-` + i} value={river.RIVER_NAME.S}>
                {river.RIVER_NAME.S}
              </option>
            ))}
          </Select>

          <div className="flex basis-full flex-col">
            <label className="mb-2 text-sm font-normal text-gray-900 dark:text-white">設置座標</label>
            <div className="flex flex-row flex-wrap justify-between gap-4">
              <TextInput
                labelProps={{
                  className: 'mb-0 mr-2',
                  children: 'X',
                }}
                type="text"
                containerProps={{
                  className: 'flex flex-row items-center',
                  style: {
                    minWidth: '12rem',
                  },
                }}
                required
                onChange={(e) => {
                  if (eTextCoordinateY.current?.value)
                    eMarker.current?.setLatLng(new LatLng(+e.target.value, +eTextCoordinateY.current.value));
                }}
                ref={eTextCoordinateX}
              />

              <TextInput
                labelProps={{
                  className: 'mb-0 mr-2',
                  children: 'Y',
                }}
                type="text"
                containerProps={{
                  className: 'flex flex-row items-center',
                  style: {
                    minWidth: '12rem',
                  },
                }}
                required
                onChange={(e) => {
                  if (eTextCoordinateX.current?.value)
                    eMarker.current?.setLatLng(new LatLng(+e.target.value, +eTextCoordinateX.current.value));
                }}
                ref={eTextCoordinateY}
              />
            </div>
          </div>

          <Button
            className="basis-full justify-center bg-black text-xs text-white"
            style={{
              minWidth: '256px',
            }}
            type="submit"
            onClick={handleButtonSubmit}
            ref={eButtonSubmit}>
            追加
          </Button>
          <Link
            className={'mx-auto basis-full rounded-md border border-gray-600  p-2.5 text-center text-xs'}
            to=".."
            style={{
              minWidth: '256px',
            }}>
            キャンセル
          </Link>
        </div>

        <MapContainer
          attributionControl={false}
          minZoom={5}
          zoom={9}
          center={[33.9195, 133.1811]}
          style={{
            height: '100%',
            minHeight: '60vh',
            width: '20vw',
            border: '0.25rem solid',
            flexBasis: '100%',
          }}
          ref={map}>
          <TileLayer url={decodeURI(BASE_MAP_URL.std.href)} ref={baseTileLayer} maxNativeZoom={18} />
          <Control
            position="topright"
            container={{
              className: 'flex bg-none flex-col',
            }}>
            <SelectBoxBaseLayer baseTileLayer={baseTileLayer} />
          </Control>

          <MarkerMapControl
            ref={eMarker}
            onClickMap={(e) => {
              eTextCoordinateX.current &&
                eTextCoordinateY.current &&
                (eTextCoordinateX.current.value = `${e.latlng.lat}`) &&
                (eTextCoordinateY.current.value = `${e.latlng.lng}`);

              return true;
            }}
            icon={
              new Icon({
                iconUrl: `/assets/dam.png`,
                iconSize: [29, 33],
                iconAnchor: [13, 37],
              })
            }
          />
        </MapContainer>
      </div>
    </form>
  );
}
