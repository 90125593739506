import { useEffect, useRef, useState } from 'react';
import { Link, useNavigate } from 'react-router-dom';
import Select from 'react-select';
import { Button } from 'src/lib/components/Button.component';
import { TextInput } from 'src/lib/components/TextInput.component';
import { fget, fpost } from 'src/lib/fetch';
import locale from 'src/locales/ja.json';
import { CreateUserReq } from '../../dto/user/user.req';

export function CreateUserPage() {
  const eForm = useRef<HTMLFormElement>(null);

  const eTextUsername = useRef<HTMLInputElement>(null);
  const eTextEmail = useRef<HTMLInputElement>(null);
  const eTextPassword = useRef<HTMLInputElement>(null);
  const eTextRePassword = useRef<HTMLInputElement>(null);

  const eButtonSubmit = useRef<HTMLButtonElement>(null);
  const eTextInputKagawaEditable = useRef<HTMLInputElement>(null);

  const navigate = useNavigate();

  const [selectedAreas, setSelectedAreas] = useState<any>(new Set());

  const [showEditWaterLevelCheckbox, setShowEditWaterLevelCheckbox] = useState<boolean>(false);
  const onSelectAreaChange = (e: any) => {
    setSelectedAreas(new Set(e.map((item: any) => item.value)));
  };

  useEffect(() => {
    if (!selectedAreas) return;
    if (selectedAreas.has('香川用水')) {
      setShowEditWaterLevelCheckbox(true);
    } else {
      setShowEditWaterLevelCheckbox(false);
    }
  }, [selectedAreas]);

  const [areas, setAreas] = useState<any[]>([]);

  const handleButtonSubmit = async (e: React.MouseEvent<HTMLButtonElement, MouseEvent>) => {
    e.preventDefault();
    if (!eForm.current?.checkValidity()) return eForm.current?.reportValidity();

    await fpost<CreateUserReq>('api/users', {
      body: {
        user: {
          USER_NAME: eTextUsername.current?.value || '',
          USER_MAIL: eTextEmail.current?.value || '',
          USER_PASS: eTextPassword.current?.value || '',
          USER_AREAS: Array.from(selectedAreas),
          KAGAWA_EDITABLE: eTextInputKagawaEditable.current?.checked ? '1' : '0',
          USER_ROLE: 'user',
        },
      },
    });
    navigate('..');
  };

  useEffect(() => {
    const fetchData = async () => {
      try {
        const [_, data] = await fget<any>('api/areas');
        if (data) {
          const items = data?.Items || [];
          setAreas(items);
        }
      } catch (error) {
        console.error('An error occurred:', error);
      }
    };

    fetchData();
  }, []);

  return (
    <form className="flex flex-row flex-wrap px-4 pt-12 md:px-12 md:py-8" ref={eForm}>
      <h1 className="mb-2 whitespace-nowrap text-2xl">ユーザー追加</h1>
      <span className="w-full border-b border-slate-800"></span>

      <div className="flex w-full flex-row flex-wrap xl:w-1/3">
        <TextInput
          labelProps={{
            children: 'ユーザー名',
          }}
          type="text"
          containerProps={{
            className: 'mt-4 w-1/3 basis-full',
            style: {
              minWidth: '24rem',
            },
          }}
          required
          ref={eTextUsername}
        />

        <TextInput
          labelProps={{
            children: 'メールアドレス',
          }}
          type="email"
          containerProps={{
            className: 'mt-4 w-1/3 basis-full',
            style: {
              minWidth: '24rem',
            },
          }}
          required
          ref={eTextEmail}
        />

        <TextInput
          labelProps={{
            children: 'パスワード',
          }}
          type="password"
          containerProps={{
            className: 'mt-4 w-1/3 basis-full',
            style: {
              minWidth: '24rem',
            },
          }}
          onChange={(e) => {
            if (e.target.value !== eTextPassword.current?.value) {
              e.target.setCustomValidity('Password and re-password does not match.');
            } else {
              eTextPassword.current?.setCustomValidity('');
              eTextRePassword.current?.setCustomValidity('');
            }
          }}
          ref={eTextPassword}
        />

        <TextInput
          labelProps={{
            children: '再パスワード',
          }}
          type="password"
          containerProps={{
            className: 'mt-4 w-1/3 basis-full',
            style: {
              minWidth: '24rem',
            },
          }}
          onChange={(e) => {
            if (e.target.value !== eTextPassword.current?.value) {
              e.target.setCustomValidity('Password and re-password does not match.');
            } else {
              eTextPassword.current?.setCustomValidity('');
              eTextRePassword.current?.setCustomValidity('');
            }
          }}
          ref={eTextRePassword}
        />

        {showEditWaterLevelCheckbox && (
          <div className="mt-4 flex items-center gap-5">
            <p className="text-sm text-[#000000]">Can edit Kagawa predicted</p>
            <input type="checkbox" ref={eTextInputKagawaEditable} />
          </div>
        )}

        <label className="mt-4 w-1/3 basis-full">
          <span className="text-sm text-[#000000]">{locale['area']['ja']}</span>
        </label>
        <Select
          isMulti
          name="areas"
          options={areas.map((area) => {
            return {
              label: area?.AREA_NAME?.S,
              value: area?.AREA_NAME?.S,
            };
          })}
          className="mt-4 w-full"
          onChange={onSelectAreaChange}
        />

        <div
          className="mt-8 flex w-1/3 basis-full flex-col items-center"
          style={{
            minWidth: '24rem',
          }}>
          <Button
            className="w-1/2 basis-full justify-center rounded-md bg-black text-xs text-white"
            style={{
              minWidth: '256px',
            }}
            type="submit"
            onClick={handleButtonSubmit}
            ref={eButtonSubmit}>
            追加
          </Button>
          <Link
            className="mt-4 basis-full cursor-pointer select-none text-center underline"
            to=".."
            style={{
              minWidth: '256px',
            }}>
            キャンセル
          </Link>
        </div>
      </div>
    </form>
  );
}
