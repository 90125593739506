import { LatLng } from 'leaflet';
import React, { useEffect, useRef, useState } from 'react';
import { useDispatch } from 'react-redux';
import { Select } from 'src/lib/components/Select.component';
import { toasting } from 'src/lib/components/Toast.component';
import { fget } from 'src/lib/fetch';
import { AreaEntity } from 'src/modules/dashboard/dto/area/area.entity';
import { ResponseDocument, ResponseListDocuments } from 'src/modules/dashboard/dto/response';
import { ReverseGeoCodingResponse } from 'src/modules/dashboard/dto/weather/reverse-geocoding.res';
import { WeatherResponse } from 'src/modules/dashboard/dto/weather/weather.res';
import { getMonthDateDayInWeekCurrent, getWeatherCodeObject } from 'src/modules/dashboard/pages/weather/Weather.page';
import { MarkerMapControl } from 'src/modules/map-markers/components/MarkerMapControl.component';
import { setSelectedArea } from 'src/redux/slices/area.slice';

export const MapWeatherInfo = React.memo(
  ({ onSelectArea }: { onSelectArea?: (area?: ResponseDocument<AreaEntity>) => void }) => {
    const eSelectAreaName = useRef<HTMLSelectElement>(null);

    const [data, setData] = useState<{
      weather?: Pick<WeatherResponse, 'current_weather' | 'elevation' | 'timezone_abbreviation'>;
      areas?: ResponseListDocuments<AreaEntity>;
      address?: ReverseGeoCodingResponse;
      meteoUrl?: URL;
    }>();

    const dispatch = useDispatch();

    useEffect(() => {
      const fetchData = async () => {
        try {
          const [_, areas] = await fget<unknown, ResponseListDocuments<AreaEntity>>('api/areas');
          const item = areas.Items?.[0];

          const [lat, lng] = item?.AREA_COORDINATE?.S?.split(',') || [33.9195, 133.1811];

          setData((prev) => ({
            ...prev,
            areas,
            meteoUrl: new URL(
              `https://api.open-meteo.com/v1/forecast?latitude=${lat}&longitude=${lng}&hourly=temperature_2m,relativehumidity_2m,apparent_temperature,precipitation,rain,showers,snowfall,snow_depth,weathercode,visibility,windspeed_10m,winddirection_10m,windgusts_10m&daily=weathercode,temperature_2m_max,temperature_2m_min,apparent_temperature_max,apparent_temperature_min,precipitation_sum,rain_sum,showers_sum,snowfall_sum,precipitation_hours,windspeed_10m_max,windgusts_10m_max,winddirection_10m_dominant&current_weather=true&windspeed_unit=ms&timeformat=unixtime&timezone=Asia%2FTokyo`,
            ),
          }));

          onSelectArea?.(areas.Items?.[0]);
          dispatch(setSelectedArea({ areaCoordinates: areas.Items?.[0]?.AREA_COORDINATE?.S }));
        } catch (error) {
          console.error('Error fetching data:', error);
          toasting({ children: 'データの取得に失敗しました。', containerProps: { className: 'border-red-600' } });
        }
      };

      fetchData();
    }, []);

    useEffect(() => {
      const fetchWeatherAndAddress = async () => {
        try {
          if (data?.meteoUrl) {
            const response = await fetch(decodeURIComponent(data.meteoUrl?.href || ''));
            const weather = (await response.json()) as WeatherResponse;

            const addressResponse = await fetch(
              `https://nominatim.openstreetmap.org/reverse?lat=${data.meteoUrl?.searchParams.get(
                'latitude',
              )}&lon=${data.meteoUrl?.searchParams.get('longitude')}&format=json&accept-language=ja`,
            );

            const address = (await addressResponse.json()) as ReverseGeoCodingResponse;

            setData((prev) => ({
              ...prev,
              weather,
              address,
              chunkIndex: 0,
            }));
          }
        } catch (error) {
          console.error('Error fetching weather and address data:', error);
          toasting({ children: 'データの取得に失敗しました。', containerProps: { className: 'border-red-600' } });
        }
      };

      if (data?.meteoUrl) {
        fetchWeatherAndAddress();
      }
    }, [data?.meteoUrl]);

    return data?.areas?.Items?.[0]?.AREA_COORDINATE?.S ? (
      <>
        <Select
          defaultValue={data.areas.Items[0].AREA_NAME.S}
          labelProps={{ value: '地域名' }}
          containerProps={{
            className: 'bg-transparent',
          }}
          onChange={(e) => {
            setData((prev) => {
              if (prev?.meteoUrl) {
                const coordinates = e.target.value;
                const [lat, lng] = coordinates.split(',');
                const meteoUrl = new URL(prev.meteoUrl);
                meteoUrl.searchParams.set('latitude', lat);
                meteoUrl.searchParams.set('longitude', lng);
                return { ...prev, meteoUrl };
              }
              return prev;
            });

            onSelectArea?.(data.areas?.Items?.[e.target.selectedIndex]);
            dispatch(setSelectedArea({ areaCoordinates: e.target.value }));
          }}
          required
          ref={eSelectAreaName}>
          {data.areas.Items.map((area, i) => (
            <option key={`Option-` + i} value={area.AREA_COORDINATE?.S || ''}>
              {area.AREA_NAME.S}
            </option>
          ))}
        </Select>

        <div className="flex flex-col gap-2 border border-gray-600 p-2">
          {data?.weather && (
            <>
              <span>{data?.address?.display_name}</span>
              <span className="text-lg">{getMonthDateDayInWeekCurrent(data?.weather)}</span>
              <div className="flex items-center gap-2">
                <i
                  className="h-12 w-12 border bg-contain bg-no-repeat"
                  style={{
                    backgroundSize: 'contain',
                    backgroundRepeat: 'no-repeat',
                    backgroundPosition: 'center',
                    backgroundImage: `${getWeatherCodeObject(data.weather.current_weather.weathercode).backgroundUrl}`,
                  }}
                />
                <span>{getWeatherCodeObject(data.weather.current_weather.weathercode).explain.ja}</span>
                <span className="text-lg text-cyan-700">{`${data.weather.current_weather.temperature} °C`}</span>
              </div>
              <div className="flex flex-wrap justify-between gap-2">
                <span>風向き: {data.weather.current_weather.winddirection} °</span>
                <span>風速: {data.weather.current_weather.windspeed} m3/s</span>
                <span>身長: {data.weather.elevation}m</span>
              </div>
            </>
          )}
        </div>

        <MarkerMapControl
          position={
            new LatLng(
              +data.areas.Items[0].AREA_COORDINATE.S.split(',')[0],
              +data.areas.Items[0].AREA_COORDINATE.S.split(',')[1],
            )
          }
          allowUseEffect={false}
          onClickMap={(e) => {
            setData((prev) => {
              if (prev?.meteoUrl) {
                const meteoUrl = new URL(prev.meteoUrl);
                meteoUrl.searchParams.set('latitude', e.latlng.lat.toString());
                meteoUrl.searchParams.set('longitude', e.latlng.lng.toString());
                return { ...prev, meteoUrl };
              }
              return prev;
            });
            return true;
          }}
          hideIcon
        />
      </>
    ) : (
      <></>
    );
  },
);
