import { useDispatch, useSelector } from 'react-redux';
import { Checkbox } from 'src/lib/components/Checkbox.component';
import { Props } from 'src/lib/type/props.type';
import { setEnableSidebar } from 'src/redux/slices/sidebar.slice';
import { RootState } from 'src/redux/store';
import { twMerge } from 'tailwind-merge';

export function DashboardContentLayout({ children, className }: Props<'div'>) {
  const enable = useSelector((state: RootState) => state.sidebarReducer.enable);

  const dispatch = useDispatch();

  return (
    <div
      id="dashboard-content"
      className={twMerge(
        'custom-scrollbar-1 ml-[3rem] w-[calc(100vw-3rem)] overflow-auto bg-slate-100 md:ml-[12.5rem] md:w-[calc(100vw-12.5rem)] lg:ml-[15rem] lg:w-[calc(100vw-15rem)]',
        enable
          ? ''
          : 'ml-[0rem] w-[calc(100vw-0rem)] md:ml-[0rem] md:w-[calc(100vw-0rem)] lg:ml-[0rem] lg:w-[calc(100vw-0rem)]',
        className,
      )}>
      <Checkbox
        containerProps={{
          className: 'absolute',
        }}
        labelProps={{
          children: (
            <div className="m-1.5 cursor-pointer rounded-md border border-blue-900 p-1 shadow duration-500 hover:scale-110">
              {enable ? (
                <svg
                  className="h-6 w-6 text-black transition-all"
                  viewBox="0 0 24 24"
                  strokeWidth="2"
                  stroke="currentColor"
                  fill="none"
                  strokeLinecap="round"
                  strokeLinejoin="round">
                  <path stroke="none" d="M0 0h24v24H0z" /> <polyline points="11 7 6 12 11 17" />
                  <polyline points="17 7 12 12 17 17" />
                </svg>
              ) : (
                <svg
                  className="h-6 w-6 text-black"
                  viewBox="0 0 24 24"
                  strokeWidth="2"
                  stroke="currentColor"
                  fill="none"
                  strokeLinecap="round"
                  strokeLinejoin="round">
                  <path stroke="none" d="M0 0h24v24H0z" /> <polyline points="7 7 12 12 7 17" />
                  <polyline points="13 7 18 12 13 17" />
                </svg>
              )}
            </div>
          ),
        }}
        defaultChecked={enable}
        className="hidden"
        onChange={(e) => {
          dispatch(setEnableSidebar(e.target.checked));
        }}
      />
      {children}
    </div>
  );
}
